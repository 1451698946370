import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import Header from "../../../../components/Header";
import { useEffect } from "react";

const UploadDetailsPreview = ({
  custom_styles,
  setSteps,
  removeUploadedInvoice,
  purchaseOrderData,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  return (
    <>
      <div className="p-4">
        <Header
          title="Upload Details"
          // subTitle="Select an account to retrieve details from Monday CRM and QuickTix, or enter account details manually."
          onEdit={() => {
            dispatch(removeUploadedInvoice());
            setSteps(0);
          }}
        />

        <div className="form-group d-flex align-items-center w-50">
          <label
            className="form-label col-form-label"
            style={{ ...custom_styles.font_label }}
          >
            Scan Invoice
          </label>
          <label className="ms-4" style={{ ...custom_styles.details }}>
            {purchaseOrderData.UploadInvoiceFileName
              ? purchaseOrderData.UploadInvoiceFileName
              : "No File Uploaded"}
          </label>
        </div>
      </div>
    </>
  );
};

export default UploadDetailsPreview;
