import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { checkLogin } from "../data/reducers/authentication/auth.reducer";

import DashboardRoutes from "./dashboard/dashboard.routes";
import AuthRoutes from "./auth/auth.routes";

const PrimaryRoutes = () => {
  const dispatch = useDispatch();
  const authInfo = useSelector((state) => state.auth);
  useEffect(() => {
    if (isNaN(authInfo.loggedIn) || authInfo.loggedIn === undefined)
      dispatch(checkLogin());
  }, []);

  return authInfo.loggedIn ? (
    <Route>
      <DashboardRoutes />
    </Route>
  ) : (
    <>
      <Route>
        <AuthRoutes />
      </Route>
    </>
  );
};

export default PrimaryRoutes;
