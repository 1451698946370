import React, { useEffect, useRef } from "react";
import PaymentRow from "./common/PaymentRow";
import WeekHeader from "./common/WeekHeader";
import Reset from "../../images/reset.svg";
import { Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getPaymentCalendarData } from "../../data/reducers/sync/sync.reducer";
import { withLoader } from "../../utils/hoc/withLoader";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

const PaymentDashboard = ({ setBusy }) => {
  const { calendarData } = useSelector((state) => state.sync);
  const dispatch = useDispatch();
  const calref = useRef(null);
  const arrayRef = React.useRef([]);
  const [clickedDate, setClickedDate] = React.useState(null);

  const [weekCount, setWeekCount] = React.useState(
    getWeekCount(2023, moment().month(), moment().startOf("month").day())
  );
  //Gives Index of day First day of Month
  const [startDayOfMonth, setStartDayOfMonth] = React.useState(
    moment().startOf("month").day()
  );
  //Gives Index of day Last day of Month
  const [endDayOfMonth, setEndDayOfMonth] = React.useState(
    moment().endOf("month").day()
  );
  const [monthData, setMonthData] = React.useState({
    daysInCurrentMonth: moment().daysInMonth(),
    daysInPreviousMonth: moment().subtract(1, "month").daysInMonth(),
  });

  //Return the Month Index
  const [month, setMonth] = React.useState(moment().month());
  const [year, setYear] = React.useState(moment().year());

  //start date and endDate
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.PAYMENT_CALENDAR,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.PAYMENT_CALENDAR,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  React.useEffect(() => {
    const firstWeekDate = getFirstWeekDates()[0];
    if (firstWeekDate?.date === 1) {
      const startDate = moment([year, month, 1]).format("YYYY-MM-DD");
      setStartDate(startDate);
    } else {
      if (month === 0) {
        const startDate = moment([year - 1, 11, firstWeekDate?.date]).format(
          "YYYY-MM-DD"
        );
        setStartDate(startDate);
      } else {
        const startDate = moment([year, month - 1, firstWeekDate?.date]).format(
          "YYYY-MM-DD"
        );
        setStartDate(startDate);
      }
    }
    const lateWeekDates = getLastWeekDates();
    const lastDayDate = lateWeekDates[lateWeekDates.length - 1];

    if (
      lastDayDate?.date === 31 ||
      lastDayDate?.date === 30 ||
      lastDayDate?.date === 28
    ) {
      const endDate = moment([year, month, lastDayDate?.date]).format(
        "YYYY-MM-DD"
      );
      setEndDate(endDate);
    } else {
      if (month == 11) {
        const endDate = moment([year + 1, 1, lastDayDate?.date]).format(
          "YYYY-MM-DD"
        );
        setEndDate(endDate);
      } else {
        const endDate = moment([year, month + 1, lastDayDate?.date]).format(
          "YYYY-MM-DD"
        );
        setEndDate(endDate);
      }
    }
  }, [monthData, startDayOfMonth, endDayOfMonth, weekCount]);

  const getCalendarData = async () => {
    try {
      setBusy(true);
      const res = await dispatch(
        getPaymentCalendarData({ startDate, endDate })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      getCalendarData();
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (year && (month || month === 0)) {
      const date = moment([year, month, 1]);
      setWeekCount(
        getWeekCount(year, date.month(), date.startOf("month").day())
      );
      setStartDayOfMonth(date.startOf("month").day());
      setEndDayOfMonth(date.endOf("month").day());
      setMonthData({
        daysInCurrentMonth: date.daysInMonth(),
        daysInPreviousMonth: date.subtract(1, "month").daysInMonth(),
      });
    }
  }, [year, month]);

  const weekDays = [
    {
      id: 1,
      day: "Sunday",
    },
    {
      id: 2,
      day: "Monday",
    },
    {
      id: 3,
      day: "Tuesday",
    },
    {
      id: 4,
      day: "Wednesday",
    },
    {
      id: 5,
      day: "Thursday",
    },
    {
      id: 6,
      day: "Friday",
    },
    {
      id: 7,
      day: "Saturday",
    },
  ];
  const months = [
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "November",
    },
    {
      id: 12,
      name: "December",
    },
  ];

  const years = [];

  for (var i = 1970; i <= parseInt(moment().format("YYYY")) + 10; i++) {
    years.push({
      id: i,
      name: i,
    });
  }

  // const time6 = moment([2025, 1, 18])

  function getWeekCount(year, month_number) {
    const date = moment([year, month_number, 1]);
    const firstDay = date.startOf("month").day();
    const endDay = date.endOf("month").day();
    const daysInMonth = date.daysInMonth();
    const firstWeekDays = 7 - firstDay;
    const lastWeekDays = endDay + 1;
    const remainingDays = daysInMonth - firstWeekDays - lastWeekDays;
    const numberOfWeeks = remainingDays / 7;
    return numberOfWeeks + 2;
    // var firstOfMonth = new Date(year, month_number, 1);
    // var day = firstOfMonth.getDay() || 6;
    // day = day === 1 ? 0 : day;
    // if (day) {
    // 	day--;
    // }
    // var diff = 7 - day;
    // var lastOfMonth = new Date(year, month_number + 1, 0);
    // var lastDate = lastOfMonth.getDate();
    // if (lastOfMonth.getDay() === 1) {
    // 	diff--;
    // }
    // var result = Math.ceil((lastDate - diff) / 7);
    // return result + 1;
  }
  //style for dropdown
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const getFirstWeekDates = () => {
    let previousMonthDaysInCurrentRow =
      monthData?.daysInPreviousMonth - startDayOfMonth;
    let startDate = 0;
    const dates = weekDays?.map((day) => {
      if (day?.id - 1 < startDayOfMonth) {
        previousMonthDaysInCurrentRow = previousMonthDaysInCurrentRow + 1;
        return {
          date: previousMonthDaysInCurrentRow,
          isCurrentMonth: false,
          isPreviousMonth: true,
          isNextMonth: false,
        };
      }
      startDate++;
      return {
        date: startDate,
        isCurrentMonth: true,
        isPreviousMonth: false,
        isNextMonth: false,
      };
    });
    return dates;
  };

  const getLastWeekDates = () => {
    let startDate = 0;
    const dates = weekDays?.map((day) => {
      if (day?.id - 1 <= endDayOfMonth) {
        const date = 7 - startDayOfMonth + (weekCount - 2) * 7 + day?.id;
        return {
          date: date,
          isCurrentMonth: true,
          isPreviousMonth: false,
          isNextMonth: false,
        };
      }
      startDate++;
      return {
        date: startDate,
        isCurrentMonth: false,
        isPreviousMonth: false,
        isNextMonth: true,
      };
    });
    return dates;
  };

  const handleRefresh = () => {
    if (clickedDate) {
      arrayRef.current[clickedDate].click();
    }
    getCalendarData()
    setMonth(moment().month());
    setYear(moment().year());
  };

  const handleClickOutside = (event) => {
    if (calref.current && !calref.current.contains(event.target)) {
      setClickedDate(null);
      arrayRef.current = [];
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.PAYMENT_CALENDAR,
        });
      }}
      className="container-fluid p-3 bg-white"
      style={{ fontWeight: "500" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h4>
          <b>Payment Calendar</b>
        </h4>
        <Button
          onClick={handleRefresh}
          className="ms-2 ss-light-button"
          style={{ fontSize: "0.71rem", marginTop: "-0.80rem" }}
        >
          <img src={Reset} alt=" " />
          <span>Refresh</span>
        </Button>
      </div>
      <div
        className="p-2"
        style={{ border: "1px solid rgba(167, 167, 167, 0.3)" }}
      >
        <Row className="my-2">
          <Col sm={4}>
            <p style={{ color: "#0241A0" }}>
              Today, {moment().format("DD MMMM YYYY")}
            </p>
          </Col>
          <Col>
            <Row>
              <Col sm={2}>
                <Select
                  value={months?.find((x) => x.id === month + 1)}
                  onChange={(e) => {
                    if (clickedDate) {
                      arrayRef.current[clickedDate].click();
                    }
                    setMonth(e?.id - 1 ?? 0);
                  }}
                  styles={customStyles}
                  options={months}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option?.id}
                />
              </Col>
              <Col sm={2}>
                <Select
                  value={years?.find((x) => String(x.id) === String(year))}
                  onChange={(e) => {
                    if (clickedDate) {
                      arrayRef.current[clickedDate].click();
                    }
                    setYear(e?.id ?? 1970);
                  }}
                  styles={customStyles}
                  options={years}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option?.id}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <WeekHeader weekDays={weekDays} />
        <div ref={calref}>
          {new Array(weekCount).fill(0).map((week, weekIndex) => {
            if (weekIndex === 0) {
              const dates = getFirstWeekDates();
              return (
                <PaymentRow
                  arrayRef={arrayRef}
                  weekDays={weekDays}
                  key={weekIndex}
                  dates={dates}
                  selectedData={{
                    month: month,
                    year: year,
                  }}
                  clickedDate={clickedDate}
                  setClickedDate={setClickedDate}
                />
              );
            }
            if (weekIndex === weekCount - 1) {
              const dates = getLastWeekDates();
              return (
                <PaymentRow
                  arrayRef={arrayRef}
                  weekDays={weekDays}
                  key={weekIndex}
                  dates={dates}
                  selectedData={{
                    month: month,
                    year: year,
                  }}
                  clickedDate={clickedDate}
                  setClickedDate={setClickedDate}
                />
              );
            }
            const dates = weekDays?.map((day) => {
              return {
                date: 7 - startDayOfMonth + (weekIndex - 1) * 7 + day?.id,
                isCurrentMonth: true,
                isPreviousMonth: false,
                isNextMonth: false,
              };
            });
            return (
              <PaymentRow
                arrayRef={arrayRef}
                weekDays={weekDays}
                key={weekIndex}
                dates={dates}
                selectedData={{
                  month: month,
                  year: year,
                }}
                clickedDate={clickedDate}
                setClickedDate={setClickedDate}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withLoader(PaymentDashboard);
