import React, { useState, useEffect, useMemo } from "react";
import AddEditAccountModal from "./modals/AddEditAccountModal";
import { withLoader } from "../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import Run from "../../images/run.svg";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import { Button, Dropdown, Form } from "react-bootstrap";

//components
import { formatMoney } from "../../utils/formatter/currencyFormatter";

// images
import Export from "../../images/export.svg";
import Reset from "../../images/reset.svg";
import Add from "../../images/add_blue.svg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

import "../../shared/styles/ModalStyles.scss";
import "./index.scss";
import { Link } from "react-router-dom";
import AddEditAddress from "../addressBook/modals/AddEditAddress";
import {
  exportCSVAccount,
  exportSelectedAsCSVAccount,
  exportPdfAccount,
  exportSelectedAsPdfAccount,
  getAccountCardsInfo,
} from "../../data/reducers/accounts/accounts.reducers";
import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import { createAddress } from "../../data/reducers/addressBook/addressBook.reducer";
import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import CollapsibleTable from "./collapsible-table";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import {
  createAccount,
  deleteAccount,
  fetchAccounts,
  flushAccounts,
  updateAccount,
} from "../../data/reducers/accounts/cached-accounts.reducers";
import {
  flushAccountsCards,
  loadAccountCards,
} from "../../data/reducers/accounts/cached-accounts-cards.reducers";
import DashboardCards from "./dashboard-cards";
import AccountsFilterPanel from "./AccountsFilterPanel";
import Loader from "../../shared/components/loader/Loader";

const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "SortDir") {
      return value !== "Ascending";
    }
    if (
      key === "SortField" ||
      key === "searchString" ||
      key === "Address" ||
      key === "Email" ||
      key === "Name" ||
      key === "SportId" ||
      key === "AccountId" ||
      key === "TeamId" ||
      key === "VendorId"
    ) {
      return value !== "";
    }
    return true;
  });
  return Object.fromEntries(arr);
};

const AccountDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [exportLoading, setExportLoading] = useState(false);

  //---------------------------------------------------------------------------------------------------------------//

  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.ACCOUNT,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.ACCOUNT,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  //---------------------------------------------------------------------------------------------------------------//

  //use states
  const [addEditAddressModalVisibility, setAddEditAddressModalVisibility] =
    useState(false);
  const [added, setAdded] = useState(null);
  const [addAccountModalVisible, setAddEditAccountModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [minorLoading, setMinorLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [cardError, setCardError] = useState(null);

  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    Address: params.get("Address") || "",
    Email: params.get("Email") || "",
    Name: params.get("Name") || "",
    SportId: params.get("SportId") || "",
    AccountId: params.get("AccountId") || "",
    TeamId: params.get("TeamId") || "",
    VendorId: params.get("VendorId") || "",
    PageNumber: Number(params.get("PageNumber")) || 1,
    PageSize: Number(params.get("PageSize")) || 40,
    SortDir: params.get("SortDir") || "Ascending",
    SortField: params.get("SortField") || "",
    categoryId: Number(params.get("categoryId")) || 1,
  });

  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const {
    data: cachedAccountsObject,
    error: accountsFetchError,
    loading,
  } = useSelector((state) => state.cachedAccounts);

  const { callCardsApi } = useSelector((state) => state.cachedAccountsCards);

  useEffect(() => {
    if (accountsFetchError) {
      dispatch(flushAccounts());
    }
  }, []);

  const { accounts, paging, timestamp } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/accounts?${queryParams}`);
    const data = cachedAccountsObject?.[JSON.stringify(filters)];
    if (!data) {
      return { accounts: undefined, paging: undefined };
    }
    return data;
  }, [cachedAccountsObject, filters]);

  useEffect(() => {
    if (accountsFetchError) {
      dispatch(
        errorMessage(accountsFetchError?.message ?? "Something went wrong")
      );
    } else if (!accounts) {
      dispatch(fetchAccounts(filters));
    }
  }, [accountsFetchError, accounts, filters]);

  const fetchAccountCards = async (data, index) => {
    try {
      setMinorLoading("account" + index);
      const response = await dispatch(getAccountCardsInfo(data));
      if (response.error) {
        dispatch(
          errorMessage(response.error.message ?? "Something went wrong")
        );
      }
    } catch (e) {
      dispatch(errorMessage(e ?? "Something went wrong"));
    } finally {
      setMinorLoading(false);
    }
  };

  const resetHandler = () => {
    dispatch(flushAccounts());
    dispatch(flushAccountsCards());
  };

  // delete account
  const onDeleteAccount = async (accountId) => {
    try {
      await dispatch(deleteAccount(accountId));
      dispatch(successMessage("Account was deleted successfully"));
    } catch (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } finally {
      setDeleteConfirmationVisibility(false);
    }
  };

  const exportSelectedAsCSV = async () => {
    setMinorLoading("export");

    const { PageNumber, SortDir, PageSize, categoryId, ...exportFilters } =
      filters;

    const hasFilters = Object.values(exportFilters).some(
      (val) => val !== "" && val !== 0
    );

    if (hasFilters) {
      await dispatch(
        exportSelectedAsCSVAccount({
          ...exportFilters,
          categoryId: filters.categoryId,
          SortDir: filters.SortDir,
        })
      );
    } else {
      await dispatch(exportCSVAccount({ categoryId: filters.categoryId }));
    }
    setMinorLoading(false);
  };

  const onExportSelectedAsPdf = async () => {
    setMinorLoading("export");

    const { PageNumber, SortDir, PageSize, categoryId, ...exportFilters } =
      filters;

    const hasFilters = Object.values(exportFilters).some(
      (val) => val !== "" && val !== 0
    );

    if (!hasFilters) {
      await dispatch(exportPdfAccount({ categoryId: filters.categoryId }));
    } else {
      await dispatch(exportSelectedAsPdfAccount(filters));
    }
    setMinorLoading(false);
  };

  // add or update account details
  const addUpdateHandler = async (data) => {
    try {
      let result;
      if (data?.id) {
        result = await dispatch(
          updateAccount({
            ...data,
            accountCredits: data?.accountCredits ? data.accountCredits : 0,
          })
        );
      } else {
        result = await dispatch(
          createAccount({
            vendorId: parseInt(data.vendorId),
            teamId: parseInt(data.teamId),
            accountId: parseInt(data.accountId),
            name: data.name,
            addressBookId: data.addressBookId,
            email: data.email,
            sportId: parseInt(data.sportId),
            categoryId: data?.categoryId ? data.categoryId : 1,
            accountCredits: data?.accountCredits ? data.accountCredits : 0,
            mobileNo: data?.mobileNo,
          })
        );
      }
    } catch (error) {
      dispatch(errorMessage("Something went wrong2"));
    } finally {
      setMinorLoading(false);
      setAddEditAccountModalVisibility(false);
    }
  };

  //add address function
  const addAddress = async (data) => {
    try {
      const message = data.id
        ? `Address Updated Succesfully`
        : `New Address Created Succesfully`;
      setBusy(true);
      const action = createAddress(data);
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        if (response.payload.id)
          setSelectedRecord({
            ...selectedRecord,
            addressBookId: response.payload.id,
          });
        setAdded(response.payload.id);
        dispatch(successMessage(message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
      setAddEditAddressModalVisibility(false);
      setAddEditAccountModalVisibility(true);
    }
  };

  //accounts table row and data
  const accountsCol = [
    {
      columnName: "Team/Sport",
      sort: true,
      sortName: "SportId",
      render: (data) => (
        <>
          {data.teamName}
          <br />
          {data.sportName}
        </>
      ),
    },
    {
      columnName: "Name/Account",
      sort: true,
      sortName: "Name",
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={`/accounts/${data.accountId}?accountRowId=${data?.id}`}
          >
            {data.name}
            <br />
            {data.accountId == 0 ? "" : data.accountId}
          </Link>
        </>
      ),
    },
    {
      columnName: "Vendor",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data.vendorId}
        >
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
      sort: true,
      sortName: "VendorId",
      columnKey: "vendorName",
    },
    {
      columnName: "Section/Row",
      render: (data) => (
        <>
          <span>
            {data?.section?.substring(0, 16)}
            {data?.section?.length > 16 ? "..." : ""}
          </span>
          <br />
          <span>
            {data?.row?.substring(0, 16)}
            {data?.row?.length > 16 ? "..." : ""}
          </span>
        </>
      ),
    },
    {
      columnName: "Low Seat/High Seat",
      render: (data) => (
        <>
          <span>
            {data?.lowSeat?.substring(0, 16)}
            {data?.lowSeat?.length > 16 ? "..." : ""}
          </span>
          <br />
          <span>
            {data?.highSeat?.substring(0, 15)}
            {data?.highSeat?.length > 16 ? "..." : ""}
          </span>
        </>
      ),
    },
    {
      columnName: (
        <>
          Past <br /> Charges
        </>
      ),
      flexGrow: 0.5,
      render: (data) => <>{formatMoney(data.pastCharges)}</>,
    },
    {
      columnName: (
        <>
          Future <br /> Charges
        </>
      ),
      flexGrow: 0.5,
      render: (data) => <>{formatMoney(data.futureCharges)}</>,
    },
    {
      columnName: "Inventory",
      flexGrow: 0.5,
      render: (data) => <>{formatMoney(data.inventory)}</>,
    },
    {
      columnName: (
        <>
          Account <br /> Credits
        </>
      ),
      render: (data) => <>{formatMoney(data.accountCredits)}</>,
    },
    {
      columnName: "Actions",
      render: (data, ind, open, setOpen) => (
        <>
          {data?.autoGenerated === false && (
            <small className="table-row-card-actions d-flex">
              <Button
                variant="link"
                className="table-action-button green_bg"
                onClick={(e) => {
                  e.preventDefault();
                  setIsReset(true);
                  setSelectedRecord({ ...data, redirect: false });
                  setSelectedRecordId(data.accountId);
                  setAddEditAccountModalVisibility(true);
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    page: mixpanel_contants.ACCOUNT,
                    buttonName: mixpanel_button_name.EDIT,
                  });
                }}
              >
                <img src={EditIcon} alt="" />
              </Button>
              <Button
                variant="link"
                className="table-action-button"
                onClick={() => {
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setDeleteConfirmationVisibility(true);
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    page: mixpanel_contants.ACCOUNT,
                    buttonName: mixpanel_button_name.DELETE,
                  });
                }}
              >
                <img src={DeleteIcon} alt="delete" />
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  if (open[ind]) {
                    setOpen(Array(data?.length).fill(false));
                  } else {
                    const temp = Array(data?.length).fill(false);
                    temp[ind] = !temp[ind];
                    setOpen(temp);
                  }

                  if (!open[ind]) {
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      page: mixpanel_contants.ACCOUNT,
                      buttonName: mixpanel_button_name.COLLAPSE,
                    });
                    fetchAccountCards(
                      {
                        accountId: data?.accountId,
                        email: data?.email,
                      },
                      ind
                    );
                  }
                }}
              >
                <img src={open[ind] ? Up : Down} alt="up down image" />
              </Button>
            </small>
          )}
        </>
      ),
    },
  ];

  //Run Rules
  const callRunRules = async () => {
    try {
      setExportLoading("runRules");
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  //head details
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue={filters.PageSize}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  PageSize: e.target.value,
                });
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <div className="text-end mt-3 d-flex">
            {filters.categoryId === 1 && (
              <Button
                variant="primary"
                style={{ textWrap: "nowrap" }}
                className="mx-2 ss-light-button"
                disabled={exportLoading === "runRules"}
                onClick={async (e) => {
                  e.currentTarget.blur();
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    buttonName: mixpanel_button_name.RUNRULES,
                    page: mixpanel_contants.TRANSACTIONS,
                  });
                  await callRunRules();
                  resetHandler();
                }}
              >
                {exportLoading === "runRules" ? (
                  <Loader />
                ) : (
                  <img src={Run} alt=" " />
                )}
                <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Run Rules
                  </div>
                </span>
              </Button>
            )}

            <Dropdown
              className="position-inherit"
              onClick={(e) => e.currentTarget.blur()}
              autoClose={false}
            >
              <Dropdown.Toggle
                id="dropdown-autoclose-false"
                className="button ss-light-button"
                disabled={minorLoading === "export"}
              >
                <img className="filter-reset-svg" src={Export} alt=" " />
                <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Export
                    {minorLoading === "export" && (
                      <Loader style={{ marginLeft: "8px" }} />
                    )}
                  </div>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    exportSelectedAsCSV();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      page: mixpanel_contants.ACCOUNT,
                      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_CSV,
                    });
                  }}
                >
                  Export as CSV
                </Dropdown.Item>
                <hr className="hr-full" />
                <Dropdown.Item
                  onClick={() => {
                    onExportSelectedAsPdf();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      page: mixpanel_contants.ACCOUNT,
                      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_PDF,
                    });
                  }}
                >
                  Export as PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                setIsReset(false);
                await setSelectedRecord(undefined);
                await setSelectedRecordId(undefined);
                await setAddEditAccountModalVisibility(true);
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: mixpanel_contants.ACCOUNT,
                  buttonName: mixpanel_button_name.ADD,
                });
              }}
              variant="primary"
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Add} alt=" " />
              <span>Add</span>
            </Button>
            <Button
              onClick={() => {
                resetHandler();
                dispatch(successMessage("Refresh successful"));
              }}
              variant="primary"
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Reset} alt=" " />
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Refresh
                </div>
              </span>
            </Button>
          </div>
          <p
            style={{
              visibility: timestamp == null && "hidden",
              textAlign: "end",
              fontSize: "12px",
              lineHeight: 0,
              paddingTop: "16px",
              color: "#00000090",
            }}
          >
            Last sync {new Date(timestamp).toLocaleDateString()}{" "}
            {new Date(timestamp).toLocaleTimeString()}
          </p>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.ACCOUNT,
        });
      }}
      className="container-fluid pt-3"
    >
      <div className=" d-flex justify-content-end">
        <button
          onClick={() => {
            dispatch(loadAccountCards(!callCardsApi));
          }}
          className=" ss-light-button-white d-flex justify-content-center my-2"
          style={{
            padding: "6px 12px",
            borderRadius: "6px",
          }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
          >
            {"Load Card Values"}
          </span>
        </button>
      </div>

      {/* Summary Cards */}
      <DashboardCards filters={filters} setCardError={setCardError} />

      <AccountsFilterPanel
        filters={filters}
        setFilters={setFilters}
        resetHandler={resetHandler}
        error={accountsFetchError}
        cardError={cardError}
      />

      {/* Data Table */}
      <CollapsibleTable
        loading={loading}
        rowLoading={minorLoading}
        nextPage={nextPage}
        previousPage={previousPage}
        paramerterNavigation={true}
        headerName="ACCOUNTS"
        paging={paging}
        pageType="accounts"
        columns={accountsCol}
        data={accounts || []}
        tabs={["Active", "Inactive", "Revoked", "All"]}
        selectedTabIndex={filters.categoryId === 0 ? 3 : filters.categoryId - 1}
        onTabSelectionChange={(i) => {
          setFilters({
            ...filters,
            PageNumber: 1,
            categoryId: i === 3 ? 0 : i + 1,
          });
        }}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        sortField={filters.SortField}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        setSortField={() => {}}
        setSortDir={() => {}}
        headOptions={headOptions}
        setpageNumber={() => {}}
      />
      {/* Modal Popups */}
      {addAccountModalVisible && (
        <AddEditAccountModal
          added={added}
          setSelectedRecord={setSelectedRecord}
          isVisible={addAccountModalVisible}
          handleThisClose={() => setAddEditAccountModalVisibility(false)}
          modalOpen={addEditAddressModalVisibility}
          setModalOpen={setAddEditAddressModalVisibility}
          onSubmit={addUpdateHandler}
          selectedRecord={selectedRecord}
          addOrEdit={isReset}
        />
      )}
      {selectedRecordId && (
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisible}
          handleClose={() => setDeleteConfirmationVisibility(false)}
          onConfirmation={() => onDeleteAccount(selectedRecordId)}
        />
      )}
      {addEditAddressModalVisibility && (
        <AddEditAddress
          isVisible={addEditAddressModalVisibility}
          onSubmit={addAddress}
          handleClose={() => {
            setAddEditAddressModalVisibility(false);
            setAddEditAccountModalVisibility(true);
          }}
        />
      )}
    </div>
  );
};

export default withLoader(AccountDashboard);
