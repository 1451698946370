import { MdOutlineModeEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsPreview } from "../../../../data/reducers/purchaseOrders/po.reducer";
const Header = ({ onEdit, title, subTitle }) => {
  const [isAdmin, setAdmin] = useState(false);

  const { selectedPendingPOTab } = useSelector((state) => state.purchaseOrder);
  const dispatch = useDispatch();
  useEffect(() => {
    const userRole = localStorage.getItem("USER_ROLE");
    setAdmin(userRole === "Admin");
  }, []);

  const { showStepperPO } = useSelector((state) => state.purchaseOrder);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <span style={{ fontSize: "20px", fontWeight: "500", color: "black" }}>
          {title}
        </span>

        {subTitle && (
          <>
            <br />
            <span className="text-muted small">{subTitle}</span>
          </>
        )}
      </div>

      {onEdit && (
        <>
          <span
            style={{
              fontSize: "14px",
              color: "#0241a0",
              cursor: "pointer",
              fontWeight: "500",
            }}
            onClick={() => {
              dispatch(setIsPreview(true));
              onEdit();
            }}
          >
            <MdOutlineModeEdit
              color="#0241A0"
              size="15"
              className="mx-1 fw-bold"
              style={{ fontWeight: "600" }}
            />
            Edit
          </span>
        </>
      )}
    </div>
  );
};

export default Header;
