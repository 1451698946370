import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import Up from "../../../images/uparrow.svg";
import Down from "../../../images/downarrow.svg";

const HistoryRow = ({ val }) => {
  const greenColor = "#219653";
  const black = "black";
  const fontSize = "14px";

  const [show, toggleShow] = useState(false);

  const statusId = val.statusId
    ? { color: greenColor, fontSize }
    : { color: black, fontSize };
  const description = { color: "black", fontSize };
  const updateCount = Array.isArray(val.updates) ? val.updates.length : 0;

  return (
    <>
      <div className="mainContainer">
        <Container className="tableBody">
          <Row className="tableUpperRow" onClick={() => toggleShow(!show)}>
            <Col style={{ fontSize }} className="tableCol">
              {val.updatedBy}
            </Col>
            <Col style={{ fontSize }}>{val.updatedDate}</Col>
            <Col style={{ color: black, fontSize }}>
              {val.statusId?.value === 1 && "Updated"}
              {val.statusId?.value === 2 && "Rejected"}
              {val.statusId?.value === 3 && "Request Changes"}
              {val.statusId?.value === 4 && "Approved"}
            </Col>

            <Col
              style={{
                ...description,
                color: val.description?.value ? undefined : "#707070",
              }}
            >
              {val.description?.value || "Not Available"}
            </Col>

            <Col style={{ color: black, fontSize }}>
              {updateCount > 0 ? updateCount : "0"}
              <IconButton>
                <img
                  src={show ? Up : Down}
                  style={{ marginLeft: "5px" }}
                  alt="up down image"
                />
              </IconButton>
            </Col>
          </Row>
        </Container>

        {show && (
          <Container className="innerTableBody">
            {/* ---------------- Header ----------------- */}
            <Row className="innerTableHead">
              <Col>Section</Col>
              <Col>Field</Col>
              <Col>Previous Value</Col>
              <Col>New Value</Col>
            </Row>
            {/* ---------------- Header ----------------- */}
            {Array.isArray(val.updates) && val.updates.length > 0 ? (
              val.updates.map((data, index) => {
                const givingStyle = {
                  color: "black",
                  fontSize: fontSize || "16px", // Ensure fontSize is defined
                };

                const previousValue = data.oldValue ? (
                  <>{data.oldValue}</>
                ) : (
                  "-"
                );

                const newValue = data.newValue ? <>{data.newValue}</> : "-";

                return (
                  <Row
                    key={index}
                    style={givingStyle}
                    className="innerTableRow"
                  >
                    <Col>{data.section || "-"}</Col>
                    <Col>{data.field || "-"}</Col>
                    <Col>{previousValue}</Col>
                    <Col>{newValue}</Col>
                  </Row>
                );
              })
            ) : (
              <Row>
                <Col>No updates available</Col>
              </Row>
            )}
          </Container>
        )}
      </div>
    </>
  );
};

export default HistoryRow;
