import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";

import AddEditAddressModal from "../../../../../addressBook/modals/AddEditAddress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import Done from "../../../../../../images/done.svg";
import salemimg from "../../../../../../images/Salem_Seats_crop.png";
import mondaycrop from "../../../../../../images/mondaycrm_crop.png";
import debounce from "lodash/debounce";
import {
  EditSelectedAccount,
  getAccountIdDetails,
  getAccountsbysearch,
  getAccountsDetails,
  getAccountsList,
  getAccountsListByVendor,
  getMondayDetails,
  getSelectedAccount,
  removeAccountList,
  removeSelectedAccount,
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} from "../../../../../../data/reducers/accounts/accounts.reducers";
import {
  errorMessage,
  successMessage,
} from "../../../../../../data/reducers/alerts/alert.reducer";
import {
  EditSelectedAddress,
  createAddress,
  getAddressList,
  updateAddress,
} from "../../../../../../data/reducers/addressBook/addressBook.reducer";

import { StoreDetails } from "../../../../../../data/reducers/purchaseOrders/po.newinvoice.reducer";
import { Spinner } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { MdOutlineEdit } from "react-icons/md";
import WarningMessage from "./WarningMessage";
import { fetchVendorsListForDropdown } from "../../../../../../data/reducers/vendors/cached-vendors.reducer";
import KeyValueLabel from "../../../components/KeyValueLabel";
import { reactSelectStyle } from "../../../style";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { withLoader } from "../../../../../../utils/hoc/withLoader";
import { formatPhoneNumber } from "../../../../../../utils/formatter/currencyFormatter";

const custom_styles = {
  details: {
    fontWeight: "500",
    fontSize: "14px",
  },
  font_label: {
    fontSize: "14px",
    color: "#404040",
    width: "120px",
  },
  add_btn: {
    width: "32px",
    height: "32px",
  },
  form_input: {
    height: "32px",
    fontSize: "14px",
  },
  font_label2: {
    fontSize: "14px",
    color: "#a7a7a7",
  },
  border2: {
    backgroundColor: "#dfdfdf",
    height: "1px",
    opacity: "0.5",
  },
};

const InvoiceAccountDetails = ({ setBusy }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { FetchedAccounts, selectedAccountDetails } = useSelector(
    (state) => state.accounts
  );

  const { allPurchaseOrderDetails } = useSelector(
    (state) => state.purchaseOrder
  );
  const { accountsList } = useSelector((state) => state.accounts);
  const { accountVendorCombination } = useSelector((state) => state.accounts);
  const { mondayAccountDetails } = useSelector((state) => state.accounts);

  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const [isUploaded, setIsUploaded] = useState(
    purchaseOrderData?.UploadInvoiceFileName ? true : false
  );
  const { ticketDetails } = useSelector((state) => state.accounts);
  const { selectedAddress: globalAddress } = useSelector(
    (state) => state.addressBook
  );

  const [loading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openAddress, setOpenAddress] = useState(false);
  const [searchedvalue, setSearchedvalue] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAccountSelected, setIsAccountSelected] = useState(null);
  const [quicktixAccount, setquicktixAccount] = useState([]);
  const [mondayMatch, setMondayMatch] = useState({
    nameMatch: false,
    emailMatch: false,
  });
  const [quicktixMatch, setQuicktixMatch] = useState({
    nameMatch: false,
    emailMatch: false,
  });
  const [isfetched, setIsFetched] = useState(
    ticketDetails.sport ? true : false
  );
  const [isAddClicked, setIsAddClicked] = useState(false);

  // Account reducers

  // AddressBook Details
  const { addressList } = useSelector((state) => state.addressBook);

  useEffect(() => {
    if (!addressList.length) {
      fetchAddressList();
    }
  }, [addressList]);

  useEffect(() => {
    if (globalAddress?.id) {
      fetchAddressList();
    }
  }, [globalAddress]);

  const fetchAddressList = async () => {
    setBusy(true);
    await dispatch(getAddressList());
    setBusy(false);
  };

  // remove selected account from redux after unmounting the page
  // call the vendor list on page load

  const { vendorsListForDropdown: vendorList } = useSelector(
    (state) => state.cachedVendors
  );

  useEffect(() => {
    if (!vendorList.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorList]);

  // Handle account search
  const HandleAccount = (e) => {
    if (e?.searchString?.length > 2) {
      setSearching(true);
      dispatch(
        getAccountsbysearch({
          searchString: e?.searchString,
          vendorId: e?.vendorId,
        })
      );
    }
    if (e?.searchString?.length === 0) {
      dispatch(removeAccountList());
    }
  };

  // Debouncing for searching
  const debouncedFetchOptions = useCallback(
    debounce(HandleAccount, 300), // Adjust the delay as necessary
    []
  );

  // handle change
  const handleInputChange = (newValue) => {
    debouncedFetchOptions({
      searchString: newValue,
      vendorId: selectedVendor?.value,
    });
    setSearchedvalue(newValue);
  };

  // Handle Selected account
  const HandleSelectedAccount = (val) => {
    setSelectedAccount(val);
    setIsAccountSelected(val);
    if (!val) {
      dispatch(removeAccountList());
      dispatch(removeSelectedAccount());
      setSelectedAddress(null);
    }
    // Fetch Details from quicktix
    if (val && val?.data?.quicktix) {
      setLoading(true);
      dispatch(
        getAccountsDetails({
          accountId: val?.data?.accountID,
          vendorId: selectedVendor?.value,
        })
      );
    } else if (val) {
      // fetch details from monday
      setLoading(true);
      if (val?.value == null) {
        dispatch(
          setSelectedAccountDetails({
            name: val?.data?.name,
          })
        );
      } else {
        dispatch(getSelectedAccount(val?.value));
      }
      // dispatch(fetchVendorsListForDropdown());
    }
  };

  // handle if get the selectedaccount details
  useEffect(() => {
    if (selectedAccountDetails) {
      setLoading(false);
    }
    if (
      selectedAccountDetails &&
      selectedAccountDetails?.vendorId &&
      selectedAccountDetails?.vendorName &&
      selectedVendor?.value != selectedAccountDetails?.vendorId &&
      selectedVendor?.label != selectedAccountDetails?.vendorName
    ) {
      setSelectedVendor({
        label: selectedAccountDetails?.vendorName,
        value: selectedAccountDetails?.vendorId,
      });
    }
    if (selectedAccountDetails && selectedAccountDetails?.addressBookId) {
      const filteredaddress = addressList?.filter(
        (add) => add?.id === selectedAccountDetails?.addressBookId
      );
      const addressAsString =
        filteredaddress.length > 0
          ? (filteredaddress[0]?.address1 ?? "") +
            " " +
            (filteredaddress[0].address2 ?? "") +
            " " +
            (filteredaddress[0].city ?? "") +
            " " +
            (filteredaddress[0].state ?? "") +
            " " +
            filteredaddress[0].postalCode
          : null;

      setSelectedAddress({
        value: filteredaddress[0],
        label: addressAsString,
      });

      //if the address field of the global state is not being set then we are setting it.
      if (addressAsString && !selectedAccountDetails?.address) {
        dispatch(
          setSelectedAccountDetails({
            ...selectedAccountDetails,
            address: addressAsString,
          })
        );
      }
    }

    if (selectedAccountDetails && isfetched == false) {
      dispatch(
        setSelectedTicketDetails({
          ...ticketDetails,
          sport:
            selectedAccountDetails?.sportName && selectedAccountDetails?.sportId
              ? {
                  label: selectedAccountDetails?.sportName,
                  value: selectedAccountDetails?.sportId,
                }
              : undefined,
          team:
            selectedAccountDetails?.teamName && selectedAccountDetails?.teamName
              ? {
                  label: selectedAccountDetails?.teamName,
                  value: selectedAccountDetails?.teamId,
                }
              : undefined,
        })
      );
    }

    if (!selectedAccountDetails?.vendorId && selectedVendor?.value) {
      dispatch(
        setSelectedAccountDetails({
          ...selectedAccountDetails,
          vendorId: selectedVendor?.value,
          vendorName: selectedVendor?.label,
        })
      );
    }
  }, [selectedAccountDetails, addressList]);

  useEffect(() => {
    setIsFetched(false);
  }, [selectedAccountDetails]);

  const retriveAccountInformationDropdown = async () => {
    setBusy(true);
    if (selectedAccountDetails?.vendorId && selectedAccountDetails?.accountId) {
      setSearching(true);
      await dispatch(
        getAccountsbysearch({
          searchString: selectedAccountDetails?.email,
          accountId: selectedAccountDetails?.accountId,
          vendorId: selectedAccountDetails?.vendorId,
        })
      );
    } else if (
      allPurchaseOrderDetails?.account?.vendorId &&
      allPurchaseOrderDetails?.account?.accountId
    ) {
      setSearching(true);
      await dispatch(
        getAccountsbysearch({
          searchString: allPurchaseOrderDetails?.account?.email,
          accountId: allPurchaseOrderDetails?.account?.accountId,
          vendorId: allPurchaseOrderDetails?.account?.vendorId,
        })
      );
    }
    setBusy(false);
  };

  useEffect(() => {
    retriveAccountInformationDropdown();
  }, [allPurchaseOrderDetails]);

  useEffect(() => {
    if (FetchedAccounts && FetchedAccounts?.length > 0) {
      for (let i = 0; i < FetchedAccounts?.length; i++) {
        if (
          (selectedAccountDetails?.accountId == FetchedAccounts[i].accountID &&
            selectedAccountDetails?.vendorId == FetchedAccounts[i].vendorId) ||
          (FetchedAccounts[i].accountID ==
            allPurchaseOrderDetails?.account?.accountId &&
            FetchedAccounts[i].vendorId ==
              allPurchaseOrderDetails?.account?.vendorId)
        ) {
          setSelectedAccount({
            value: FetchedAccounts[i].email,
            label: FetchedAccounts[i].name,
            data: FetchedAccounts[i],
          });
          setIsAccountSelected(true);

          break;
        }
      }
    }

    // stopping the loader
    setSearching(false);
  }, [FetchedAccounts]);

  const HandleEditDetails = (val, type, name) => {
    if (type === "account") {
      dispatch(
        EditSelectedAccount({
          [val?.target?.name ?? name]: val?.target?.value ?? val,
        })
      );
    } else if (type === "address") {
      dispatch(
        EditSelectedAddress({
          [val?.target?.name ?? name]: val?.target?.value ?? val,
        })
      );
    } else {
      dispatch(
        StoreDetails({ [val?.target?.name ?? name]: val?.target?.value ?? val })
      );
    }
  };

  const customFilterOption = (option, inputValue) => {
    return (
      option.data.data.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.data.email.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  //add new address or update existing one
  const onEntrySubmitted = async (data) => {
    try {
      const message = data.id
        ? `Address Updated Succesfully`
        : `New Address Created Succesfully`;
      setSelectedAddress({
        label: `${data?.address1} ${data?.address2} ${data?.city} ${data?.state} ${data?.postalCode}`,
        value: data,
      });
      const action = data?.id ? updateAddress(data) : createAddress(data);
      const response = await dispatch(action);

      if (response?.payload?.id) {
        HandleEditDetails(response?.payload?.id, "account", "addressBookId");
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setOpenAddress(false);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    }
    setIsAddClicked(false);
  };

  const RenderImages = (crm, quicktix, showquick, showmonday) => {
    if (!showmonday && !showquick) return null;
    return (
      <div className="d-flex">
        {/* CRM */}
        {showmonday && (
          <div className="mx-2 d-flex flex-column">
            {/* Operation */}
            {crm ? (
              <FaRegCheckCircle size={15} color="green" />
            ) : (
              <GiCancel size={15} color="red" />
            )}
            {/* Images */}
            <img src={mondaycrop} alt="monday" width={15} height={15} />
          </div>
        )}
        {/* Salem images */}
        {showquick && (
          <div className="mx-2 d-flex flex-column">
            {/* Operation */}
            {quicktix ? (
              <FaRegCheckCircle size={15} color="green" />
            ) : (
              <GiCancel size={15} color="red" />
            )}
            {/* Images */}
            <img src={salemimg} alt="salem" width={15} height={15} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      setIsAccountSelected(null);
      setSelectedAccount(null);
    }
    if (FetchedAccounts && FetchedAccounts?.length > 0) {
      removeAccountList();
    }
  }, [selectedVendor]);

  //quicktix states
  useEffect(async () => {
    const isquicktixAccount = accountVendorCombination;
    const quicktixNameMatch =
      selectedAccountDetails.name === isquicktixAccount?.name;
    const quicktixEmailMatch =
      selectedAccountDetails.email === isquicktixAccount?.email;
    setQuicktixMatch({
      nameMatch: quicktixNameMatch,
      emailMatch: quicktixEmailMatch,
    });
  }, [accountVendorCombination]);

  // monday states .
  useEffect(() => {
    let mondayDetail = mondayAccountDetails;
    mondayDetail =
      mondayDetail && mondayDetail.length > 0 ? mondayDetail[0] : null;
    // Determine the match results for Monday
    const mondayNameMatch = selectedAccountDetails.name === mondayDetail?.name;
    const mondayEmailMatch =
      selectedAccountDetails.email === mondayDetail?.email;
    setMondayMatch({
      nameMatch: mondayNameMatch,
      emailMatch: mondayEmailMatch,
    });
  }, [mondayAccountDetails]);

  //TODO : Call it at the time when any entry from the account dropdown gets selected.
  const matchNameEmail = async () => {
    if (isUploaded) {
      dispatch(
        getAccountsDetails({
          accountId: selectedAccountDetails?.accountId,
          vendorId: selectedVendor?.value,
        })
      );
      dispatch(getMondayDetails(selectedAccountDetails?.email));
    }
  };

  useEffect(() => {
    matchNameEmail();
  }, []);

  const style2 = {
    ...reactSelectStyle,
    valueContainer: (provided) => ({
      ...provided,
      padding: isAccountSelected ? "12px 8px" : "0px 8px", // Adjust padding inside the select input
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#007bff" : "#ced4da",
      boxShadow: state.isFocused ? "0 0 0 1px #007bff" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#007bff" : "#ced4da",
      },
      width: "100%",
      minHeight: isAccountSelected ? "54px" : "32px", // Reduce this value to decrease height
      height: isAccountSelected ? "54px" : "32px", // Adjust height directly
    }),
    menu: (provided) => ({
      ...provided,
      top: "auto",
      bottom: "100%",
      minWidth: "400px",
    }),
  };

  return (
    <>
      <div className="px-4 pt-4 d-flex flex-column gap-4">
        {/* Accounts */}

        <div className="d-flex justify-content-between ">
          <div className="form-group d-flex justify-content-start align-items-center w-50">
            <label
              className="form-label col-form-label "
              style={custom_styles.font_label}
            >
              Vendor <span style={{ color: "red" }}>*</span>
            </label>
            <div className="input-container  w-75 d-flex">
              <Select
                isClearable
                options={vendorList?.map((vendor) => ({
                  value: vendor?.id,
                  label: vendor?.name,
                }))}
                onChange={(e) => {
                  setSelectedVendor(e);
                  HandleEditDetails(e?.label, "account", "vendorName");
                  HandleEditDetails(e?.value, "account", "vendorId");
                }}
                className="w-75"
                placeholder="Type & Select"
                styles={reactSelectStyle}
                value={
                  selectedVendor
                    ? selectedVendor
                    : selectedAccountDetails?.vendorName
                }
                required
              />
              {RenderImages(true, false)}
            </div>
          </div>
          <div className="form-group d-flex align-items-center w-50">
            <label
              className="form-label col-form-label"
              style={custom_styles.font_label}
            >
              Account
            </label>
            <div className="input-container w-75 d-flex">
              <div className="d-flex flex-column gap-2 w-100">
                <Select
                  required
                  options={FetchedAccounts.map((account) => ({
                    value: account.email,
                    label: account.name,
                    data: account,
                  }))}
                  className="w-75"
                  styles={style2}
                  value={selectedAccount}
                  inputValue={searchedvalue}
                  isLoading={isSearching}
                  isDisabled={selectedVendor ? false : true}
                  getOptionLabel={(option) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="" style={{ width: "60%" }}>
                          <span style={{ fontSize: "14px", color: "#000000" }}>
                            {option?.label ?? option?.name}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {option?.value ?? option?.email}
                          </span>
                        </div>
                        {isAccountSelected == null ? (
                          <div
                            className="d-flex align-items-center mx-2 "
                            style={{ width: "40%" }}
                          >
                            <img src={Done} alt="" style={{ height: "11px" }} />
                            <div className="d-flex flex-column mx-1">
                              {option?.data?.monday &&
                                option?.data?.quicktix && (
                                  <div style={{ fontSize: "0.6rem" }}>
                                    Available in{" "}
                                    <span className="fw-bold">Monday CRM </span>
                                    and{" "}
                                    <span className="fw-bold">Quicktix</span>
                                  </div>
                                )}

                              {option?.data?.quicktix &&
                                !option?.data?.monday && (
                                  <div style={{ fontSize: "0.6rem" }}>
                                    Available in{" "}
                                    <span className="fw-bold">QuickTix</span>
                                  </div>
                                )}
                              {!option?.data?.quicktix &&
                                option?.data?.monday && (
                                  <div style={{ fontSize: "0.6rem" }}>
                                    Available in{" "}
                                    <span className="fw-bold">Monday CRM</span>
                                  </div>
                                )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  }}
                  onChange={HandleSelectedAccount}
                  filterOption={customFilterOption}
                  onInputChange={handleInputChange}
                  isClearable
                  placeholder="Type & Select"
                  name="account"
                />
                {isAccountSelected != null ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "0.6rem" }}
                  >
                    <div className="mx-1">
                      <img src={Done} alt="" style={{ height: "11px" }} />
                    </div>

                    {selectedAccount.data.monday &&
                    selectedAccount.data.quicktix ? (
                      <div style={{ fontSize: "0.6rem" }}>
                        Available in{" "}
                        <span className="fw-bold">Monday CRM </span>
                        and <span className="fw-bold">Quicktix</span>
                      </div>
                    ) : selectedAccount.data.monday &&
                      !selectedAccount.data.quicktix ? (
                      <div style={{ fontSize: "0.6rem" }}>
                        Available in{" "}
                        <span className="fw-bold">Monday CRM </span>
                      </div>
                    ) : !selectedAccount.data.monday &&
                      selectedAccount.data.quicktix ? (
                      <div style={{ fontSize: "0.6rem" }}>
                        Available in <span className="fw-bold">Quicktix</span>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <span style={{ color: "#00000080", fontSize: "12px" }}>
                    Type and select account name and email to fetch the account
                    details.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ ...custom_styles.border2, margin: "0" }}></div>

        {/* Other details */}
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "250px" }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="d-flex flex-column gap-2">
            {/* Name | Account Id*/}
            <div className="d-flex justify-content-between ">
              <div className="form-group d-flex align-items-center w-50">
                <label
                  className="form-label col-form-label"
                  style={custom_styles.font_label}
                >
                  Name <span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-container w-75 d-flex align-items-center gap-3">
                  <input
                    name="name"
                    placeholder="Enter "
                    className="form-control w-75"
                    value={selectedAccountDetails?.name ?? ""}
                    onChange={(e) => {
                      HandleEditDetails(e, "account");
                      setIsAccountSelected(null);
                      setSelectedAccount(null);
                    }}
                    style={{ ...custom_styles.form_input }}
                    required
                  />
                  {RenderImages(true, false)}
                  {mondayMatch.nameMatch == true && isUploaded ? (
                    <img src={Done} alt="" style={{ height: "13px" }} />
                  ) : null}
                </div>
              </div>
              <div className="form-group d-flex justify-content-end w-50">
                <div className="form-label text-start col-form-label">
                  <label style={custom_styles.font_label}>
                    Account ID <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="input-container d-flex justify-content-end  w-75">
                  <input
                    name="accountId"
                    placeholder="Enter"
                    type="text"
                    className="form-control w-75"
                    value={selectedAccountDetails?.accountId ?? ""}
                    onChange={(e) => {
                      const regex = /^\d+$/;
                      if (
                        (!e?.target?.value || !regex.test(e.target.value)) &&
                        e?.target?.value != ""
                      ) {
                        return;
                      }
                      HandleEditDetails(e, "account");
                    }}
                    style={{ ...custom_styles.form_input }}
                    required
                  />
                  {RenderImages(true, false, false, false)}
                </div>
              </div>
            </div>

            {/* Emails | Phone Number */}
            <div className="d-flex justify-content-between ">
              <div className="form-group d-flex align-items-center w-50">
                <label
                  className="form-label col-form-label "
                  style={custom_styles.font_label}
                >
                  SS Email <span style={{ color: "red" }}>*</span>
                </label>
                <div className=" w-75 d-flex align-items-center gap-3">
                  <input
                    name="email"
                    placeholder="Enter email"
                    className="form-control w-75"
                    value={selectedAccountDetails?.email ?? ""}
                    onChange={(e) => HandleEditDetails(e, "account")}
                    style={{ ...custom_styles.form_input }}
                    required
                  />
                  {RenderImages(true, false)}
                  {mondayMatch.emailMatch == true && isUploaded ? (
                    <img src={Done} alt="" style={{ height: "13px" }} />
                  ) : null}
                </div>
              </div>
              <div className="form-group d-flex justify-content-end w-50">
                <div className="form-label text-start col-form-label">
                  <label style={custom_styles.font_label}>SS Phone</label>
                </div>

                <div className="input-container d-flex justify-content-end  w-75">
                  <input
                    type="text"
                    placeholder="+1"
                    name="mobileNo"
                    value={
                     
                      formatPhoneNumber(selectedAccountDetails.mobileNo)
                    }
                    onChange={(e) => {
                      HandleEditDetails(e, "account", "mobileNo");
                    }}
                    className="form-control w-75"
                    style={{ ...custom_styles.form_input }}
                    required
                  />
                  {RenderImages(true, false, false, false)}
                </div>
              </div>
            </div>

            {/* Address Section */}
            <div className="d-flex justify-content-between ">
              {/* Address 1 */}
              <div className="form-group d-flex align-items-center w-50">
                <label
                  className="form-label col-form-label"
                  style={custom_styles.font_label}
                >
                  Address <span style={{ color: "red" }}>*</span>
                </label>
                <div className="d-flex w-75">
                  <Select
                    required
                    isClearable
                    options={addressList.map((address) => ({
                      value: address,
                      label: `${address?.address1} ${address?.address2} ${address?.city} ${address?.state} ${address?.postalCode}`,
                    }))}
                    onChange={(e) => {
                      setSelectedAddress(e);
                      HandleEditDetails(
                        e?.value.id,
                        "account",
                        "addressBookId"
                      );
                      HandleEditDetails(e?.label, "account", "address");
                    }}
                    placeholder="Type & Select"
                    styles={reactSelectStyle}
                    value={
                      selectedAddress
                        ? selectedAddress
                        : selectedAccountDetails?.address
                        ? {
                            label: selectedAccountDetails.address,
                            value: selectedAccountDetails.address,
                          }
                        : null
                    }
                    className="w-75"
                  />
                  {selectedAddress && (
                    <span
                      className="btn d-flex justify-content-center ms-2 align-items-center btn-primary text-light"
                      style={{ ...custom_styles.add_btn }}
                      onClick={() => setOpenAddress(true)}
                    >
                      <span className="fs-5">
                        <MdOutlineEdit />
                      </span>
                    </span>
                  )}

                  <span
                    className="btn d-flex justify-content-center ms-2 align-items-center btn-primary text-light"
                    style={{ ...custom_styles.add_btn }}
                    onClick={() => {
                      setIsAddClicked(true);
                      setOpenAddress(true);
                    }}
                  >
                    <span className="fs-3">+</span>
                  </span>
                </div>
              </div>
            </div>

            {/* Address in details */}
            {selectedAddress && (
              <div className="d-flex flex-column gap-4 mt-4 my-3">
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex gap-4 addresssWidth">
                    <KeyValueLabel
                      justify={true}
                      label={"Address 1"}
                      value={
                        selectedAddress?.value?.address1
                          ? selectedAddress?.value?.address1
                          : "-"
                      }
                    />
                    <KeyValueLabel
                      justify={true}
                      label={"Address 2"}
                      value={
                        selectedAddress?.value?.address2
                          ? selectedAddress?.value?.address2
                          : "-"
                      }
                    />
                    <KeyValueLabel
                      justify={true}
                      label={"City"}
                      value={
                        selectedAddress?.value?.city
                          ? selectedAddress?.value?.city
                          : "-"
                      }
                    />
                    <KeyValueLabel
                      justify={true}
                      label={"State"}
                      value={
                        selectedAddress?.value?.state
                          ? selectedAddress?.value?.state
                          : "-"
                      }
                    />
                    <KeyValueLabel
                      justify={true}
                      label={"Country"}
                      value={"United States"}
                    />
                    <KeyValueLabel
                      label={"Zip Code"}
                      justify={true}
                      value={
                        selectedAddress?.value?.postalCode
                          ? selectedAddress?.value?.postalCode
                          : "-"
                      }
                    />
                  </div>
                  {/* <div className="d-flex justify-content-between gap-4">
                    <KeyValueLabel
                      label={"State"}
                      value={selectedAddress?.value?.state}
                    />
                    <KeyValueLabel label={"Country"} value={"United States"} />
                    <KeyValueLabel
                      label={"Zip Code"}
                      value={selectedAddress?.value?.postalCode}
                    />
                  </div> */}
                </div>
              </div>
            )}
            {selectedVendor && selectedAccount && (
              <WarningMessage
                selectedVendor={selectedVendor}
                selectedAccount={selectedAccount}
                quicktixMatch={quicktixMatch}
                mondayMatch={mondayMatch}
              />
            )}
          </div>
        )}
      </div>

      {openAddress && (
        <AddEditAddressModal
          isVisible={openAddress}
          handleClose={() => {
            setOpenAddress(false);
            setIsAddClicked(false);
          }}
          onSubmit={onEntrySubmitted}
          selectedRecord={isAddClicked ? null : selectedAddress?.value}
        />
      )}
    </>
  );
};

export default withLoader(InvoiceAccountDetails);
