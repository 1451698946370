export const reactSelectStyle = {
  container: (provided) => ({
    ...provided,
    // width: "263px",
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#007bff" : "#ced4da",
    boxShadow: state.isFocused ? "0 0 0 1px #007bff" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "#007bff" : "#ced4da",
    },
    width: "100%",
    minHeight: "32px", // Reduce this value to decrease height
    height: "32px", // Adjust height directly
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: "4px", // Adjust padding to reduce the size of the clear indicator
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 8px", // Adjust padding inside the select input
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px", // Remove any extra margin inside the input
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#007bff"
      : state.isFocused
      ? "#e9ecef"
      : "#fff",
    color: state.isSelected ? "#fff" : "#000",
    "&:active": {
      backgroundColor: state.isSelected ? "#0056b3" : "#e9ecef",
    },
  }),
  menu: (provided) => ({
    ...provided,
    top: "auto",
    bottom: "100%",
    minWidth: "300px",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px", // Reduce placeholder font size
  }),
  singleValue: (provided) => ({
    ...provided,
    // color: "#495057",
    height: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
  }),
};
