// MODALS
import React, { useState, useEffect, useMemo } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { MdOutlineIndeterminateCheckBox } from "react-icons/md";
import Up from "../../images/uparrow.svg";
import Down from "../../images/downarrow.svg";
import AddEditPOModal from "./modals/AddEditPOModal";
import { columnError } from "../../shared/components/table/data/error";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import MovePOConfirmationModal from "./modals/MovePOConfirmationModal";
import Export from "../../images/export.svg";
import Reset from "../../images/reset.svg";
import DeleteIcon from "../../images/Remove.svg";
import FileMoveIcon from "../../images/drive_file_move.svg";
import EditIcon from "../../images/edit.svg";
import HistoryIcon from "../../images/history1.svg";
import AddEditSeasonModal from "../season/modals/AddEditSeasonModal";
import SelectMatch from "../transactions/modals/SelectMatch";
import CollapsibleTable from "./CollapsibleTable";
import IconButton from "@mui/material/IconButton";
import HistoryPO from "./modals/HistoryPO";
import CardPanel from "../../shared/components/panels/card-panel/CardPanel";
import TableRowCard from "../../shared/components/table/TableRowCard";
import DateFilter from "../transactions/modals/DateFilter";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import { withLoader } from "../../utils/hoc/withLoader";
import FilterPanelPO from "./filter-panel";
import Loader from "../../shared/components/loader/Loader";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  createSeason,
  getSeasonsList,
} from "../../data/reducers/season/season.reducer";
import {
  formatDate,
  dateSlashFormatToDateObject,
  formatPlanDate,
} from "../../utils/formatter/dateFormatter";
import {
  createPO,
  updatePO,
  deletePO,
  exportSelectedAsCSVPaymentPalns,
  exportCSV,
  exportPDF,
  changePOEditState,
  togglePOSkyboxRef,
  exportCSVPaymentPlans,
  setInvoiceDetailsManually,
  setAdditionalDetails,
  setPurchasePaymentDetails,
  setpendingPo,
  setshowStepperPO,
  setGlobalMaxStep,
  deleteDraft,
  setisAddFromInvoice,
  setIsPOSubmitted,
} from "../../data/reducers/purchaseOrders/po.reducer";
import "../../shared/styles/ModalStyles.scss";
import { setSelectedPurchaseOrders } from "../../data/reducers/purchaseOrders/po.reducer";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import {
  fetchPurchaseOrders,
  fetchPurchaseOrdersDraft,
  flushPurchaseOrders,
} from "../../data/reducers/purchaseOrders/cached-po.reducer";
import {
  fetchPurchaseOrdersCards,
  flushPurchaseOrdersCards,
  loadPurchaseOrderTransactionCards,
} from "../../data/reducers/purchaseOrders/cached-po-cards.reducer";

import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import Run from "../../images/run.svg";
import {
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} from "../../data/reducers/accounts/accounts.reducers";
import { setSelectedDraftPO } from "../../data/reducers/purchaseOrders/po.reducer";
import { isDraft, isPending } from "@reduxjs/toolkit";
import { flushTransactions } from "../../data/reducers/transactions/cached-transactions.reducer";
import { flushTransactionsCards } from "../../data/reducers/transactions/cached-transaction-cards.reducer";
const ISREVIEWED = {
  0: "ALL",
  1: "NOSKYBOX",
  2: "REVOKED",
};

const ISREVIEWED_STR_TO_NUM = {
  ALL: 0,
  NOSKYBOX: 1,
  REVOKED: 2,
};

const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "SortDir") {
      return value !== "Ascending";
    }
    if (key === "isReviewed") {
      return value !== "ALL";
    }
    if (key === "isDraft") return value === true;

    if (
      key === "PurchaseOrderId" ||
      key === "Description" ||
      key === "SkyBoxPurchaseId" ||
      key === "SeasonId" ||
      key === "month" ||
      key === "SortField" ||
      key === "searchString" ||
      key === "SportId" ||
      key === "AccountId" ||
      key === "TeamId" ||
      key === "VendorId"
    ) {
      return value !== "";
    }
    return true;
  });

  const newFilters = Object.fromEntries(arr);
  if (newFilters.StartDate) {
    newFilters.StartDate = formatDate(newFilters.StartDate);
  }
  if (newFilters.EndDate) {
    newFilters.EndDate = formatDate(newFilters.EndDate);
  }
  return newFilters;
};

const MONTH_ARRAY = [
  {
    id: 1,
    name: "Last 12 Month",
    value: 12,
  },
  {
    id: 2,
    name: "Last 24 Month",
    value: 24,
  },
  {
    id: 3,
    name: "Last 36 Month",
    value: 36,
  },
  {
    id: 4,
    name: "Custom Range",
    value: null,
  },
];

const PurchaseOrderDashboard = ({ setBusy }) => {
  //dispatch
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // SELECTORS

  const {
    data: cachedPurchaseOrdersCards,
    loading: cardsLoading,
    callCardsApi,
  } = useSelector((state) => state.cachedPurchaseOrdersCards);

  const {
    data: cachedPurchaseOrders,
    loading,
    draftPOCount,
  } = useSelector((state) => state.cachedPurchaseOrders);

  // USESTATES
  const [monthSelect, setMonthSelect] = useState(12);
  const [exportLoading, setExportLoading] = useState(false);
  const [Runrules, setRunrules] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [addPOModalVisible, setAddEditPOModalVisibility] = useState(false);
  const [historyModalVisible, setHistoryModalVisibility] = useState(false);
  const [moveConfirmationVisible, setMoveConfirmationVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);

  const [deleteConfirmationVisibleDraft, setDeleteConfirmationVisibilityDraft] =
    useState(false);

  const [addSeasonModalVisibility, setAddSeasonModalVisibility] =
    useState(false);
  const { isPOSubmitted } = useSelector((state) => state.purchaseOrder);

  const [draftCount, setDraftCount] = useState(0);

  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    VendorId: params.get("VendorId") || "",
    PageNumber: Number(params.get("PageNumber")) || 1,
    PageSize: Number(params.get("PageSize")) || 40,
    PurchaseOrderId: params.get("PurchaseOrderId") || "",
    Description: params.get("Description") || "",
    AccountId: params.get("AccountId") || "",
    SortDir: params.get("SortDir") || "Ascending",
    SortField: params.get("SortField") || "",
    isReviewed: Number(params.get("isReviewed")) || "ALL",
    SportId: Number(params.get("SportId")) || "",
    TeamId: params.get("TeamId") || "",
    isDraft: params.get("isDraft") || false,
    StartDate:
      dateSlashFormatToDateObject(params.get("StartDate")) ||
      new Date(new Date().setMonth(new Date().getMonth() - 12)),
    EndDate: dateSlashFormatToDateObject(params.get("endDate")) || new Date(),
    SeasonId: params.get("SeasonId") || "",
    SkyBoxPurchaseId: params.get("SkyBoxPurchaseId") || "",
    month: params.get("month") || "",
    statusId: 4,
  });

  // MEMOISED VALUES
  const { purchaseOrders, paging, timestamp } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/purchases?${queryParams}`);
    const data =
      cachedPurchaseOrders?.[
        JSON.stringify({
          ...filters,
          StartDate: formatDate(filters.StartDate),
          EndDate: formatDate(filters.EndDate),
        })
      ];
    if (!data) {
      return { purchaseOrders: undefined, paging: undefined, timestamp: null };
    }
    return data;
  }, [cachedPurchaseOrders, filters]);

  const { cards } = useMemo(() => {
    const stringifiedFilters = JSON.stringify({
      ...filters,
      StartDate: formatPlanDate(filters.StartDate),
      EndDate: formatPlanDate(filters.EndDate),
    });
    const data = cachedPurchaseOrdersCards?.[stringifiedFilters];
    if (!data) {
      return { data: undefined };
    }
    return data;
  }, [cachedPurchaseOrdersCards, filters]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.PURCHASEORDERS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.PURCHASEORDERS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!purchaseOrders) {
      dispatch(
        fetchPurchaseOrders({
          ...filters,
          StartDate: formatDate(filters.StartDate),
          EndDate: formatDate(filters.EndDate),
        })
      );
      dispatch(
        fetchPurchaseOrdersDraft({
          ...filters,
          isDraft: true,
          PageSize: 1,
          StartDate: formatDate(filters.StartDate),
          EndDate: formatDate(filters.EndDate),
        })
      );
    }
  }, [filters]);

  useEffect(() => {
    if (!cards && callCardsApi != null) {
      dispatch(
        fetchPurchaseOrdersCards({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
        })
      );
    }
  }, [callCardsApi]);

  useEffect(() => {
    if (draftPOCount) {
      setDraftCount(draftPOCount);
    }
  }, [draftPOCount]);

  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const refetchAccountsAndCards = async () => {
    dispatch(
      fetchPurchaseOrders({
        ...filters,
        StartDate: formatDate(filters.StartDate),
        EndDate: formatDate(filters.EndDate),
      })
    );
  };

  // Run Rules
  const callRunRules = async () => {
    try {
      setRunrules(true);
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setRunrules(false);
    }
  };

  const resetHandler = async () => {
    dispatch(flushPurchaseOrders());
    dispatch(flushPurchaseOrdersCards());
    refetchAccountsAndCards();
    setSelectedCheckboxes({});
    setSelectAll(false);
    setIsPOSubmitted(false);
  };

  useEffect(() => {
    // when PO is submitted then refresh the page to display updated entry
    if (isPOSubmitted == true) {
      resetHandler();
    }
  }, [isPOSubmitted]);

  // DELETE
  const onDeletePurchaseOrder = async (id) => {
    try {
      setBusy(true);
      const response = await dispatch(deletePO(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        resetHandler();
        dispatch(successMessage("Purchase Order was deleted successfully"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };
  const onDeleteDraft = async () => {
    try {
      setBusy(true);
      const selectedIds = Object.keys(selectedCheckboxes).filter(
        (id) => selectedCheckboxes[id]
      );

      const response = await dispatch(deleteDraft(selectedIds));
      if (response || response.status === "Success") {
        setDeleteConfirmationVisibilityDraft(false);
        resetHandler();
        dispatch(
          successMessage(response.statusMessage || "Deleted successfully")
        );
      } else {
        dispatch(errorMessage(response.error?.message || "An error occurred"));
      }
    } catch (e) {
      dispatch(errorMessage(e.message || "An unexpected error occurred"));
    } finally {
      setBusy(false);
    }
  };

  const addSeason = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      try {
        const message = data.id
          ? `Season Updated Succesfully`
          : `New Season Created Succesfully`;
        setBusy(true);
        const action = createSeason(data);
        const response = await dispatch(action);
        await dispatch(getSeasonsList());
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage(message));
          setAddSeasonModalVisibility(false);
          setAddEditPOModalVisibility(true);
        }
      } catch (e) {
        dispatch(errorMessage(e));
      } finally {
        setBusy(false);
      }
    }
  };

  const movePOHandler = async (id) => {
    try {
      setBusy(true);

      const response = await dispatch(togglePOSkyboxRef(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setMoveConfirmationVisible(false);
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // ADD/UPDATE PO
  const addEditHandler = async (_data) => {
    const data = _data;
    data.showInNoSkyboxRef = !!_data.showInNoSkyboxRef;
    data.totalPaid = _data.totalPaid == null ? 0 : _data.totalPaid;
    try {
      setBusy(true);
      const message = data.id
        ? `Purchase Order Updated Succesfully`
        : `New Purchase Order Created Succesfully`;
      const action = data?.id
        ? updatePO({
            ...data,
            purchaseOrderId: String(data?.purchaseOrderId),
            totalScheduled:
              data?.totalScheduled != ""
                ? parseFloat(data?.totalScheduled)
                : parseFloat(0),
          })
        : createPO({
            ...data,
            purchaseOrderId: String(data?.purchaseOrderId),
            totalScheduled:
              data?.totalScheduled != ""
                ? parseFloat(data?.totalScheduled)
                : parseFloat(0),
            skyBoxRef:
              data?.skyBoxRef === "" ? null : data?.skyBoxRef?.toString(),
          });
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setAddEditPOModalVisibility(false);
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //cards data
  const cardsData = [
    {
      head: "Scheduled",
      val:
        cards?.scheduled == null || cards?.scheduled == undefined
          ? "--"
          : formatMoney(cards?.scheduled),
    },
    {
      head: "Paid",
      val:
        cards?.paid == null || cards?.paid == undefined
          ? "--"
          : formatMoney(cards?.paid),
    },
    {
      head: "Receivables",
      val:
        cards?.receivables == null || cards?.receivables == undefined
          ? "--"
          : formatMoney(cards?.receivables),
    },
    {
      head: "Transaction",
      val:
        cards?.numberofTransaction == null ||
        cards?.numberofTransaction == undefined
          ? "--"
          : cards?.numberofTransaction,
    },
  ];

  const exportSelectedAsCSV = async () => {
    try {
      setExportLoading(true);
      await dispatch(
        exportCSV({
          ...filters,
          isReviewed: ISREVIEWED_STR_TO_NUM[filters.isReviewed],
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(errorMessage("Something went wrong!"));
    } finally {
      setExportLoading(false);
    }
  };

  const onExportSelectedAsPdf = async () => {
    try {
      setExportLoading(true);
      await dispatch(
        exportPDF({
          ...filters,
          isReviewed: ISREVIEWED_STR_TO_NUM[filters.isReviewed],
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(errorMessage("Something went wrong!"));
    } finally {
      setExportLoading(false);
    }
  };

  const exportSelectedAsCSVPaymentPlans = async () => {
    try {
      setExportLoading(true);

      const {
        EndDate,
        StartDate,
        PageNumber,
        PageSize,
        SortDir,
        isReviewed,
        ...exportFilters
      } = filters;

      const hasFilters = Object.values(exportFilters).some((val) => val !== "");
      if (hasFilters) {
        await dispatch(
          exportSelectedAsCSVPaymentPalns({
            ...filters,
            ExportPaymentPlanData: true, // this new parameter is for exporting the payment plans for the current purchaseOrders
            isReviewed: ISREVIEWED_STR_TO_NUM[filters.isReviewed],
            StartDate: formatPlanDate(filters.StartDate),
            EndDate: formatPlanDate(filters.EndDate),
          })
        );
      } else {
        await dispatch(exportCSVPaymentPlans());
      }
    } catch (error) {
      console.error(error);
      dispatch(errorMessage("Something went wrong!"));
    } finally {
      setExportLoading(false);
    }
  };

  // handle add from invoice
  const handleAddFromInvoice = () => {
    //when clicked on the add PO from invoice, it is clearing state
    dispatch(setshowStepperPO(true));

    dispatch(setGlobalMaxStep(0));
    dispatch(setInvoiceDetailsManually(undefined));
    dispatch(setSelectedAccountDetails({}));
    dispatch(setSelectedTicketDetails({}));
    dispatch(setPurchasePaymentDetails({ calculated: {} }));
    dispatch(setPurchasePaymentDetails({ data: {} }));
    dispatch(setAdditionalDetails({}));
    dispatch(setisAddFromInvoice(true));

    history.push(`/purchase/add-invoice/account/0`);
    dispatch(setpendingPo(false));
  };
  const handleAddFromInvoicePO = (id) => {
    dispatch(setSelectedAccountDetails({}));
    dispatch(setSelectedTicketDetails({}));
    history.push(`/purchase/add-invoice/${id}/0`); // Use the id here
  };

  const handleDraft = () => {
    const newFilters = {
      ...filters,
      isDraft: true,
    };

    setFilters(newFilters);

    console.log("Updated Filters:", newFilters);

    const queryParams = new URLSearchParams(getValidFilters(newFilters));

    history.push(`/purchases?${queryParams}`);
    dispatch(
      fetchPurchaseOrders({
        ...newFilters,
        StartDate: formatDate(newFilters.StartDate),
        EndDate: formatDate(newFilters.EndDate),
      })
    );
  };

  useEffect(() => {
    // Log the purchase orders data whenever it changes
    if (purchaseOrders) {
      console.log("Fetched Purchase Orders:", purchaseOrders);
    }
  }, [purchaseOrders]);

  const handleGoBack = () => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    queryParams.delete("isDraft");

    history.push(`/purchases?${queryParams}`);
    setFilters({ ...filters, isDraft: false });
  };
  const selectedTabIndex = ISREVIEWED_STR_TO_NUM[filters.isReviewed];

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: isChecked,
    }));
    const selectedOrders = purchaseOrders.filter((order) => {
      return { ...selectedCheckboxes, [id]: isChecked }[order.id];
    });

    dispatch(setSelectedDraftPO(selectedOrders));
    const allSelected =
      Object.keys(selectedCheckboxes).length + 1 === purchaseOrders.length &&
      isChecked;

    setSelectAll(allSelected);
  };

  console.log("Selected Checkboxes:", selectedCheckboxes);

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;

    const newSelection = purchaseOrders.reduce((acc, order) => {
      acc[order.id] = isChecked;
      return acc;
    }, {});

    setSelectedCheckboxes(newSelection);
    setSelectAll(isChecked);
    dispatch(setSelectedDraftPO(isChecked ? purchaseOrders : []));
  };

  const anySelected = Object.values(selectedCheckboxes).some(Boolean);
  const allSelected =
    Object.keys(selectedCheckboxes).length > 0 &&
    Object.values(selectedCheckboxes).every(Boolean);

  const handleDeleteSelectedRecords = () => {
    const selectedIds = Object.keys(selectedCheckboxes).filter(
      (id) => selectedCheckboxes[id]
    );

    if (selectedIds.length > 0) {
      setDeleteConfirmationVisibilityDraft(true);
      setSelectedRecordId(selectedIds);
    } else {
      console.log("No records selected for deletion.");
    }
  };

  const poCol = [
    // Checkbox column only if isDraft is true
    ...(filters.isDraft
      ? [
          {
            columnName: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center", // Center vertically
                  margin: "10px 0", // Space between items
                }}
              >
                {anySelected && !allSelected ? (
                  <MdOutlineIndeterminateCheckBox
                    onClick={() => {
                      setSelectedCheckboxes({});
                      setSelectAll(false);
                      dispatch(setSelectedDraftPO([]));
                    }}
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckIndeterminate"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    style={{
                      width: "18px",
                      height: "18px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            ),
            flexGrow: 1,
            render: (data) => (
              <div className="form-check d-flex align-items-center">
                {" "}
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  id={`checkbox-${data.id}`}
                  checked={selectedCheckboxes[data.id] || false}
                  onChange={(e) => {
                    handleCheckboxChange(e, data.id);
                  }}
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </div>
            ),
          },
        ]
      : []),

    // Always show other columns
    {
      columnName: "Purchase Order",
      sort: true,
      sortName: "PurchaseOrderId",
      flexGrow: 2,
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={"/purchases/" + data.id}
            style={{ overflowWrap: "break-word" }}
          >
            {data.purchaseOrderId}
          </Link>
        </>
      ),
    },
    {
      columnName: "Date",
      sort: true,
      sortName: "PurchaseOrderDate",
      render: (data) => (
        <>{data?.purchaseOrderDate ? formatDate(data.purchaseOrderDate) : ""}</>
      ),
    },
    {
      columnName: "Vendor",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data.vendorId}
          style={{ overflowWrap: "break-word" }}
        >
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
      sort: true,
      sortName: "VendorId",
    },
    {
      columnName: "Name / Account ID",
      sort: true,
      sortName: "AccountId",
      render: (data) => (
        <>
          {" "}
          {data?.autoGenerated === false ? (
            <Link
              variant="link"
              className="ss-link"
              to={"/accounts/" + data.accountId}
            >
              {data.accountName} <br />
              {data.accountId == 0 ? "" : data.accountId}
            </Link>
          ) : (
            <span>
              {data.accountName} <br />
              {data.accountId == 0 ? "" : data.accountId}
            </span>
          )}
        </>
      ),
    },
    {
      columnName: "Season",
      columnKey: "seasonName",
      render: (row) => <>{row?.seasonName}</>,
    },
    {
      columnName: "Description",
      sort: true,
      sortName: "Description",
      flexGrow: 2,
      render: (data) => <>{data.description}</>,
    },
    {
      columnName: (
        <>
          SkyBox <br /> Ref{" "}
        </>
      ),
      sort: true,
      sortName: "SkyBoxRef",
      render: (row, ind, open, setOpen, getPurchaseOrderRefsData, data) => (
        <div>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (open[ind]) {
                setOpen(Array(data?.length).fill(false));
              } else {
                const temp = Array(data?.length).fill(false);
                temp[ind] = !temp[ind];
                setOpen(temp);
              }
              if (!open[ind]) {
                getPurchaseOrderRefsData(row?.id);
              }
            }}
          >
            <span style={{ fontSize: "14px" }}>{row.skyBoxRef}</span>
            <img
              src={open[ind] ? Up : Down}
              style={{ marginLeft: "5px" }}
              alt="up down image"
            />
          </IconButton>
        </div>
      ),
    },
    {
      columnName: "SkyBox Value",
      render: (data) => <>{formatMoney(data?.skyboxRefAmount)}</>,
    },
    {
      columnName: "Total Scheduled",
      sort: true,
      sortName: "TotalScheduled",
      render: (data) => <>{formatMoney(data.totalScheduled)}</>,
    },
    {
      columnName: (
        <>
          {" "}
          Total <br /> Paid{" "}
        </>
      ),
      render: (data) => <>{formatMoney(data.totalPaid)}</>,
    },
    {
      columnName: "Actions",
      flexGrow: 2,
      render: (data) => (
        <>
          <small className="table-row-card-actions d-flex gap-1">
            <Button
              variant="link"
              className="table-action-button green_bg"
              onClick={() => {
                // Dispatch the action to set the pending state
                dispatch(setpendingPo(false));
                dispatch(setshowStepperPO(true));
                if (!data.mainInvoiceType) {
                  dispatch(changePOEditState(true));
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setAddEditPOModalVisibility(true);
                } else {
                  if (!filters.isDraft) {
                    dispatch(setGlobalMaxStep(10));
                  } else {
                    dispatch(setGlobalMaxStep(0));
                  }
                  handleAddFromInvoicePO(data.id);
                }
              }}
            >
              <img
                style={{ height: "100%", width: "13px" }}
                src={EditIcon}
                alt="Edit Purchase Orders"
              />
            </Button>

            <Button
              variant="link"
              className="table-action-button"
              style={{
                display: "grid",
                placeContent: "center",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setSelectedRecordId(data.id);
                setSelectedRecord(data);
                setHistoryModalVisibility(true);
              }}
            >
              <img
                style={{ height: "auto", width: "25px" }}
                src={HistoryIcon}
                alt="Edit Purchase Orders"
              />
            </Button>

            {data?.autoGenerate === false && (
              <Button
                variant="link"
                className="table-action-button"
                onClick={() => {
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setDeleteConfirmationVisibility(true);
                  dispatch(flushTransactions());
                  dispatch(flushTransactionsCards());
                }}
              >
                <img
                  style={{ height: "100%", width: "13px" }}
                  src={DeleteIcon}
                  alt="Delete Purchase Orders"
                />
              </Button>
            )}
            {((selectedTabIndex == 0 && data?.showInNoSkyBoxRef == false) ||
              selectedTabIndex == 1) && (
              <Button
                variant="link"
                className="table-action-button"
                style={{
                  display: "grid",
                  placeContent: "center",
                  backgroundColor: "transparent",
                }}
                title={
                  selectedTabIndex == 0
                    ? 'Move to "no skybox ref"'
                    : 'Move to "all"'
                }
                onClick={() => {
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setMoveConfirmationVisible(true);
                }}
              >
                <img
                  style={{ height: "auto", width: "30px" }}
                  src={FileMoveIcon}
                  alt={
                    selectedTabIndex == 0
                      ? 'Move to "no skybox ref"'
                      : 'Move to "all"'
                  }
                />
              </Button>
            )}
          </small>
        </>
      ),
    },
  ];

  //table head options
  const headOptions = () => {
    return (
      <>
        {!filters.isDraft && (
          <>
            <div className="d-flex align-items-center justify-content-end">
              <small>Results per Page</small>
              <Form.Group className="ms-2">
                <Form.Control
                  as="select"
                  defaultValue={filters.PageSize}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      PageSize: e.target.value,
                    });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div>
              <div className="text-end mt-3 d-flex">
                {selectedTabIndex === 0 && !filters.isDraft && (
                  <Button
                    variant="primary"
                    style={{ textWrap: "nowrap" }}
                    className="mx-2 ss-light-button"
                    disabled={Runrules}
                    onClick={async (e) => {
                      e.currentTarget.blur();
                      Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                        buttonName: mixpanel_button_name.RUNRULES,
                        page: mixpanel_contants.TRANSACTIONS,
                      });
                      await callRunRules();
                      resetHandler();
                    }}
                  >
                    {Runrules ? <Loader /> : <img src={Run} alt=" " />}
                    <span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        Run Rules
                      </div>
                    </span>
                  </Button>
                )}

                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-autoclose-false"
                    className="button ss-light-button"
                    disabled={exportLoading}
                  >
                    <img className="filter-reset-svg" src={Export} alt=" " />
                    <span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        Export
                        {exportLoading && (
                          <Loader style={{ marginLeft: "8px" }} />
                        )}
                      </div>
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        exportSelectedAsCSV();
                        Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                          buttonName:
                            mixpanel_button_name.EXPORT_SELECTED_AS_CSV,
                          page: mixpanel_contants.PURCHASEORDERS,
                        });
                      }}
                    >
                      Export as CSV
                    </Dropdown.Item>
                    <hr className="hr-full" />
                    <Dropdown.Item
                      onClick={() => {
                        onExportSelectedAsPdf();
                        Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                          buttonName:
                            mixpanel_button_name.EXPORT_SELECTED_AS_PDF,
                          page: mixpanel_contants.PURCHASEORDERS,
                        });
                      }}
                    >
                      Export as PDF
                    </Dropdown.Item>
                    <hr className="hr-full" />
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip
                          id="tooltip-export-csv"
                          style={{ width: "700px !important" }}
                        >
                          Export will be filtered by Payment Plan date.
                        </Tooltip>
                      }
                    >
                      <Dropdown.Item
                        onClick={() => {
                          exportSelectedAsCSVPaymentPlans();
                          Mixpanel.track(
                            mixpanel_event_constants.BUTTON_CLICK,
                            {
                              buttonName:
                                mixpanel_button_name.EXPORT_SELECTED_PAYMENT_PLAN_AS_CSV,
                              page: mixpanel_contants.PURCHASEORDERS,
                            }
                          );
                        }}
                      >
                        Export Payment Plans as CSV
                      </Dropdown.Item>
                    </OverlayTrigger>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-autoclose-false"
                    className="button ss-light-button"
                  >
                    Add
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ boxShadow: "0px 2px 8px 0px #0000001A" }}
                    className="border-0"
                  >
                    <Dropdown.Item
                      onClick={async () => {
                        dispatch(changePOEditState(false));
                        await setSelectedRecord(undefined);
                        await setSelectedRecordId(undefined);
                        await setAddEditPOModalVisibility(true);
                        Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                          buttonName: mixpanel_button_name.ADD_PO,
                          page: mixpanel_contants.PURCHASEORDERS,
                        });
                      }}
                    >
                      Add New PO
                    </Dropdown.Item>
                    <hr className="hr-full" />
                    <Dropdown.Item onClick={handleAddFromInvoice}>
                      Add From Invoice
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  onClick={() => {
                    resetHandler();
                    dispatch(successMessage("Refresh successful"));
                  }}
                  variant="primary"
                  className="button ss-light-button"
                >
                  <img className="filter-reset-svg" src={Reset} alt=" " />
                  <span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      Refresh
                    </div>
                  </span>
                </Button>
              </div>

              <p
                style={{
                  visibility: timestamp == null && "hidden",
                  textAlign: "end",
                  fontSize: "12px",
                  lineHeight: 0,
                  paddingTop: "16px",
                  color: "#00000090",
                }}
              >
                Last sync {new Date(timestamp).toLocaleDateString()}{" "}
                {new Date(timestamp).toLocaleTimeString()}
              </p>
            </div>
          </>
        )}
        {filters.isDraft && (
          <Button
            className="no-underline button ss-light-button"
            variant="link"
            onClick={handleDeleteSelectedRecords}
          >
            <img
              className="img-button"
              style={{
                height: "100%",
                width: "16px",
                marginRight: "4px",
                color: "#000000 !important",
              }}
              src={DeleteIcon}
              alt="Delete Purchase Orders"
            />
            <span style={{ color: "#000000", fontWeight: "500" }}>Delete</span>
          </Button>
        )}

        <p
          style={{
            visibility: timestamp == null ? "hidden" : "visible",
            textAlign: "end",
            fontSize: "12px",
            lineHeight: 0,
            paddingTop: "16px",
            color: "#707070",
            font: "Poppins",
            fontWeight: "500",
          }}
        >
          {filters.isDraft ? "" : ` You have ${draftCount} draft PO. `}
          <span
            style={{
              textDecoration: "underline",
              color: "#0241A0",
              cursor: "pointer",
              font: "Poppins",
              fontWeight: "500",
            }}
            onClick={filters.isDraft ? handleGoBack : handleDraft}
          >
            {filters.isDraft ? "Back to PO" : "View Drafts"}
          </span>
        </p>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.PURCHASEORDERS,
        });
      }}
      className="container-fluid pt-3"
    >
      {!filters.isDraft && (
        <>
          <DateFilter
            monthArray={MONTH_ARRAY}
            monthSelect={monthSelect}
            setMonthSelect={setMonthSelect}
            setEndDate={(date) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                EndDate: date,
              }));
            }}
            setStartDate={(date) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                StartDate: date,
              }));
            }}
            startDate={filters.StartDate}
            endDate={filters.EndDate}
            loadButtonAction={loadPurchaseOrderTransactionCards(!callCardsApi)}
            showLoadButton={true}
            loadButtonText={"Load Card Values"}
          />

          {/* Summary Cards */}
          <CardPanel data={cardsData} loader={cardsLoading} />
        </>
      )}
      {/* Filter Panel */}
      <FilterPanelPO filters={filters} setFilters={setFilters} />

      {/* Data Table */}
      <CollapsibleTable
        loading={loading}
        align={"left"}
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName={
          filters.isDraft ? "DRAFT PURCHASE ORDERS" : "PURCHASE ORDERS"
        }
        paging={paging}
        pageType="purchase-orders"
        columns={poCol}
        data={purchaseOrders || []}
        headOptions={headOptions}
        tabs={["All", "No Skybox Ref", "Revoked"]}
        selectedTabIndex={ISREVIEWED_STR_TO_NUM[filters.isReviewed]}
        onTabSelectionChange={(i) => {
          setFilters({
            ...filters,
            isReviewed: ISREVIEWED[i],
            PageNumber: 1,
          });
        }}
        setBusy={setBusy}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        sortField={filters.SortField}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        setSortField={() => {}}
        setSortDir={() => {}}
        setpageNumber={() => {}}
        isDraft={filters.isDraft}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : poCol}
            rowData={rowData}
          />
        )}
      />

      {/* MODALS */}
      {addPOModalVisible && (
        <AddEditPOModal
          isVisible={addPOModalVisible}
          handleClose={() => setAddEditPOModalVisibility(false)}
          onSubmit={addEditHandler}
          selectedRecord={selectedRecord}
          setModalOpen={setAddSeasonModalVisibility}
          setSelectedRecord={setSelectedRecord}
          setBusy={setBusy}
        />
      )}

      {historyModalVisible && (
        <HistoryPO
          isVisible={historyModalVisible}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          handleClose={() => setHistoryModalVisibility(false)}
        />
      )}

      {deleteConfirmationVisible && (
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisible}
          handleClose={() => setDeleteConfirmationVisibility(false)}
          onConfirmation={() => onDeletePurchaseOrder(selectedRecordId)}
        />
      )}

      {deleteConfirmationVisibleDraft && (
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisibleDraft}
          handleClose={() => setDeleteConfirmationVisibilityDraft(false)}
          onConfirmation={() => onDeleteDraft(selectedRecordId)}
        />
      )}

      {moveConfirmationVisible && (
        <MovePOConfirmationModal
          isVisible={moveConfirmationVisible}
          handleClose={() => {
            setMoveConfirmationVisible(false);
          }}
          selectedRecord={selectedRecord}
          onConfirmation={movePOHandler}
        />
      )}

      {addSeasonModalVisibility && (
        <AddEditSeasonModal
          isVisible={addSeasonModalVisibility}
          onSubmit={addSeason}
          handleClose={() => {
            setAddSeasonModalVisibility(false);
            setAddEditPOModalVisibility(true);
          }}
        />
      )}

      {matchedVisibility && (
        <SelectMatch
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={() => setmatchedVisibility(false)}
          match={true}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};

export default withLoader(PurchaseOrderDashboard);
