import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import HistoryIcon from "../../../images/history2.svg";
import { withLoader } from "../../../utils/hoc/withLoader";
import "./historyModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPOHistory } from "../../../data/reducers/purchaseOrders/po.history.reducer";
import HistoryRow from "./HistoryRow";
import dot from "../../../images/dot.svg";
import Loader from "../../../shared/components/loader/Loader";
const HistoryPO = ({ isVisible, handleClose, selectedRecord }) => {
  const dispatch = useDispatch();
  const { historyList } = useSelector((state) => state.poHistoy);
  const [loading, setLoading] = useState(true); // Track loading state

  const [poHistoryList, setPoHistoryList] = useState([]);
  const fetchPOHistory = async () => {
    if (isVisible && selectedRecord) {
      try {
        setLoading(true);
        await dispatch(getPOHistory(selectedRecord.id)); // Wait for the dispatch to complete
      } finally {
        setLoading(false); // Stop loader after fetching
      }
    }
  };
  useEffect(() => {
    fetchPOHistory();
  }, [isVisible, selectedRecord]);

  useEffect(() => {
    if (historyList && isVisible) {
      setPoHistoryList(historyList);
    }
  }, [historyList, isVisible]);

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        className="historyModal"
        show={isVisible}
        onHide={handleClose}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <Image
                width={"24px"}
                style={{ marginTop: "-4px" }}
                src={HistoryIcon}
              />{" "}
              History
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="upperCon">
              <Row>
                <Col sm={4} className="light">
                  Purchase Order :{" "}
                  <span className="dark" style={{ color: "black" }}>
                    {selectedRecord?.purchaseOrderId}
                  </span>
                </Col>
                <Col sm={4} />
              </Row>
            </Container>

            <div className="tableCover">
              <Container className="tableHeader">
                <Row>
                  <Col>Updated by</Col>
                  <Col>Updated on</Col>

                  <Col>Action</Col>
                  <Col>Description</Col>

                  <Col>Updates</Col>
                </Row>
              </Container>

              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : poHistoryList.length === 0 ? (
                <p>No history available</p>
              ) : (
                poHistoryList.map((val) => (
                  <HistoryRow key={val.id} val={val} />
                ))
              )}
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(HistoryPO);
