import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { errorMessage, successMessage } from "../alerts/alert.reducer";
import { setAccountRejectedData } from "../accounts/accounts.reducers";

const cachedPurchaseOrdersSlice = createSlice({
  name: "cachedPurchaseOrders",
  initialState: {
    data: null,
    error: null,
    loading: false,
    draftPOCount: 0,
  },
  reducers: {
    fetchDataSuccess: (state, action) => {
      const { params, response } = action.payload;
      
      if (!state.data) {
        state.data = {
          [JSON.stringify(params)]: {
            purchaseOrders: response.data,
            paging: response.paging,
            timestamp: new Date(),
          },
        };
      } else {
        state.data[JSON.stringify(params)] = {
          purchaseOrders: response.data,
          paging: response.paging,
          timestamp: new Date(),
        };
      }
      state.loading = false;
    },
    fetchDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    flushStore: (state) => {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
    loadingStart: (state) => {
      state.loading = true;
    },
    loadingEnd: (state) => {
      state.loading = false;
    },
    fetchDraftDataSuccess: (state, action) => {
      const { params, response } = action.payload;
      state.draftPOCount = response.paging.totalItemsCount;
    },

  },
});

export const flushPurchaseOrders = createAsyncThunk(
  "cachedPurchaseOrders/flush",
  async (params, { dispatch }) => {
    dispatch(cachedPurchaseOrdersSlice.actions.flushStore());
  }
);

export const fetchPurchaseOrders = createAsyncThunk(
  "cachedPurchaseOrders/get",
  async (params, { dispatch }) => {
    try {
      dispatch(cachedPurchaseOrdersSlice.actions.loadingStart());
      const response = await api.get("PurchaseOrders/getall", params);
      dispatch(
        cachedPurchaseOrdersSlice.actions.fetchDataSuccess({
          params,
          response,
        })
      );
    } catch (error) {
      dispatch(
        cachedPurchaseOrdersSlice.actions.fetchDataFailure(error.message)
      );
      dispatch(errorMessage(error?.message ?? "Something went wrong!"));
    }
  }
);

export const fetchPurchaseOrdersDraft = createAsyncThunk(
  "cachedPurchaseOrders/getDraft",
  async (params, { dispatch }) => {
    try {
      dispatch(cachedPurchaseOrdersSlice.actions.loadingStart());
      const response = await api.get("PurchaseOrders/getall", params);
      dispatch(
        cachedPurchaseOrdersSlice.actions.fetchDraftDataSuccess({
          params,
          response,
        })
      );
    } catch (error) {
      dispatch(
        cachedPurchaseOrdersSlice.actions.fetchDataFailure(error.message)
      );
      dispatch(errorMessage(error?.message ?? "Something went wrong!"));
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "cachedPurchaseOrders/delete",
  async (id, { dispatch }) => {
    try {
      dispatch(cachedPurchaseOrdersSlice.actions.loadingStart());
      await api.delete("Accounts/delete", id);
      dispatch(cachedPurchaseOrdersSlice.actions.flushStore());
      dispatch(successMessage("Account was deleted successfully"));
    } catch (error) {
      dispatch(cachedPurchaseOrdersSlice.actions.fetchDataFailure(error));
      dispatch(errorMessage(error?.message ?? "Error updating account"));
    }
  }
);

export const updateAccount = createAsyncThunk(
  "cachedPurchaseOrders/update",
  async (payload, { dispatch }) => {
    try {
      dispatch(cachedPurchaseOrdersSlice.actions.loadingStart());
      await api.update("Accounts/update", payload);
      dispatch(cachedPurchaseOrdersSlice.actions.flushStore());
      if (!payload?.dontShowError) {
        dispatch(successMessage("Account was updated successfully"));
      }
    } catch (error) {
      dispatch(cachedPurchaseOrdersSlice.actions.fetchDataFailure(error));
      dispatch(errorMessage(error?.message ?? "Error updating account"));
    }
    finally {
      dispatch(setAccountRejectedData(undefined))
    }
  }
);

export const createAccount = createAsyncThunk(
  "cachedPurchaseOrders/create",
  async (payload, { dispatch }) => {
    try {
      dispatch(cachedPurchaseOrdersSlice.actions.loadingStart());
      await api.create("Accounts/create", payload);
      dispatch(cachedPurchaseOrdersSlice.actions.flushStore());
      dispatch(successMessage("Account was created successfully"));
    } catch (error) {
      dispatch(cachedPurchaseOrdersSlice.actions.fetchDataFailure(error));
      dispatch(errorMessage(error?.message ?? "Error creating account"));
    }
  }
);

export default cachedPurchaseOrdersSlice.reducer;
