import { SeasonServices } from "../../services/season/season.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";

export const createSeason = createAsyncThunk(
  "Season/CreateSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.createSeason(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getSeasonByID = createAsyncThunk(
  "Season/getSeasonByID",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.getSeasonById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateSeason = createAsyncThunk(
  "Season/updateSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.updateSeason(
      payload?.data,
      payload?.removeLocations
    );
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteSeason = createAsyncThunk(
  "Season/deleteSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.deleteSeason(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllSeason = createAsyncThunk(
  "Season/getAllSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.getAllSeason(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getSeasonsList = createAsyncThunk(
  "Season/getSeasonList",
  async (thunkAPI) => {
    const response = await SeasonServices.getSeasonsList();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVSeason = createAsyncThunk(
  "Season/exportcsv",
  async (thunkAPI) => {
    const response = await SeasonServices.exportCSVSeason();
    if (response.isSuccessful === true) {
      await fileDownload(response.data?.data, "Season.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVSeason = createAsyncThunk(
  "Season/exportCSVSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.exportSelectedAsCSVSeason(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "SeasonFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfSeason = createAsyncThunk(
  "Season/exportpdf",
  async (thunkAPI) => {
    const response = await SeasonServices.exportPdfSeason();
    if (response.isSuccessful === true) {
      await fileDownload(response.data, "Season.pdf");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsPdfSeason = createAsyncThunk(
  "Season/exportPdfSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.exportSelectedAsPdfSeason(payload);
    // if (response.isSuccessful === true) {
    //     fileDownload(response.data, "Season Filter.pdf")
    // } else {
    //     thunkAPI.rejectWithValue(response.message);
    //     throw new Error(response.message);
    // }
  }
);

export const filterSeason = createAsyncThunk(
  "Season/filterSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.filterSeason(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getSeasonDeatails = createAsyncThunk(
  "Season/getSeasonDeatails",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.getSeasonDeatails(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfSingleSeason = createAsyncThunk(
  "Seasons/exportPdfSingleSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.exportPdfSingleSeason(payload);
  }
);

export const exportExcelSingleSeason = createAsyncThunk(
  "season/exportExcelSingleSeason",
  async (payload, thunkAPI) => {
    const response = await SeasonServices.exportExcelSingleSeason(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.file, `Location-${payload?.data?.name}.xlsx`);
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const seasonSlice = createSlice({
  name: "season",
  initialState: {
    season: [],
    selectedSeason: {},
    paging: {},
    seasonList: [],
    seasonData: {
      data: [],
      paging: {},
    },
  },
  reducers: {
    setSeasonBySport: (state, action) => {
      state.seasonList = action.payload;
    },

  },
  extraReducers: {
    [getAllSeason.fulfilled]: (state, action) => {
      state.season = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getSeasonDeatails.fulfilled]: (state, action) => {
      state.seasonData.data = action.payload.data;
      state.seasonData.paging = action.payload.paging;
    },
    [getSeasonsList.fulfilled]: (state, action) => {
      state.seasonList = action.payload;
    },
    [createSeason.fulfilled]: (state, action) => {
      state.season.push(action.payload);
    },
    [getSeasonByID.fulfilled]: (state, action) => {
      state.selectedSeason = action.payload;
    },
    [updateSeason.fulfilled]: (state, action) => {
      const index = state.season.findIndex((po) => po.id == action.payload.id);
      state.season[index] = action.payload;
      state.selectedSeason = action.payload;
    },
    [filterSeason.fulfilled]: (state, action) => {
      state.season = action.payload.data;
      state.paging = action.payload.paging;
    },
    [deleteSeason.fulfilled]: (state, action) => {
      state.season = [
        ...state.season.filter((po) => po?.id !== action.payload.id),
      ];
    },
  },
});

export const { removeSelected, setSeasonBySport } = seasonSlice.actions;

export default seasonSlice.reducer;
