import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { MdRefresh } from "react-icons/md";
import { useDispatch } from "react-redux";
import { generateMaskingFromBackend } from "../../../data/reducers/purchaseOrders/po.reducer";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import { cloneDeep } from "lodash";

const TableComponent = ({ data, setData, setGenerateMasking }) => {
  const [isgenerate, setIsGenerate] = useState({
    isrow: false,
    isvariation: false,
  });
  const dispatch = useDispatch();

  const removeMasking = (innerData, index) => {
    const oldData = cloneDeep(data);
    oldData[index] = {
      ...oldData[index],
      possibleMaskingRows: "",
      generateMasking: {
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
      },
      maskinggenerated: false,
    };
    setData(oldData);
  };

  // this function will call the api and generate the masking for us from the backend.
  const generateMasking = async (innerData, index) => {
    const rowsExclusion = innerData?.rowExclusion
      ?.split(",")
      ?.map((item) => item?.trim());
    const row = innerData?.maskingRow;
    const maxRow = innerData?.maximumRow;

    const response = await dispatch(
      generateMaskingFromBackend({
        rowsExclusion: rowsExclusion ?? [],
        row: row ?? "",
        maxRow: maxRow ?? "",
      })
    );

    //if there is any error then we will print that error.
    if (response?.error) {
      dispatch(errorMessage(response?.error?.message));
    } else {
      const possibleRows = response?.payload?.possibleRows?.join(",");
      const oldData = cloneDeep(data);
      oldData[index] = {
        ...oldData[index],
        possibleMaskingRows: possibleRows,
        generateMasking: {
          sunday: response?.payload?.sunday,
          monday: response?.payload?.monday,
          tuesday: response?.payload?.tuesday,
          wednesday: response?.payload?.wednesday,
          thursday: response?.payload?.thursday,
          friday: response?.payload?.friday,
          saturday: response?.payload?.saturday,
        },
        maskinggenerated: true,
      };
      setData(oldData);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setData((prev) => {
      const updatedData = [...prev];
      updatedData[index] = {
        ...updatedData[index],
        [name]: value === "" ? "" : value, // Handle empty string and numeric input
        maskinggenerated: false, // Reset masking generated flag when any value changes
      };
      return updatedData;
    });
  };


  return (
    <div>
      <Table bordered className="w-100 my-2">
        <thead
          style={{
            backgroundColor: "#f5f5f5",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          <tr>
            <th>Section</th>
            <th>Row</th>
            <th>Maximum Row</th>
            <th>Row(s) Exclusion</th>
            <th>Possible Masking Rows</th>
            <th className="text-muted" style={{ width: "90px" }}>
              Days
            </th>
            <th className="text-muted" style={{ border: "none" }}>
              Masking Row
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "14px" }} className="w-100">
          {!data.length ? (
            <tr>
              <td colSpan={6} className="text-center">
                No Data
              </td>{" "}
            </tr>
          ) : (
            data.map((row, i) => {
              return (
                <>
                  <tr className="position-relative">
                    <td rowSpan={7} className="align-middle">
                      <Form.Control
                        readOnly
                        className="border-0 w-100 text-center bg-transparent"
                        style={{
                          width: "150px",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                        placeholder="Enter the row"
                        name="section"
                        onChange={(e) => {
                          handleChange(e, i);
                          if (e.target.value) {
                            setIsGenerate({ ...isgenerate, isrow: true });
                          } else {
                            setIsGenerate({ ...isgenerate, isrow: false });
                          }
                        }}
                        type="text"
                        value={row.section}
                      />
                    </td>
                    <td rowSpan={7} className="align-middle">
                      <Form.Control
                        readOnly
                        className="border-0 w-100 text-center bg-transparent"
                        style={{
                          width: "150px",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                        placeholder="Enter the row"
                        name="maskingRow"
                        onChange={(e) => {
                          handleChange(e, i);
                          if (e.target.value) {
                            setIsGenerate({ ...isgenerate, isrow: true });
                          } else {
                            setIsGenerate({ ...isgenerate, isrow: false });
                          }
                        }}
                        type="text"
                        value={row.maskingRow}
                      />
                    </td>
                    <td rowSpan={7} className="align-middle">
                      <Form.Control
                        readOnly={row?.maskinggenerated ? true : false}
                        className="border-0 w-100 text-center bg-transparent"
                        placeholder="Enter"
                        style={{
                          width: "150px",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                        name="maximumRow"
                        onChange={(e) => {
                          const regex = /^[A-Za-z0-9]*$/;

                          if (!regex.test(e.target.value)) {
                            return;
                          }
                          handleChange(e, i)

                        }}
                        type="text"
                        value={row.maximumRow}
                      />
                    </td>
                    <td rowSpan={7} className="align-middle">
                      <Form.Control
                        readOnly={row?.maskinggenerated ? true : false}
                        className="border-0 w-100 text-center bg-transparent"
                        placeholder="Enter"
                        style={{
                          width: "150px",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                        name="rowExclusion"
                        onChange={(e) => {
                          const regex = /^[A-Za-z0-9]*$/;

                          if (!regex.test(e.target.value)) {
                            return;
                          }
                          handleChange(e, i)
                        }}
                        type="text"
                        value={row.rowExclusion}
                      />
                    </td>
                    <td rowSpan={7} className="align-middle">
                      <Form.Control
                        readOnly={row?.maskinggenerated ? true : false}
                        className="border-0 w-100 text-center bg-transparent"
                        placeholder="No Suggestion"
                        style={{
                          width: "150px",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                        name="possibleMaskingRows"
                        onChange={(e) => handleChange(e, i)}
                        type="text"
                        value={row.possibleMaskingRows}
                      />
                    </td>

                    {row?.maskinggenerated ? (
                      <>
                        <td style={{ width: "170px" }}>Sunday</td>
                        <td> {row?.generateMasking?.sunday}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "170px",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          -
                        </td>
                      </>
                    )}

                    <td rowSpan={7} className="align-middle">
                      {row?.maskinggenerated && (
                        <MdRefresh
                          style={{ color: "#0241A0", cursor: "pointer" }}
                          onClick={() => {
                            generateMasking(row, i);
                          }}
                          size={20}
                          className="me-2"
                        />
                      )}
                      {!row?.maskinggenerated ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            generateMasking(row, i);
                          }}
                          disabled={row.maximumRow ? false : true}
                          className="btn btn-primary py-1"
                          style={{ height: "32px", fontSize: "14px" }}
                        >
                          Generate
                        </button>
                      ) : (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            removeMasking(row, i);
                          }}
                          className="btn btn-primary py-1"
                          style={{ fontSize: "14px" }}
                        >
                          No Masking
                        </button>
                      )}
                    </td>
                  </tr>
                  {row?.maskinggenerated ? (
                    <>
                      <tr>
                        <td>Monday</td>
                        <td>{row?.generateMasking?.monday}</td>
                      </tr>
                      <tr>
                        <td>Tuesday</td>
                        <td> {row?.generateMasking.tuesday}</td>
                      </tr>
                      <tr>
                        <td>Wednesday</td>
                        <td>{row?.generateMasking?.wednesday}</td>
                      </tr>
                      <tr>
                        <td>Thursday</td>
                        <td>{row?.generateMasking?.thursday}</td>
                      </tr>
                      <tr>
                        <td>Friday</td>
                        <td>{row?.generateMasking?.friday}</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>{row?.generateMasking?.saturday}</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                      <tr></tr>
                    </>
                  )}
                </>
              );
            })
          )}
        </tbody>
      </Table>

      {/* <button
        onClick={(e) => {
          e.preventDefault();
          handleNewRow();
          setIsGenerate({ ...isgenerate })
        }}
        className="btn btn-primary py-1"
        style={{ height: "32px", fontSize: "14px" }}
      >
        Add Masking for Another Row
      </button> */}
    </div>
  );
};

export default TableComponent;
