import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Container,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { todayDate } from "../../../utils/formatter/dateAndTime";

const AddEditPOCharges = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  preFilledData,
  details
}) => {
  const dispatch = useDispatch();
  const [dropVal, setdropVal] = useState({
    account: "",
    purchase: ""
  });
  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm();

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);

  // reset form on visibility toggle
  useEffect(() => {
    reset();
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setIsEdit(true);
      setdropVal(
        {
          account: { accountId: selectedRecord.accountId, name: selectedRecord.accountName },
          purchase: { purchaseOrderId: selectedRecord.purchaseOrderId }
        }
      );
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      // if selected record is undefined, reset
      setIsEdit(false);
      setdropVal({ account: "", purchase: "" });
      reset();

    }
  }, [isVisible]);

  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setdropVal(
      {
        account: { accountId: selectedRecord.accountId, name: selectedRecord.accountName },
        purchase: { purchaseOrderId: selectedRecord.purchaseOrderId }
      }
    );
  };

  const handleClear = () => {
    reset();
    setdropVal({ account: "", purchase: "" });

  };

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: (isFocused) ? "#329BEF" : "white",
      color: isFocused ? "white" : "black"
    }),
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title className="myAcc-title">ALL CHARGES</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/purchases",
                name: "Purchase Order",
                active: false,
              },
              {
                name: isEdit ? "Edit Charge" : "New Charge",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <Container className="p-2 w-100 h-100" fluid>
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Description
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.description}
                        readOnly={isEdit}
                        {...register("description", { required: true })}
                        placeholder="Description"
                      />
                      <Form.Control.Feedback type="invalid">
                        Description is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Date and Time
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="datetime-local"
                        readOnly={isEdit}
                        isInvalid={!!errors.dateAndTime}
                        {...register("dateAndTime", { required: true })}
                        defaultValue={todayDate()}
                        placeholder="yyyy-mm-ddThh-mm-ss"
                      />
                      <Form.Control.Feedback type="invalid">
                        Date and Time is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Source Type
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.sourceType}
                        readOnly
                        {...register("sourceType", { required: true })}
                        placeholder="Source Type"
                        value="MANUAL"
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Source Type is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Amount
                    </Form.Label>
                    <Col sm="7">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          readOnly={isEdit}
                          type="number"
                          step="0.01"
                          isInvalid={!!errors.amount}
                          {...register("amount", { required: true })}
                          placeholder="$0.00"
                        />
                        <Form.Control.Feedback type="invalid">
                          Amount is required.
                        </Form.Control.Feedback>
                      </InputGroup>

                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                {/* <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Source ID
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        readOnly
                        isInvalid={!!errors.sourceId}
                        {...register("sourceId")}
                        placeholder="2XB1N777"
                      />
                      <Form.Control.Feedback type="invalid">
                        Source ID is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col> */}
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Notes
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        {...register("notes")}
                        defaultValue="--"
                      />
                      <Form.Control.Feedback type="invalid">
                        Notes is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <hr />
              <Row className="mt-3">
                <p className="title-accounts">Vendor Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Vendor
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        readOnly
                        value={details?.vendorName}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Account Id
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control {...register("accountId")} readOnly placeholder={details?.accountId} />
                      {/* <Controller
                        name="accountId"
                        control={control}
                        rules={{ required: true, message: "error message" }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isInvalid={!!errors.accountId}

                                value={selectedRecord.accountId}
                                styles={customStyles}
                                options={accountsList}
                                getOptionLabel={option => `${option.accountId}  ${option.name}`}
                              />
                              {invalid && error ? <div className="custom-invalid-feedback">Account is required.</div> : ""}
                            </div>
                          );
                        }}
                      /> */}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>{" "}
            </Container>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={isEdit ? handleReset : handleClear}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditPOCharges;
