import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Modal, ModalTitle, Row } from "react-bootstrap";
import { withLoader } from "../../../utils/hoc/withLoader";
import "./historyModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { fetchPurchaseOrders } from "../../../data/reducers/purchaseOrders/cached-po.reducer";
import { getTransactionsForPaymentPlan, updateStatusPO } from "../../../data/reducers/purchaseOrders/po.reducer";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import { setNotePendingPO } from "../../../data/reducers/purchaseOrders/po.reducer";
import Loader from "../../../shared/components/loader/Loader";
import Reset from "../../../images/reset.svg";
import Search from "../../../images/search.svg";
import FilterPanel from "../../../shared/components/panels/filter-panel/FilterPanel";
import SelectTableHead from "../../../shared/components/table/parts/SelectTableHead";
import SelectRowCard from "../../../shared/components/table/SelectRowCard";
import { transactionCols } from "./transactionsCols";
import { set } from "lodash";


const ChargesForPaymentPlanModal = ({
    isVisible,
    handleClose,
    setArray,
    paymentPlanIndex,
    selectedPaymentPlan,
    array,
    unmatchedCharges,
    setUnmatchedCharges,
    setBusy
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectId, setselectId] = useState(null);
    const [searchStr, setSearchstr] = useState('');
    const { selectedAccountDetails } = useSelector((state) => state.accounts);
    const dispatch = useDispatch();

    // Get pendingPONote from Redux state
    const { pendingPONote, paymentPlanTransactions } = useSelector((state) => state.purchaseOrder);
    const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState([])

    useEffect(() => {
        const filteredTransactions = paymentPlanTransactions.filter(
            (transaction) =>
                !array.some((item) => item.chargesId === transaction.id) ||
                selectedPaymentPlan?.chargesId === transaction.id
        );

        setSelectedPurchaseOrders(filteredTransactions);
    }, [paymentPlanTransactions])
    console.log("array", array);
    console.log("trn", paymentPlanTransactions)
    useEffect(() => {
        selectedPurchaseOrders.map((value) => {
            if (value?.id == selectedPaymentPlan?.chargesId) {
                setselectId(value);
            }
        })
    }, [selectedPaymentPlan, selectedPurchaseOrders])

    const handleConfirmMatch = () => {
        setArray((prevArray) => {
            return prevArray.map((item, index) => {
                if (index === paymentPlanIndex) {
                    // Append the chargesId while keeping all other fields the same
                    return {
                        ...item,
                        amount: selectId?.amount,
                        chargesId: selectId?.id ?? null,
                    };
                }
                return item; // Leave other items unchanged
            });
        });

        // Optionally reset selectId after confirmation
        setselectId(null);

        // Close the modal
        handleClose();
    }

    const handleUnmatch = () => {
        setArray((prevArray) => {
            return prevArray.map((item, index) => {
                if (index === paymentPlanIndex) {
                    return {
                        ...item,
                        amount: 0, // Optionally reset amount
                        chargesId: null, // Set chargesId to null
                    };
                }
                return item;
            });
        });
        if (selectId) {
            setUnmatchedCharges((prevUnmatch) => [...prevUnmatch, selectId.id]);
        }

        setselectId(null);
        handleClose();
    };

    const handleSearch = async () => {
        setBusy(true)
        await dispatch(
            getTransactionsForPaymentPlan({
                accountId: selectedAccountDetails?.accountId,
                vendorId: selectedAccountDetails?.vendorId,
                chargeId: [...unmatchedCharges, selectedPaymentPlan.chargesId ?? ''],
                paymentPlanId: selectedPaymentPlan?.id ?? '',
                date: selectedPaymentPlan?.date,
                searchStr: searchStr
            })
        );
        setBusy(false)

    }

    const handleclear = async () => {
        setBusy(true)
        await dispatch(
            getTransactionsForPaymentPlan({
                accountId: selectedAccountDetails?.accountId,
                vendorId: selectedAccountDetails?.vendorId,
                chargeId: [...unmatchedCharges, selectedPaymentPlan.chargesId ?? ''],
                paymentPlanId: selectedPaymentPlan?.id ?? '',
                date: selectedPaymentPlan?.date,
                searchStr: ''
            })
        );
        setSearchstr('')
        setBusy(false)
    }

    return (
        <>
            <Modal
                dialogClassName="modal-xl"
                className="historyModal"
                show={isVisible}
                onHide={handleClose}
            >
                <Form onSubmit={(e) => e.preventDefault()} >
                    <Modal.Header closeButton>
                        <Modal.Title>Match Amount</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="upperCon">
                            <Row>

                                <div className="d-flex gap-2">
                                    <Form.Group className="flex-grow-1 ">
                                        <InputGroup>
                                            <InputGroup.Text className="search-prepend " id="basic-addon1">
                                                <img className="search-svg" src={Search} alt="" />
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder="Search for Card Account, Vendor, Date, Amount..."
                                                className="search-control "
                                                onChange={(e) => setSearchstr(e.target.value)}
                                                value={searchStr}

                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        className="filter-panel-button ss-light-button"
                                        onClick={handleSearch}
                                    >
                                        {/* <img className="filter-search-svg" src={Search} alt="" /> */}
                                        <span>Search</span>
                                    </Button>

                                    <Button
                                        variant="primary"
                                        className="filter-panel-button ss-light-button"
                                        onClick={handleclear}
                                    >
                                        <img className="filter-reset-svg" src={Reset} alt="" />
                                        <span>Clear</span>
                                    </Button>
                                </div>

                                <Col sm={4} />
                            </Row>
                        </Container>

                        <div className="tableCover">
                            <div className="d-flex justify-content-start my-2">
                                <b>SELECT MATCH</b>
                            </div>
                            <SelectTableHead columns={transactionCols(setselectId)} />

                            <Container className="tableBody">
                                {selectedPurchaseOrders.length > 0 ? (
                                    selectedPurchaseOrders.map((data, index) => (
                                        <SelectRowCard
                                            key={index}
                                            columns={transactionCols(setselectId, selectId)}
                                            rowData={data}
                                            isSelected={
                                                // selectedPaymentPlan?.chargesId === data?.id
                                                // ||
                                                selectedPurchaseOrders.indexOf(selectId) === selectedPurchaseOrders.indexOf(data)
                                            }
                                        />
                                    ))
                                ) : (
                                    <Row>
                                        <Col>No Payment Plan found.</Col>
                                    </Row>
                                )}
                            </Container>
                        </div>

                        <div className="w-full p-4 bg-white">
                            <div className="text-end mt-3 d-flex justify-content-end">
                                <Button
                                    variant="primary"
                                    className="ss-modal-primary-btn mx-2"
                                    style={{
                                        backgroundColor: "#0241a0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={handleConfirmMatch}
                                    disabled={selectId ? false : true}
                                >
                                    {isLoading ? <Loader /> : "Confirm Match"}
                                </Button>
                                <Button
                                    variant="primary"
                                    className="ss-modal-primary-btn mx-2"
                                    style={{
                                        backgroundColor: "#0241a0",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={handleUnmatch}
                                    disabled={selectedPaymentPlan?.chargesId != null ? selectId?.id != selectedPaymentPlan?.chargesId : true}
                                >
                                    {isLoading ? <Loader /> : "Unmatch"}
                                </Button>
                                <Button
                                    variant="light"
                                    className="ss-modal-secondary-btn mx-2"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal >
        </>
    );
};

export default withLoader(ChargesForPaymentPlanModal);
