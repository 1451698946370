import React, { useEffect, useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import right from "../../../../../../images/right.svg"
import warninginfo from "../../../../../../images/warninginfo.svg"


export default function WarningMessage({
  selectedAccount,
  quicktixMatch,
  mondayMatch,
  selectedVendor,
}) {
  const [bgColor, setBgColor] = useState("#ECF5FF");
  const [bgColornotuploaded, setBgColorNotUploaded] = useState("#ECF5FF");
  const [iconnotuploaded, setIconNotUploaded] = useState("blue");
  const [icon, setIcon] = useState("blue");

  useEffect(() => {
    if (
      mondayMatch.nameMatch == true &&
      mondayMatch.emailMatch == true &&
      quicktixMatch.nameMatch == true &&
      quicktixMatch.emailMatch == true
    ) {
      setBgColor("#E0FFDD");
      setIcon(right);
      // for green icon
    }
    if (
      (mondayMatch.nameMatch == true &&
        mondayMatch.emailMatch == false &&
        quicktixMatch.nameMatch == true &&
        quicktixMatch.emailMatch == true) ||
      (mondayMatch.nameMatch == false &&
        mondayMatch.emailMatch == false &&
        quicktixMatch.nameMatch == false &&
        quicktixMatch.emailMatch == false)
    ) {
      setBgColor("#FCF6D7");
      setIcon(warninginfo);
      // for yellow icons.
    }
  }, [mondayMatch, quicktixMatch]);

  useEffect(() => {
    if (selectedAccount?.data.monday && selectedAccount?.data.quicktix) {
      setBgColorNotUploaded("#E0FFDD")
      setIconNotUploaded(right)
    }
  }, [selectedAccount])

  const { UploadInvoice } = useSelector((state) => state.purchaseOrder);
  return (
    <div>
      {!UploadInvoice && selectedAccount ? (
        <div
          className="d-flex align-items-center p-4 rounded"
          style={{ backgroundColor: bgColornotuploaded }} // #E0FFDD --for ok , #FCF6D7 --for warning, #ECF5FF -- for info
        >
          <span className="mx-2"></span>
          {iconnotuploaded == "blue" && (
            <IoInformationCircleOutline color="#0241A0" size={20} className="me-2" />
          )}
          <span style={{ color: "#404040", fontSize: "12px" }}>
            {selectedAccount?.data.monday && !selectedAccount?.data.quicktix ? (
              <span>
                {" "}
                The account name and email exist in Monday CRM,
                but don't exist in Quicktix. Clicking 'next' will add this
                details to Quicktix.
              </span>
            ) : selectedAccount?.data.quicktix && !selectedAccount?.data.monday ? (
              <span>
                The account name and email do not exist in Monday CRM. Please
                ensure to add these details to Monday CRM.
              </span>
            ) : selectedAccount?.data.monday &&
              selectedAccount?.data.quicktix ? (
              <span>
                The email and account name are available in both Monday CRM and
                QuickTix.
              </span>
            ) : null}
          </span>
        </div>
        // ) : 
        // selectedVendor || selectedAccount?.data.vendorName ? (
        //   <div
        //     className="d-flex align-items-center p-4 rounded"
        //     style={{ backgroundColor: bgColor }} // #E0FFDD --for ok , #FCF6D7 --for warning, #ECF5FF -- for info
        //   >
        //     <span className="mx-2">
        //       <IoInformationCircleOutline color="#0241A0" size={20} />
        //     </span>
        //     <span style={{ color: "#404040", fontSize: "12px" }}>
        //       {mondayMatch.nameMatch == true &&
        //         mondayMatch.emailMatch == true &&
        //         quicktixMatch.nameMatch == true &&
        //         quicktixMatch.emailMatch == true ? (
        //         <span>
        //           {" "}
        //           The account name and email from Monday CRM and QuickTix match
        //           the invoice.
        //         </span>
        //       ) : mondayMatch.nameMatch == true &&
        //         mondayMatch.emailMatch == true &&
        //         quicktixMatch.nameMatch == false &&
        //         quicktixMatch.emailMatch == false ? (
        //         <span>
        //           The account name and email from Monday CRM match the invoice but
        //           don't exist in QuickTix. Clicking 'Next' will add these details
        //           to QuickTix.
        //         </span>
        //       ) : mondayMatch.nameMatch == true &&
        //         mondayMatch.emailMatch == false &&
        //         quicktixMatch.nameMatch == true &&
        //         quicktixMatch.emailMatch == true ? (
        //         <span>
        //           The email from Monday CRM does not match the invoice. Please
        //           verify the email or correct it manually. Clicking 'Next' will
        //           add these details to QuickTix.
        //         </span>
        //       ) : mondayMatch.nameMatch == false &&
        //         mondayMatch.emailMatch == false &&
        //         quicktixMatch.nameMatch == false &&
        //         quicktixMatch.emailMatch == false ? (
        //         <span>
        //           The email and account name are not available in Monday CRM or
        //           QuickTix. Please verify the account name and email, or enter
        //           them manually. Clicking 'Next' will add these details to
        //           QuickTix.
        //         </span>
        //       ) : mondayMatch.nameMatch == false &&
        //         mondayMatch.emailMatch == false &&
        //         quicktixMatch.nameMatch == true &&
        //         quicktixMatch.emailMatch == true ? (
        //         <span>
        //           The email and account name from QuickTix match the invoice but
        //           do not exist in Monday CRM. Please verify the details or add
        //           them to Monday CRM.
        //         </span>
        //       ) : null}
        //     </span>
        //   </div>
        // ) : (
        //   ""
      )
        :
        null
      }
    </div>
  );
}