/* eslint-disable no-mixed-spaces-and-tabs */
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// svg icons
import Vendor from "../../../images/Vendor1.svg";
import Menu from "../../../images/menu.svg";
import Cross from "../../../images/cross.svg";
import Account from "../../../images/account.svg";
import Invoice from "../../../images/invoice.svg";
import Purchase from "../../../images/purchase.svg";
import Banking from "../../../images/Banking.svg";
import Address from "../../../images/Address.svg";
import Fund from "../../../images/Fund.svg";
import FundRight from "../../../images/fund_down.svg";
import FundDown from "../../../images/fund_right.svg";
import Paypal from "../../../images/paypal.svg";
import Plaid from "../../../images/plaid.svg";
import BIReport from "../../../images/bi-report.svg";
import Finance2 from "../../../images/Finance2.svg";
import Accounts from "../../../images/Accounts.svg";
import PO from "../../../images/PO.svg";
import Cards from "../../../images/Card.svg";
import Skybox from "../../../images/SKYBOX.svg";
import Transactions from "../../../images/Transactions.svg";
import Season from "../../../images/Season.svg";
import Settings from "../../../images/settings.svg";
import SportsTeams from "../../../images/sports&team.svg";
import Sync from "../../../images/sync.svg";
import RevealMarket from "../../../images/revealMarket.svg";
import Inventory from "../../../images/inventory.svg";
import Events from "../../../images/events.svg";
import stacked_email from "../../../images/stacked_email.svg";
import Email from "../../../images/email.svg";
import Upload from "../../../images/upload1.svg";
import Calendar from "../../../images/calendar.svg";
import { setSelectedPurchaseOrders } from "../../../data/reducers/purchaseOrders/po.reducer";

const fundSources = [
  {
    imageSource: Cards,
    menuName: "Cards",
    to: "/fundSources/cards?page=1",
    role: ["Admin", "User"],
  },
  {
    imageSource: Paypal,
    menuName: "PayPal",
    to: "/fundSources/paypal?page=1",
    role: ["Admin", "User"],
  },
  {
    imageSource: Plaid,
    menuName: "Plaid Accounts",
    to: "/fundSources/plaid-accounts?page=1",
    role: ["Admin", "User"],
  },
];

const orderDetails = [
  {
    imageSource: PO,
    menuName: "Purchase Orders",
    to: "/purchases?page=1",
    role: ["Admin", "User"],
  },
  {
    imageSource: PO,
    menuName: "Pending POs",
    to: "/pendingPO",
    role: ["Admin", "User"],
  },
  {
    imageSource: Skybox,
    menuName: "SkyBox Purchases",
    to: "/skybox-purchases?page=1",
    role: ["Admin", "User"],
  },
];

const financeDetails = [
  {
    imageSource: Finance2,
    menuName: "Finance",
    to: "/finance?page=1",
    lisitem: false,
    role: ["Admin", "User"],
  },
  {
    imageSource: Invoice,
    menuName: "PSL",
    to: "/psl?page=1",
    lisitem: false,
    role: ["Admin", "User"],
  },
];

const settingsDetails = [
  {
    imageSource: SportsTeams,
    menuName: "Sports & Teams",
    to: "/settings/sports-and-teams",
    lisitem: false,
    role: ["Admin", "User"],
  },
  {
    imageSource: RevealMarket,
    menuName: "Reveal Market",
    to: "/settings/reveal-market?page=1",
    lisitem: false,
    role: ["Admin", "User"],
  },
  {
    imageSource: Sync,
    menuName: "Sync",
    to: "/settings/sync?page=1",
    lisitem: false,
    role: ["Admin", "User"],
  },
];

const emailDetails = [
  {
    imageSource: Email,
    menuName: "Email",
    to: "/email?page=1",
    lisitem: false,
    role: ["Admin", "User"],
  },
  {
    imageSource: Upload,
    menuName: "Upload",
    to: "/upload/email?page=1",
    lisitem: false,
    role: ["Admin", "User"],
  },
];

const menu = [
  {
    id: 1,
    imageSource: Accounts,
    menuName: "Accounts",
    to: "/accounts",
    lisitem: false,
    role: ["Admin", "User"],
  },
  {
    id: 2,
    imageSource: Address,
    menuName: "Address Book",
    to: "/addressbook?page=1",
    role: ["Admin", "User"],
  },
  {
    id: 3,
    imageSource: Vendor,
    menuName: "Vendors",
    to: "/vendors",
    role: ["Admin", "User"],
  },
  {
    id: 4,
    imageSource: Season,
    menuName: "Season",
    to: "/season?page=1",
    role: ["Admin", "User"],
  },
  {
    id: 5,
    imageSource: Purchase,
    menuName: "Orders",
    role: ["Admin", "User"],
  },
  {
    id: 6,
    imageSource: Inventory,
    menuName: "Sales",
    to: "/inventory?page=1",
    role: ["TicketManager", "Admin"],
  },
  {
    id: 7,
    imageSource: Events,
    menuName: "Events",
    to: "/events?page=1",
    role: ["TicketManager", "Admin"],
  },
  {
    id: 8,
    imageSource: Transactions,
    menuName: "Transactions",
    to: "/transactions?page=1",
    role: ["Admin", "User"],
  },
  {
    id: 9,
    imageSource: Banking,
    menuName: "Finance",
    role: ["Admin", "User"],
  },
  {
    id: 10,
    imageSource: Fund,
    menuName: "Fund Sources",
    role: ["Admin", "User"],
  },
  {
    id: 11,
    imageSource: Settings,
    menuName: "Settings",
    role: ["Admin", "User"],
  },
  {
    id: 12,
    imageSource: BIReport,
    menuName: "BI Report",
    to: "/bi-report",
    lisitem: false,
    role: ["Admin", "User"],
  },
  {
    id: 13,
    imageSource: stacked_email,
    menuName: "Emails",
    role: ["Admin", "User"],
  },
  // {
  //   id: 13,
  //   imageSource: Email,
  //   menuName: "Email",
  //   to: "/email?page=1",
  //   lisitem: false,
  //   role: ["Admin", "User"],
  // },
  {
    id: 14,
    imageSource: Calendar,
    menuName: "Payment Calendar",
    role: ["Admin", "User"],
    to: "/payment-calendar",
    listitem: false,
  },
];

const MenuBar = () => {
  const [isExpanded, setExpanded] = useState(true);
  const [accOpen, setAccOpen] = useState(false);
  const authInfo = useSelector((state) => state.auth);

  return (
    <>
      <div
        className={`side-menu d-flex flex-column ${
          isExpanded ? "expanded" : ""
        }`}
      >
        <div
          className="open-close text-center py-3"
          onClick={() => setExpanded(!isExpanded)}
        >
          <img src={isExpanded ? Cross : Menu} alt="" />
        </div>
      </div>
      {menu.map((m, i) => {
        if (!m.role.some((x) => x === authInfo.role)) {
          return null;
        }
        return (
          <div key={m.menuName}>
            {m.id - 1 === 4 ||
            m.id - 1 === 8 ||
            m.id - 1 == 9 ||
            m.id - 1 == 10 ||
            m.id - 1 == 12 ? (
              <Collapse
                setOpen={setAccOpen}
                open={accOpen}
                m={m}
                authInfo={authInfo}
                i={m.id - 1}
                isExpanded={isExpanded}
              />
            ) : (
              <>
                {m.id - 1 == 10 && authInfo.role == "User" ? (
                  ""
                ) : (
                  <SingleMenuItem
                    key={m.id}
                    isExpanded={isExpanded}
                    imageSource={m.imageSource}
                    menuName={m.menuName}
                    listitem={false}
                    to={m.to}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

const SingleMenuItem = ({ isExpanded, imageSource, menuName, lisitem, to }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setSelectedPurchaseOrders([]));
  };
  return (
    <NavLink
      className={
        lisitem
          ? "menu-item px-4 py-3 my-2 d-flex align-items-right"
          : "menu-item px-3 py-3 my-2 d-flex align-items-center"
      }
      activeClassName="active"
      to={to}
      onClick={handleClick}
    >
      <div className={lisitem ? "ps-3" : ""}>
        <img src={imageSource} style={{ width: "24px", height: "24px" }} />
      </div>
      <div className={`nav-link-tab ps-3 ${isExpanded ? "" : "d-none"}`}>
        {menuName}
      </div>
    </NavLink>
  );
};

const Collapse = ({ isExpanded, open, setOpen, m, i, authInfo }) => {
  let details;
  // if (i === 0) details = accDetails
  if (i === 4) details = orderDetails;
  else if (i === 8 && authInfo.role == "User") details = [financeDetails[1]];
  else if (i === 8 && authInfo.role == "Admin") details = financeDetails;
  else if (i === 9) details = fundSources;
  else if (i === 10) details = settingsDetails;
  else if (i === 12) details = emailDetails;

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        className="px-3 py-3 my-2 d-flex"
        onClick={() => (open === i ? setOpen("") : setOpen(i))}
      >
        <div>
          <img src={m.imageSource} style={{ width: "24px", height: "24px" }} />
        </div>
        <div
          style={{ width: "100%" }}
          className={`nav-link-tab px-3 ps-3 ${isExpanded ? "" : "d-none"}`}
        >
          {m.menuName}
        </div>
        &nbsp;&nbsp;
        <div className="">
          <img src={open === i ? FundDown : FundRight} />
        </div>
      </div>

      {open === i &&
        details &&
        details.map((m, i) => (
          <SingleMenuItem
            key={i}
            isExpanded={isExpanded}
            imageSource={m.imageSource}
            menuName={m.menuName}
            fundOpen={open}
            lisitem={true}
            to={m.to}
          />
        ))}
    </>
  );
};

export default MenuBar;
