import { MdOutlineModeEdit } from "react-icons/md";
import { Form, InputGroup, Table } from "react-bootstrap";
import TableComponent from "../../../../TableComponent";
import Header from "../../../../components/Header";
import KeyValueLabel from "../../../../components/KeyValueLabel";
import { useEffect, useState } from "react";
import { formatMoney } from "../../../../../../../utils/formatter/currencyFormatter";
import { useSelector } from "react-redux";

const AdditionalDetailsPreview = ({
  custom_styles,
  setSteps,
  data,
  purchaseOrderData,
  typeName,
}) => {
  const [listSkyBoxRefs, setListSkyBoxRefs] = useState([]);
  const { globalInvoiceType } = useSelector((state) => state.purchaseOrder);

  useEffect(() => {
    //we are setting the list of sky box ref in this useEffect for its initial value only.
    if (
      (!listSkyBoxRefs || listSkyBoxRefs?.length == 0) &&
      purchaseOrderData?.additionalDetails?.listSkyBoxRefs?.length > 0
    ) {
      setListSkyBoxRefs(
        purchaseOrderData?.additionalDetails?.listSkyBoxRefs ?? []
      );
    }
  }, [purchaseOrderData?.additionalDetails]);

  return (
    <>
      <div className="p-4 pb-0 d-flex flex-column gap-4">
        <Header
          title="Additional Details"
          subTitle="Please provide any additional information related to yourpurchase."
          onEdit={() => setSteps(4)}
        />

        {globalInvoiceType != 5 ? (
          <>
            {/* Inventory Tag*/}
            <div className="form-group w-100 d-flex flex-column gap-1 ">
              <span className="small  text-muted" style={{ fontSize: "14px" }}>
                Inventory Tag
              </span>
              <Table bordered hover>
                <thead style={{ backgroundColor: "#f5f5f5" }}>
                  <tr
                    style={{ ...custom_styles.details }}
                    className="text-center"
                  >
                    <th>Sport</th>
                    <th>Season</th>
                    <th>Type</th>
                    <th>Additional Tags</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{ ...custom_styles.font_label }}
                    className="text-center"
                  >
                    <td> {data?.ticketDetails?.sport?.label}</td>
                    <td>{data?.ticketDetails?.season?.label}</td>
                    <td>
                      {purchaseOrderData?.InvoicePO?.data?.subInvoiceType}
                    </td>
                    <td>
                      {purchaseOrderData?.additionalDetails?.inventory?.additionalTags
                        ?.map((item) => item.value)
                        ?.join(",")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div style={{ ...custom_styles.border2, margin: "0" }}></div>

            {/* Internal Notes and Attendace Breakdown */}
            <div className="d-flex justify-content-between">
              <KeyValueLabel
                label={"Internal Notes"}
                value={purchaseOrderData?.additionalDetails?.inventory?.internalNotes?.join(
                  ","
                )}
              />
              <KeyValueLabel
                label={"Attendance Breakdown"}
                value={
                  purchaseOrderData?.additionalDetails?.inventory
                    ?.attendanceBreakdown
                }
              />
            </div>

            <div style={{ ...custom_styles.border2, margin: "0" }}></div>
          </>
        ) : null}

        {/* Masking Table */}
        {globalInvoiceType != 5 && globalInvoiceType != 6 ? (
          <div className="d-flex flex-column">
            <span className="" style={{ color: "#A7A7A7", fontSize: "14px" }}>
              Masking Instructions (Optional)
            </span>
            {/* <TableComponent
            isbutton={false}
            data={purchaseOrderData?.additionalDetails?.masking ?? []}
            setData={false}
          /> */}

            <Table bordered className="w-100 my-2">
              <thead
                style={{
                  backgroundColor: "#f5f5f5",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th>Section</th>
                  <th>Row</th>
                  <th>Maximum Row</th>
                  <th>Row Exclusion</th>
                  <th>Possible Masking Rows</th>
                  <th className="text-muted" style={{ width: "90px" }}>
                    Days
                  </th>
                  <th className="text-muted" style={{ border: "none" }}>
                    Masking Row
                  </th>
                </tr>
              </thead>
              <tbody
                style={{ fontSize: "14px", fontWeight: "bold" }}
                className="w-100"
              >
                {!purchaseOrderData.additionalDetails.masking?.length ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data
                    </td>{" "}
                  </tr>
                ) : (
                  purchaseOrderData.additionalDetails.masking.map((row, i) => {
                    return (
                      <>
                        <tr className="position-relative">
                          <td rowSpan={7} className="align-middle">
                            <Form.Control
                              readOnly
                              className="border-0 w-100 text-center bg-transparent"
                              style={{
                                width: "150px",
                                boxShadow: "none",
                                fontSize: "14px",
                              }}
                              placeholder="Enter the row"
                              name="section"
                              value={row.section}
                            />
                          </td>
                          <td rowSpan={7} className="align-middle">
                            <Form.Control
                              readOnly
                              className="border-0 w-100 text-center bg-transparent"
                              style={{
                                width: "150px",
                                boxShadow: "none",
                                fontSize: "14px",
                              }}
                              placeholder="Enter the row"
                              name="maskingRow"
                              value={row.maskingRow ?? "-"}
                            />
                          </td>
                          <td rowSpan={7} className="align-middle">
                            <Form.Control
                              readOnly
                              className="border-0 w-100 text-center bg-transparent"
                              style={{
                                width: "150px",
                                boxShadow: "none",
                                fontSize: "14px",
                              }}
                              name="maximumRow"
                              value={row.maximumRow ?? "-"}
                            />
                          </td>
                          <td rowSpan={7} className="align-middle">
                            <Form.Control
                              readOnly
                              className="border-0 w-100 text-center bg-transparent"
                              style={{
                                width: "150px",
                                boxShadow: "none",
                                fontSize: "14px",
                              }}
                              name="rowExclusion"
                              value={row.rowExclusion ?? "-"}
                            />
                          </td>
                          <td rowSpan={7} className="align-middle">
                            <Form.Control
                              readOnly
                              className="border-0 w-100 text-center bg-transparent"
                              style={{
                                width: "150px",
                                boxShadow: "none",
                                fontSize: "14px",
                              }}
                              name="possibleMaskingRows"
                              value={row.possibleMaskingRows ?? "-"}
                            />
                          </td>

                          {row?.maskinggenerated ? (
                            <>
                              <td style={{ width: "170px" }}>Sunday</td>
                              <td> {row?.generateMasking?.sunday}</td>
                            </>
                          ) : (
                            <>
                              <td
                                style={{
                                  width: "170px",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                -
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                No Masking
                              </td>
                            </>
                          )}
                        </tr>
                        {row?.maskinggenerated ? (
                          <>
                            <tr>
                              <td>Monday</td>
                              <td>{row?.generateMasking?.monday}</td>
                            </tr>
                            <tr>
                              <td>Tuesday</td>
                              <td> {row?.generateMasking.tuesday}</td>
                            </tr>
                            <tr>
                              <td>Wednesday</td>
                              <td>{row?.generateMasking?.wednesday}</td>
                            </tr>
                            <tr>
                              <td>Thursday</td>
                              <td>{row?.generateMasking?.thursday}</td>
                            </tr>
                            <tr>
                              <td>Friday</td>
                              <td>{row?.generateMasking?.friday}</td>
                            </tr>
                            <tr>
                              <td>Saturday</td>
                              <td>{row?.generateMasking?.saturday}</td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                            <tr></tr>
                          </>
                        )}
                      </>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        ) : null}

        <div style={{ ...custom_styles.border2, margin: "0" }}></div>

        {/* Skybox ref */}
        <div className="text-small ">
          <span
            className="small text-muted"
            style={{ ...custom_styles.titles, fontSize: "0.9rem" }}
          >
            SkyBox Ref (Optional)
          </span>

          {listSkyBoxRefs?.length > 0 && (
            <>
              <Table bordered className="my-3" style={{ fontSize: "0.9rem" }}>
                <thead style={{ backgroundColor: "#f5f5f5" }}>
                  <tr className="text-center">
                    <th style={{ width: "40%" }}>SkyBox Ref</th>
                    <th style={{ width: "25%" }}>SkyBox value</th>
                    <th style={{ width: "25%" }}>
                      Allotted Value (Allottable)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listSkyBoxRefs?.map((refs) => (
                    <tr
                      style={{ ...custom_styles.font_label }}
                      className="text-center"
                    >
                      <td
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 4,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {listSkyBoxRefs?.find((x) => x.id == refs.id)?.value
                          ?.id || ""}
                      </td>

                      <td
                        style={{
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        {formatMoney(refs?.value?.total || 0)}
                      </td>
                      <td
                        style={{
                          verticalAlign: "middle",
                          display: "flex",
                          justifyContent: "center",
                          padding: "0",
                        }}
                      >
                        <Form.Group className="d-flex align-items-center justify-content-center">
                          <InputGroup className="text-center">
                            ${refs.value.allocatableAmount ?? 0} (
                            {refs.value.staticAllocatableAmount ?? 0})
                            {/* <span className="d-flex align-items-center mx-1"> ( $ {getMax(refs)})</span> */}
                          </InputGroup>
                        </Form.Group>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>

        <div style={{ ...custom_styles.border2, margin: " 0" }}></div>

        <div className="d-flex flex-column gap-1">
          <span
            className="small text-muted "
            style={{ ...custom_styles.titles, fontSize: "0.9rem" }}
          >
            Add these PO details to Google Sheets and/or Monday CRM
          </span>
          <div className="d-flex">
            <Form.Group>
              <Form.Check
                className="remember-forgotpass me-4"
                type="checkbox"
                checked={
                  purchaseOrderData?.additionalDetails?.inventory?.isGoogleSheet
                }
                label={
                  <label style={{ ...custom_styles.font_label }}>
                    Google Sheet
                  </label>
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                className="remember-forgotpass"
                type="checkbox"
                checked={
                  purchaseOrderData?.additionalDetails?.inventory?.isMondayCRM
                }
                label={
                  <label style={{ ...custom_styles.font_label }}>
                    Monday CRM
                  </label>
                }
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalDetailsPreview;
