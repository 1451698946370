import { MdOutlineModeEdit } from "react-icons/md";
import Header from "../../../../components/Header";
import KeyValueLabel from "../../../../components/KeyValueLabel";
import { formatPhoneNumber } from "../../../../../../../utils/formatter/currencyFormatter";

const AccountDetailsPreview = ({ custom_styles, setSteps, data }) => {
  return (
    <>
      <div className="p-4 d-flex flex-column gap-4">
        {/* Header */}

        <Header
          title="Account Details"
          subTitle="Select an account to retrieve details from Monday CRM and QuickTix, or enter account details manually."
          onEdit={() => setSteps(1)}
        />

        {/* Account */}
        <div className="form-group d-flex align-items-center w-50">
          <label
            className="form-label col-form-label "
            style={{ ...custom_styles.font_label }}
          >
            Account
          </label>
          <div className="d-flex flex-column ">
            <label className="ms-4" style={{ ...custom_styles.details }}>
              {data?.selectedAccountDetails?.name}
            </label>
            <span className="text-muted small ms-4">
              {data?.selectedAccountDetails?.email}
            </span>
          </div>
        </div>

        <div style={{ ...custom_styles.border2 }}></div>

        <div className="d-flex flex-column gap-4">
          {/* Name | Account ID*/}
          <div className="d-flex justify-content-between">
            <KeyValueLabel
              label={"Name"}
              value={data?.selectedAccountDetails?.name}
            />
            <KeyValueLabel
              label={"Account ID"}
              value={data?.selectedAccountDetails?.accountId}
            />
          </div>
          {/* SS EMail | SS Phone | */}
          <div className="d-flex justify-content-between">
            <KeyValueLabel
              label={"SS Email"}
              value={data?.selectedAccountDetails?.email}
            />
            <KeyValueLabel
              label={"SS Phone"}
              value={formatPhoneNumber(data?.selectedAccountDetails?.mobileNo)}
            />
          </div>
          {/* Vendor */}
          <div className="d-flex justify-content-between">
            <KeyValueLabel
              label={"Vendor"}
              value={data?.selectedAccountDetails?.vendorName}
            />
          </div>
          {/* Address Details */}

          <div className="d-flex justify-content-between">
            <KeyValueLabel
              label={"Address"}
              value={data?.selectedAccountDetails?.address}
              fullWidth
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetailsPreview;
