import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import FooterPart from "../../shared/components/table/parts/FooterPart";
import BasicCard from "./BasicCard";
import Grid from "@mui/material/Grid";

function Row(props) {
  const {
    ind,
    row,
    open,
    setOpen,
    data,
    selectedTickets,
    setSelectedTickets,
    isReset,
  } = props;

  const customStyles = {
    flexGrow: 1,
    flexBasis: 0,
    textAlign: "center",
    wordWrap: "break-word",
  };

  return (
    <>
      <div
        className="table-row-card my-3 d-flex justify-content-between px-3 align-items-center"
        style={{
          minHeight: "64px",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
        }}
      >
        <small style={customStyles} className="mx-2">
          {row.email}
        </small>
        <small style={customStyles} className="mx-2">
          Section - {row.section}
        </small>
        <small style={customStyles} className="mx-2">
          Row - {row.row}
        </small>
        <small style={customStyles} className="mx-2" align="center">
          {row.quantity} {row.quantity > 1 ? "Seats" : "Seat"}
        </small>
        <small style={customStyles} className="mx-2" align="center">
          Select Seats
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (open[ind]) {
                setOpen(Array(data.length).fill(false));
              } else {
                const temp = Array(data.length).fill(false);
                temp[ind] = !temp[ind];
                setOpen(temp);
              }
              setSelectedTickets({
                open: null,
                section: null,
                row: null,
                tickets: [],
                ticketsId: [],
              });
            }}
          >
            <img src={open[ind] ? Up : Down} alt="up down image" />
          </IconButton>
        </small>
      </div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          textAlign: "center",
          backgroundColor: "#FBFBFB",
          overflow: "hidden",
        }}
        className="mx-2"
      >
        <Collapse in={open[ind]} timeout="auto" unmountOnExit>
          {row.quantity > 0 ? (
            <Box
              sx={{
                flexGrow: 1,
                "& > :not(style)": {
                  ml: 2,
                  mb: 2,
                },
              }}
            >
              <Grid container spacing={1.5} columns={{ md: 16 }}>
                {row?.masterSeats?.map((rec,i) => (
                  <Grid item xs={2} sm={4} md={4} key={i}>
                    <BasicCard
                      number={rec.seat}
                      seatId={rec.seatId}
                      isAvailable={rec.status === "Available" ? true : false}
                      rec={rec}
                      selectedTickets={selectedTickets}
                      setSelectedTickets={setSelectedTickets}
                      row={row}
                      openInd={ind}
                      isReset={isReset}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <h5 className="p-5 text-center">No records to display</h5>
          )}
        </Collapse>
      </div>
    </>
  );
}

export default function CollapsibleTable({
  paging,
  data,
  setpageNumber,
  setSortField,
  sortDir,
  setSortDir,
  sortField,
  selectedTickets,
  setSelectedTickets,
  isReset,
}) {
  const [open, setOpen] = React.useState([]);

  useEffect(() => {
    const temp = Array(data.length).fill(false);
    temp[0] = true;
    setOpen(temp);
  }, []);

  useEffect(() => {
    const temp = Array(data.length).fill(false);
    temp[0] = true;
    setOpen(temp);
  }, [isReset]);

  useEffect(() => {
    if (selectedTickets.open !== null) {
      const temp = Array(data.length).fill(false);
      temp[selectedTickets.open] = !temp[selectedTickets.open];
      setOpen(temp);
    }
  }, [selectedTickets]);

  return (
    <div className="quicktix-table">
      <TableContainer
        sx={{
          height: data?.length == 0 ? 130 : 300,
          maxWidth: 1093,
        }}
      >
        <div className="table-body">
          {data?.length > 0 ? (
            data.map((row, ind) => (
              <Row
                key={ind}
                ind={ind}
                row={row}
                open={open}
                setOpen={setOpen}
                data={data}
                selectedTickets={selectedTickets}
                setSelectedTickets={setSelectedTickets}
                isReset={isReset}
              />
            ))
          ) : (
            <h5 className="p-5 text-center">No records to display</h5>
          )}
        </div>
      </TableContainer>

      {paging && (
        <FooterPart
          paging={paging}
          setpageNumber={setpageNumber}
          setSortDir={setSortDir}
          sortDir={sortDir}
          sortField={sortField}
          setSortField={setSortField}
        />
      )}
    </div>
  );
}
