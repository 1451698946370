/* eslint-disable no-mixed-spaces-and-tabs */
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { inputDate } from "../../../utils/formatter/dateFormatter";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";

//dropdown data
import {
  getAccountTeamList,
  getAccountSportList,
} from "../../../data/reducers/accounts/accounts.reducers";
import {
  createTeam,
  createSport,
} from "../../../data/reducers/sports&teams/sports&teams.reducer";
import { withLoader } from "../../../utils/hoc/withLoader";
import { fetchVendorsListForDropdown } from "../../../data/reducers/vendors/cached-vendors.reducer";

const AddEditPSLModal = ({
  setBusy,
  isVisible,
  selectedRecord,
  handleClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountSportList());
    dispatch(getAccountTeamList());
  }, []);

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );
  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  const sports = useSelector((state) => state.accounts).sportList;
  const teams = useSelector((state) => state.accounts).teamList;
  const newlyCreated = useSelector((state) => state.accounts).newlyCreated;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = useForm();
  useEffect(() => {
    if (newlyCreated?.team?.id) {
      setdropVal({ ...dropVal, team: newlyCreated.team });
      setValue("teamId", newlyCreated?.team?.id);
    }
    if (newlyCreated?.sport?.id) {
      setdropVal({ ...dropVal, sport: newlyCreated.sport });
      setValue("sportId", newlyCreated?.sport?.id);
    }
  }, [newlyCreated]);

  const [isEdit, setIsEdit] = useState(false);
  const [customSportView, setCustomSportView] = useState(false);
  const [customTeamView, setCustomTeamView] = useState(false);
  const [date, setDate] = useState("");
  const [dropVal, setdropVal] = useState({
    source: "",
    team: "",
    sport: "",
  });
  const [dropsearch, setdropsearch] = useState({
    team: "",
    sport: "",
  });
  useEffect(() => {
    reset();
  }, [isVisible]);
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  useEffect(() => {
    if (selectedRecord) {
      setdropVal({
        source: {
          name: selectedRecord.source,
        },
        team: {
          id: selectedRecord.teamId,
          teamName: selectedRecord.teamName,
        },
        sport: {
          id: selectedRecord.sportId,
          sportName: selectedRecord.sportName,
        },
      });
      setIsEdit(true);
      setDate(String(selectedRecord?.date)?.substr(0, 10));
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      setdropVal({
        source: "",
        sport: "",
        team: "",
      });
      setIsEdit(false);
      setDate(inputDate());
      reset();
    }
    setCustomTeamView(false);
    setCustomSportView(false);
  }, [selectedRecord, isVisible]);

  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setdropVal({
      source: {
        name: selectedRecord.source,
      },
      team: {
        id: selectedRecord.teamId,
        teamName: selectedRecord.teamName,
      },
      sport: {
        id: selectedRecord.sportId,
        sportName: selectedRecord.sportName,
      },
    });
    setDate(String(selectedRecord?.date).substr(0, 10));
    setCustomTeamView(false);
    setCustomSportView(false);
  };

  const handleClear = () => {
    reset();
    setCustomSportView(false);
    setCustomTeamView(false);
    setdropVal({
      source: "",
      sport: "",
      team: "",
    });
    setDate("");
  };

  const handleAddSport = async (val) => {
    setBusy(true);
    await dispatch(createSport({ sportName: val }));
    setBusy(false);
  };

  const handleAddTeam = async (val) => {
    setBusy(true);
    await dispatch(createTeam({ teamName: val }));
    setBusy(false);
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>PSL</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/psl-source",
                name: "PSL Source",
                active: true,
              },
              {
                to: "/psl-source",
                name: "10503862",
                active: true,
              },
              {
                name: isEdit ? "Edit Charge" : "New Charge",
                active: false,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">PSL Information</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Row
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.row}
                        type="number"
                        placeholder="Row"
                        {...register("row", {
                          required: true,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Row is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Quantity
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.quantity}
                        type="number"
                        placeholder="Quantity"
                        {...register("quantity", {
                          required: true,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Quantity is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Section
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.section}
                        placeholder="Section"
                        {...register("section", {
                          required: true,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Section is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Seats
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.seats}
                        placeholder="Seats"
                        {...register("seats", {
                          required: true,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Seats is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      PSL Cost
                    </Form.Label>
                    <Col sm="7">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          isInvalid={!!errors.cost}
                          placeholder="PSL Cost"
                          {...register("cost", {
                            required: true,
                          })}
                        />
                      </InputGroup>

                      <Form.Control.Feedback type="invalid">
                        PSL Cost is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <br />
              <Row>
                <p className="title-accounts">Vendor Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      PSL Vendor
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="source"
                        control={control}
                        rules={{
                          required: true,
                          message: "error message",
                        }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isInvalid={!!errors.source}
                                value={dropVal.source}
                                onChange={(e) => {
                                  field.onChange(e?.name);
                                  setdropVal({
                                    ...dropVal,
                                    source: e,
                                  });
                                }}
                                styles={customStyles}
                                isClearable
                                options={[
                                  { name: "PSL Source" },
                                  { name: "STR" },
                                ]}
                                getOptionLabel={(option) => `${option.name}`}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  PSL Vendor is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="date"
                        isInvalid={!!errors.date}
                        {...register(
                          "date",
                          isEdit ? { required: false } : { required: true }
                        )}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Date is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <p className="title-accounts">Sport and Team</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="sportId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <CreatableSelect
                                required
                                // isClearable
                                // isInvalid={!!errors.sportId}
                                value={dropVal.sport}
                                onChange={async (e) => {
                                  field.onChange(e.id);
                                  setdropVal({ ...dropVal, sport: e });
                                }}
                                styles={customStyles}
                                // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                                options={sports?.filter((x) => x.id > 0)}
                                getOptionLabel={(option) => option.sportName}
                                onCreateOption={async (e) => {
                                  await handleAddSport(e);
                                  await field.onChange(newlyCreated?.sport?.id);
                                }}
                                getNewOptionData={(
                                  inputValue,
                                  optionLabel
                                ) => ({
                                  sportName: optionLabel,
                                  id: "new",
                                  isNew: true,
                                })}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Sport is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Team
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        required
                        isInvalid={!!errors.teamId}
                        name="teamId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <CreatableSelect
                                required
                                isInvalid={!!errors.teamId}
                                // isClearable
                                value={dropVal.team}
                                onChange={async (e) => {
                                  // if (e.id === "custom") {
                                  //   setCustomTeamView(true);
                                  // }
                                  if (e.id == "new") {
                                    await handleAddTeam(e.teamName);
                                    await setdropVal({
                                      ...dropVal,
                                      team: teams[teams.length],
                                    });
                                  } else {
                                    field.onChange(e.id);
                                    setdropVal({ ...dropVal, team: e });
                                  }
                                }}
                                styles={customStyles}
                                options={teams}
                                getOptionLabel={(option) => option.teamName}
                                getNewOptionData={(
                                  inputValue,
                                  optionLabel
                                ) => ({
                                  teamName: optionLabel,
                                  id: "new",
                                  isNew: true,
                                })}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Team is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : handleClear())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(AddEditPSLModal);
