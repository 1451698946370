import { poServices } from "../../services/purchaseOrders/po.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import moment from "moment";
import { flushPurchaseOrders } from "../purchaseOrders/cached-po.reducer";
import { flushPurchaseOrdersCards } from "../purchaseOrders/cached-po-cards.reducer";
import { inputDate } from "../../../utils/formatter/dateFormatter";
import { errorMessage } from "../alerts/alert.reducer";

export const createPO = createAsyncThunk(
  "purchaseOrders/CreatePO",
  async (payload, thunkAPI) => {
    const response = await poServices.createPO(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllPOByID = createAsyncThunk(
  "purchaseOrder/getAllPOByID",
  async (payload, thunkAPI) => {
    const response = await poServices.getPurchaseOrderById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getInvoiceFeeDropdown = createAsyncThunk(
  "purchaseOrder/getInvoiceFeeDropdown",
  async (payload, thunkAPI) => {
    const response = await poServices.getInvoiceFeeDropdown(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getTransactionsForPaymentPlan = createAsyncThunk(
  "purchaseOrder/getTransactionsForPaymentPlan",
  async (payload, thunkAPI) => {
    const response = await poServices.getTransactionsForPaymentPlan(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getInvoiceDetails = createAsyncThunk(
  "purchaseOrder/getInvoiceDetails",
  async (payload, thunkAPI) => {
    const response = await poServices.getInvoiceDetails(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const togglePOSkyboxRef = createAsyncThunk(
  "purchaseOrders/togglePOSkyboxRef",
  async (payload, thunkAPI) => {
    const response = await poServices.togglePOSkyboxRef(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updatePO = createAsyncThunk(
  "purchaseOrders/updatePO",
  async (payload, thunkAPI) => {
    const response = await poServices.updatePO(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deletePO = createAsyncThunk(
  "purchaseOrders/deletePO",
  async (payload, thunkAPI) => {
    const response = await poServices.deletePO(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteDraft = createAsyncThunk(
  "purchaseOrders/deleteDraft",
  async (payload, thunkAPI) => {
    const response = await poServices.deleteDraft(payload);
    if (response.isSuccessful === true) {
      thunkAPI.dispatch(flushPurchaseOrders());
      thunkAPI.dispatch(flushPurchaseOrdersCards());
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllPO = createAsyncThunk(
  "purchaseOrders/getAllPO",
  async (payload, thunkAPI) => {
    const response = await poServices.getAllPO(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPOList = createAsyncThunk(
  "purchaseOrders/getPurchaseOrderList",
  async (payload, thunkAPI) => {
    const response = await poServices.getPurchaseOrderList(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPOCards = createAsyncThunk(
  "po/getAccountsCards",
  async (payload, thunkAPI) => {
    const response = await poServices.getPOCards(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPOCardsById = createAsyncThunk(
  "po/getAccountsCardsById",
  async (payload, thunkAPI) => {
    const response = await poServices.getPOCardsById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVPurchaseOrder = createAsyncThunk(
  "purchaseOrders/exportCSVPurchaseOrder",
  async (thunkAPI) => {
    const response = await poServices.exportCSVPurchaseOrder();
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "PurchaseOrders.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfPurchaseOrder = createAsyncThunk(
  "purchaseOrders/exportPdfPurchaseOrder",
  async (thunkAPI) => {
    const response = await poServices.exportPdfPurchaseOrder();
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "PurchaseOrders.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const searchPO = createAsyncThunk(
  "accounts/searchPO",
  async (payload, thunkAPI) => {
    const response = await poServices.searchPO(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVPO = createAsyncThunk(
  "po/exportCSVPO",
  async (payload, thunkAPI) => {
    const response = await poServices.exportSelectedAsCSVPO(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "PurchaseOrderFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVPaymentPlans = createAsyncThunk(
  "purchaseOrders/exportCSVPaymentPlans",
  async (thunkAPI) => {
    const response = await poServices.exportCSVPaymentPlans();
    if (response.isSuccessful === true) {
      fileDownload(
        response.data?.data,
        `PurchaseOrders-PaymentPlans-${moment().format("MM/DD/YYYY")}.csv`
      );
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVPaymentPalns = createAsyncThunk(
  "po/exportSelectedAsCSVPaymentPalns",
  async (payload, thunkAPI) => {
    const response = await poServices.exportSelectedAsCSVPaymentPlans(payload);
    if (response.isSuccessful === true) {
      fileDownload(
        response.data?.data,
        `PaymentPlansFilter-${moment().format("MM/DD/YYYY")}.csv`
      );
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSV = createAsyncThunk(
  "po/exportCSVorPDF",
  async (payload, thunkAPI) => {
    const response = await poServices.exportCSV(payload);
    if (response.isSuccessful === true) {
      fileDownload(
        response.data?.data,
        `PurchaseOrder-${moment().format("DD/MM/YYYY")}.csv`
      );
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const exportPDF = createAsyncThunk(
  "po/exportCSVorPDF",
  async (payload, thunkAPI) => {
    const response = await poServices.exportPDF(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data);
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsPdfPO = createAsyncThunk(
  "po/exportPdfPO",
  async (payload, thunkAPI) => {
    const response = await poServices.exportSelectedAsPdfPO(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "PurchaseOrderFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const filterPO = createAsyncThunk(
  "accounts/filterPO",
  async (payload, thunkAPI) => {
    const response = await poServices.filterPO(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const uploadInvoicePdf = createAsyncThunk(
  "po/uploadInvoicePdf",
  async (payload, thunkAPI) => {
    const response = await poServices.uploadInvoicePdf(payload);
    return response;
  }
);

export const createInvoicePO = createAsyncThunk(
  "po/createInvoicePO",
  async (payload, thunkAPI) => {
    const response = await poServices.createInvoicePO(payload);
    return response;
  }
);

export const updateInvoicePO = createAsyncThunk(
  "po/updateInvoicePO",
  async (payload, thunkAPI) => {
    const response = await poServices.updateInvoicePO(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateStatusPO = createAsyncThunk(
  "po/updateStatusPO",
  async (payload, thunkAPI) => {
    const response = await poServices.updateStatusPO(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const generateMaskingFromBackend = createAsyncThunk(
  "po/generateMasking",
  async (payload, thunkAPI) => {
    const response = await poServices.generateMasking(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const uploadInvoicePoAttachment = createAsyncThunk(
  "po/uploadInvoicePoAttachment",
  async (payload, thunkAPI) => {
    const response = await poServices.uploadInvoicePoAttachment(payload);
    return response;
  }
);
export const saveInvoiceFile = createAsyncThunk(
  "po/saveInvoiceFile",
  async (payload, thunkAPI) => {
    const response = await poServices.saveInvoiceFile(payload);
    return response;
  }
);

export const setInvoiceDetailsManually = createAsyncThunk(
  "po/setInvoiceDetailsManually",
  async (payload, thunkAPI) => {
    return payload;
  }
);

export const setInvoiceTypeInInvoicePo = createAsyncThunk(
  "po/setInvoiceTypeInInvoicePo",
  async (payload, thunkAPI) => {
    return payload;
  }
);

const poSlice = createSlice({
  name: "purchaseOrder",
  initialState: {
    purchaseOrders: [],
    POList: [],
    selectedPO: {},
    paging: {},
    draftPOCount: 0,
    cards: {},
    allCards: {},
    addOrEdit: false,
    UploadInvoice: null,
    isUploadInvoice: false,
    UploadInvoiceFileName: null,
    InvoicePO: {},
    InvoicePoAttachment: null,
    PaymentPlan: {},
    purchasePaymentDetails: {
      purchaseOrderDate: inputDate(),
    },
    additionalDetails: {},
    isUpdateStatusPO: false,
    type: null,
    allPurchaseOrderDetails: undefined,
    invoiceFile: null,
    globalInvoiceType: null,
    globalUploadingInvoiceLoader: false,
    globalFeeDropdown: [],
    globalFeeInputs: [],
    ispendingPo: false,
    maxCompletedStep: 0,
    SelectedPurchaseOrders: [],
    selectedPendingPOTab: 0,
    pendingPONote: {},
    showStepperPO: true,
    selectedDraftPO: [],
    isPreviewSkipped: false,
    isAddFromInvoice: false,
    isPOSubmitted: false,
    paymentPlanTransactions: [],
    isPendingPOChanged: null,
  },
  reducers: {
    changePOEditState: (state, action) => {
      state.addOrEdit = action.payload;
    },
    setpendingPo: (state, action) => {
      state.ispendingPo = action.payload;
    },
    setshowStepperPO: (state, action) => {
      state.showStepperPO = action.payload;
    },
    setSelectedPurchaseOrders: (state, action) => {
      state.SelectedPurchaseOrders = action.payload;
    },
    setSelectedDraftPO: (state, action) => {
      state.selectedDraftPO = action.payload;
    },

    removeUploadedInvoice: (state, action) => {
      state.UploadInvoice = null;
    },
    getUploadedInvoiceDetails: (state, action) => {
      state.UploadInvoice = {
        name: state.UploadInvoice.data,
      };
    },
    setUploadedInvoiceFileName: (state, action) => {
      state.UploadInvoiceFileName = action.payload;
    },
    setPurchasePaymentDetails: (state, action) => {
      if (action?.payload?.data)
        state.purchasePaymentDetails = action.payload.data;
      else state.PaymentPlan = action?.payload?.calculated;
    },
    setAdditionalDetails: (state, action) => {
      state.additionalDetails = action.payload;
    },
    setInvoiceType: (state, action) => {
      state.type = action.payload;
    },
    setInvoiceFile: (state, action) => {
      state.invoiceFile = action.payload;
    },
    setGlobalInvoiceType: (state, action) => {
      state.globalInvoiceType = action.payload;
    },
    setGlobalUploadingInvoiceLoader: (state, action) => {
      state.globalUploadingInvoiceLoader = action.payload;
    },
    setGlobalFeeInputs: (state, action) => {
      state.globalFeeInputs = action.payload;
    },
    setGlobalMaxStep: (state, action) => {
      state.maxCompletedStep = action.payload;
    },
    setAllPurchaseOrderDetails: (state, action) => {
      state.allPurchaseOrderDetails = action.payload;
    },
    setAllPendingPOTab: (state, action) => {
      state.selectedPendingPOTab = action.payload;
    },
    setNotePendingPO: (state, action) => {
      const { id, note } = action.payload;
      state.pendingPONote[id] = note;
    },
    setIsPreview: (state, action) => {
      state.isPreviewSkipped = action.payload;
    },
    setisAddFromInvoice: (state, action) => {
      state.isAddFromInvoice = action.payload;
    },
    setIsPOSubmitted: (state, action) => {
      state.isPOSubmitted = action.payload;
    },
    setIsPendingPOChanged: (state, action) => {
      state.isPendingPOChanged = action.payload;
    },
  },
  extraReducers: {
    [setInvoiceTypeInInvoicePo.fulfilled]: (state, action) => {
      state.InvoicePO = action.payload;
    },
    [searchPO.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload;
    },
    [filterPO.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAllPO.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getPOList.fulfilled]: (state, action) => {
      state.POList = action.payload;
    },
    [createPO.fulfilled]: (state, action) => {
      state.purchaseOrders.push(action.payload);
    },
    [getAllPOByID.fulfilled]: (state, action) => {
      state.selectedPO = action.payload;

      // state.purchasePaymentDetails = action.payload;
    },
    [getInvoiceDetails.fulfilled]: (state, action) => {
      state.allPurchaseOrderDetails = action.payload;
    },
    [setInvoiceDetailsManually.fulfilled]: (state, action) => {
      state.allPurchaseOrderDetails = action.payload;
    },
    [getPOCardsById.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },
    [getPOCards.fulfilled]: (state, action) => {
      state.allCards = action.payload;
    },
    [updatePO.fulfilled]: (state, action) => {
      const index = state.purchaseOrders.findIndex(
        (po) => po.id == action.payload.id
      );
      state.purchaseOrders[index] = action.payload;
      state.selectedCharges = action.payload;
    },
    [deletePO.fulfilled]: (state, action) => {
      state.purchaseOrders = [
        ...state.purchaseOrders.filter((po) => po.id !== action.payload.id),
      ];
    },
    [uploadInvoicePdf.fulfilled]: (state, action) => {
      state.isUploadInvoice = false;
      state.UploadInvoice = action.payload;
    },
    [uploadInvoicePdf.rejected]: (state, action) => {
      state.isUploadInvoice = false;
      state.UploadInvoice = action.payload;
    },
    [uploadInvoicePdf.pending]: (state, action) => {
      state.isUploadInvoice = true;
    },
    [createInvoicePO.fulfilled]: (state, action) => {
      state.InvoicePO = action.payload?.data;
    },
    [updateInvoicePO.fulfilled]: (state, action) => {
      state.InvoicePO = action.payload;
    },
    [uploadInvoicePoAttachment.fulfilled]: (state, action) => {
      state.InvoicePoAttachment = action.payload;
    },
    [getInvoiceFeeDropdown.fulfilled]: (state, action) => {
      state.globalFeeDropdown = action.payload;
    },
    [updateStatusPO.fulfilled]: (state, action) => {
      state.isUpdateStatusPO = true;
    },
    [getTransactionsForPaymentPlan.fulfilled]: (state, action) => {
      state.paymentPlanTransactions = action.payload;
    },
  },
});

export const {
  removeSelected,
  changePOEditState,
  removeUploadedInvoice,
  getUploadedInvoiceDetails,
  setPurchasePaymentDetails,
  setAdditionalDetails,
  setInvoiceType,
  setUploadedInvoiceFileName,
  setInvoiceFile,
  setGlobalInvoiceType,
  setGlobalUploadingInvoiceLoader,
  getFeeDropdown,
  setGlobalFeeInputs,
  setpendingPo,
  setSelectedDraftPO,
  setSelectedPurchaseOrders,
  setGlobalMaxStep,
  setAllPurchaseOrderDetails,
  setAllPendingPOTab,
  setNotePendingPO,
  setshowStepperPO,
  setIsPreview,
  setisAddFromInvoice,
  setIsPOSubmitted,
  setIsPendingPOChanged,
} = poSlice.actions;

export default poSlice.reducer;
