import ModalBreadcrumb from "../../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountSportList } from "../../../../data/reducers/accounts/accounts.reducers";
import Select from "react-select";

const AddEditSportsModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
}) => {
  const dispatch = useDispatch();
  const [dropVal, setdropVal] = useState({
    sport: "",
  });
  const [isEdit, setEdit] = useState(false);
  // setup react hook form

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();
  const sportDetails = useSelector((state) => state.accounts).sportList;

  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  // reset form on visibility toggle
  useEffect(() => {
    reset();
    dispatch(getAccountSportList());
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setdropVal({
        sport: sportDetails.filter((s) => s.id === selectedRecord.sportId),
      });
    } else {
      // if selected record is undefined, reset
      setdropVal({ sport: "" });
      setEdit(false);
      reset();
    }
  }, [selectedRecord]);
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Sports</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/settings/sports-and-teams",
                name: "Sports & Teams",
                active: false,
              },
              {
                name: isEdit ? "Edit Sport" : "New Sport",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Details</p>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Sport
                    </Form.Label>
                    <Col>
                      <Controller
                        name="sportId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <Select
                                required
                                isClearable
                                isInvalid={!!errors.sportId}
                                value={dropVal?.sport}
                                onChange={async (e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, sport: e });
                                }}
                                styles={customStyles}
                                // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                                options={sportDetails?.filter((x) => x.id > 0)}
                                getOptionLabel={(option) => option.sportName}
                              />
                            </>
                          );
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Team
                    </Form.Label>
                    <Col>
                      <Form.Control
                        isInvalid={!!errors.teamName}
                        {...register("teamName", { required: true })}
                        placeholder="Team"
                      />
                      <Form.Control.Feedback type="invalid">
                        Team Name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : reset())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditSportsModal;
