import React, { useEffect, useState } from "react";
import { IoInformationCircleOutline, IoWarningOutline } from "react-icons/io5";
import { TbCalendarClock } from "react-icons/tb";
import WarningModal from "../../modals/WarningModal.js";
import Reset from "../../../../images/reset.svg";
import { useDispatch, useSelector } from "react-redux";
import Pdfimage from "../../../../images/pdfimage.svg";
import { Button } from "react-bootstrap";
import "../../../../../src/pages/accounts/index.scss";
import { removeUploadedInvoice, setAdditionalDetails, setAllPurchaseOrderDetails, setGlobalFeeInputs, setGlobalMaxStep, setInvoiceDetailsManually, setInvoiceType, setInvoiceTypeInInvoicePo, setPurchasePaymentDetails, setUploadedInvoiceFileName } from "../../../../data/reducers/purchaseOrders/po.reducer";
import { setSelectedAccountDetails, setSelectedTicketDetails } from "../../../../data/reducers/accounts/accounts.reducers";
export default function PdfHeader({
  selectedOption1,
  selectedOption2,
  selectedInvoice,
  setSelectedInvoice,
  setStep,
}) {
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const dispatch = useDispatch();
  const [isVisible, setIsvisible] = useState(null);
  const [modalfor, setModalFor] = useState("");
  const { globalFeeDropdown } = useSelector((state) => state.purchaseOrder)
  const handleButtonClick = (event, value) => {

    if (event) {
      event.preventDefault();
      event.target.value = "";
    }
    if (value) {
      setIsvisible(false);
      setModalFor("");
      dispatch(removeUploadedInvoice());
      dispatch(setUploadedInvoiceFileName(null));
      dispatch(setSelectedAccountDetails({}));
      dispatch(setSelectedTicketDetails({}));
      dispatch(setPurchasePaymentDetails({ calculated: {} }));
      dispatch(setPurchasePaymentDetails({ data: {} }));
      dispatch(setAdditionalDetails({}));
      dispatch(setGlobalMaxStep(0));
      dispatch(setAllPurchaseOrderDetails({}))
      dispatch(setGlobalFeeInputs([]))
      
      setStep(0);
    } else if (!value && !event) {
      setIsvisible(false);
      setModalFor("");
    }
  };

  const handleReset = () => {
    setIsvisible(true);
    setModalFor("reset");

  };


  return (
    <div
      style={{
        boxShadow: "0px 4px 12px 0px #CCCCCC3D",
        padding: "16px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        gap: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex">
          <div style={{ width: "20px", height: "20px" }}>
            <TbCalendarClock
              size={30}
              color={selectedOption1 ? "#0241A0" : "#6b6b6b"}
            />
          </div>
          <div style={{ marginLeft: "20px" }}>
            <span style={{ fontWeight: "500", fontSize: "18px" }}>
              {selectedOption1 ? "Regular Season - " : selectedOption2 ? "Post Season - " : null}
              {selectedOption1
                ? selectedOption1.label
                : selectedOption2
                  ? selectedOption2.label
                  : selectedInvoice == 3
                    ? "Deposit"
                    : selectedInvoice == 4
                      ? "Group Purchase"
                      : selectedInvoice == 5
                        ? "PSL"
                        :
                        selectedInvoice == 6
                          ? "Ticket Associates"
                          : null}
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <span
            style={{
              color: "rgb(2, 80, 160)",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handleReset}
          >
            <img
              style={{
                marginTop: "-2px",
                marginRight: "4px",
              }}
              src={Reset}
            />
            Reset
          </span>
          <span
            style={{
              color: "rgb(2, 80, 160)",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => setStep(0)}
          >
            Change Invoice Type
          </span>
        </div>

        {isVisible && (
          <WarningModal
            isVisible={isVisible}
            handleSubmit={handleButtonClick}
            handleVisibility={() => setIsvisible(false)}
            type={modalfor}
          />
        )}

      </div>
      <div
        style={{ color: "#a7a7a7", paddingTop: "10px" }}
        className="d-flex align-items-center mt-2"
      >
        {purchaseOrderData.UploadInvoiceFileName ? (
          <span>
            <img src={Pdfimage} alt=" " className="me-2 my-1" />
          </span>
        ) : null}
        <span
          style={{
            color: purchaseOrderData?.UploadInvoiceFileName
              ? "rgb(2, 80, 160)"
              : "grey",
            fontSize: "0.8rem",
          }}
        >
          {purchaseOrderData.UploadInvoiceFileName
            ? purchaseOrderData.UploadInvoiceFileName
            : "No File Uploaded"}
        </span>
      </div>
    </div>
  );
}
