import { ToggleButton } from "react-bootstrap";
import { useSelector } from "react-redux";

const HeaderPart = ({
  headerName,
  selectedTabIndex,
  tabs,
  onTabSelectionChange,
  headOptions,
  middleOptions,
  bottomOptions,
  isDraft,
  headButton,
  unselectAllCheckboxes,
}) => {
  let className = "d-flex align-items-stretch p-3 justify-content-between";
  if (!bottomOptions) {
    className += " table-header";
  }
  const selectedPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.SelectedPurchaseOrders
  );

  return (
    <div className="table-header align-items-stretch justify-content-center">
      <div className={className}>
        <div className="">
          <h4>
            <b>{headerName}</b>
          </h4>
          {headButton ? headButton() : ""}
          {tabs && !isDraft && (
            <div className="d-flex flex-wrap">
              {tabs.map((t, i) => (
                <ToggleButton
                  key={i}
                  value={i}
                  type="radio"
                  name="table-tabs"
                  checked={selectedTabIndex == i}
                  variant="light"
                  className="ss-table-tab-button me-2 mt-2"
                  onChange={() => onTabSelectionChange(i)}
                  style={{
                    fontSize: "0.89rem",
                    fontWeight: "500",
                  }}
                >
                  {t}
                </ToggleButton>
              ))}
            </div>
          )}
          {selectedPurchaseOrders && selectedPurchaseOrders.length > 0 ? (
            <p className="mt-3">
              {selectedPurchaseOrders.length} PO
              {selectedPurchaseOrders.length > 1 ? "s" : ""} selected{" "}
              <span
                onClick={unselectAllCheckboxes}
                style={{ color: "#ff0000", cursor: "pointer" }}
              >
                Unselect All
              </span>
            </p>
          ) : null}
        </div>
        {middleOptions && (
          <div className="m-auto align-items-center justify-content-center">
            {middleOptions()}
          </div>
        )}
        {headOptions && (
          <div className="d-flex flex-column">{headOptions()}</div>
        )}
      </div>
      {bottomOptions && <div>{bottomOptions()}</div>}
    </div>
  );
};

export default HeaderPart;
