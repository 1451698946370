/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Dropdown, Form, Nav, Row } from "react-bootstrap";

import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import { withLoader } from "../../../utils/hoc/withLoader";

//components
import FilterPanel from "../../../shared/components/panels/filter-panel/FilterPanel";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import DeleteConfirmationModal from "../../../shared/components/modals/DeleteConfirmationModal";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";

import { syncSkybox } from "../../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import { syncSkyboxVendors } from "../../../data/reducers/vendors/vendors.reducer";
import { syncBanking } from "../../../data/reducers/finance/loans.reducer";
import { runRules } from "../../../data/reducers/transactions/transactions.reducer";
import {
  filterSyncData,
  getAllSyncData,
} from "../../../data/reducers/sync/sync.reducer";
import { formatDate } from "../../../utils/formatter/dateFormatter";

// images
import Run from "../../../images/run.svg";
import Done from "../../../images/done.svg";
import Not from "../../../images/notDone.svg";

import "../../../shared/styles/ModalStyles.scss";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../../mixpanel";

const SyncDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");
  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.SYNC,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.SYNC,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);
  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "date", value: filterUrlData?.date },
        { key: "isSuccess", value: filterUrlData?.isSuccess },
        { key: "loadName", value: filterUrlData?.loadName },
      ]);
    }
  }, [filterUrlData]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        date: queryParameters.get("date"),
        isSuccess: queryParameters.get("isSuccess"),
        loadName: queryParameters.get("loadName"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/settings/sync?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(
        `/settings/sync?page=${Number(pageNumber) - 1}${searchQuery()}`
      );
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(
      `/settings/sync?page=${Number(pageNumber) + 1}${searchQuery()}`
    );
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/settings/sync?page=${data.searchText ? 1 : page}`
    var url = `/settings/sync?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.date) url += `&&date=${data.date}`;
    if (data.isSuccess) url += `&&isSuccess=${data.isSuccess}`;
    if (data.loadName) url += `&&loadName=${data.loadName}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //selectors
  const { syncData, paging } = useSelector((state) => state.sync);

  //use states
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [addAccountModalVisible, setAddEditAccountModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [dropVal, setdropVal] = useState({
    vendor: "",
    team: "",
    sport: "",
  });
  const [searchData, setsearchData] = useState(null);

  const fetchSyncData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize: pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllSyncData(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber,
      pageSize,
      sortField,
      sortDir,
      searchText: data?.searchText?.trim() || "",
    };
    setsearchData(formData);
    setBusy(true);
    if (data?.searchText || data?.loadName || data?.date || data?.isSuccess) {
      await dispatch(filterSyncData(formData));
      setBusy(false);
    } else {
      fetchSyncData();
    }
  };

  const syncDataCol = [
    {
      columnName: (
        <>
          Sync <br /> Name{" "}
        </>
      ),
      render: (data) => <>{data?.loadName}</>,
    },
    {
      columnName: "Date/Time",
      render: (data) => <>{formatDate(data?.date)}</>,
    },
    {
      columnName: "Status",
      render: (data) => <>{<img src={data.isSuccess ? Done : Not} alt="" />}</>,
    },
    {
      columnName: "Message",
      columnKey: "errorMessage",
      render: (data) => (
        <>
          {data?.isSuccess && data.errorMessage}
          {!data?.isSuccess && (
            <p style={{ color: "red" }}>{data?.exceptionDetails}</p>
          )}
        </>
      ),
    },
  ];

  const headButton = () => (
    <div className="text-end d-block align-items-center justify-content-center float-center pt-4 m-auto">
      {/* < Button
                onClick={async (e) => {
                    e.currentTarget.blur();
                }}
                variant="primary"
                className="ms-2 ss-light-button h-50"
            >
                <img src={Run} alt=" " />
                <span>Sync All</span>
            </Button > */}
    </div>
  );
  //table head
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div
          className="text-end mt-3 d-flex flex-1 justify-content-between w-100"
          style={{ justifyContent: "between", alignItems: "center", flex: 1 }}
        >
          {/* <div className="text-end d-flex align-items-center">

                        <Button
                            onClick={async (e) => {
                                e.currentTarget.blur();
                            }}
                            variant="primary"
                            className="ms-2 ss-light-button h-50"
                        >
                            <img src={Run} alt=" " />
                            <span>Sync All</span>
                        </Button>
                    </div> */}
          <div className="d-block">
            <div className="text-end mt-3 d-flex">
              <Button
                onClick={async () => {
                  setBusy(true);
                  await dispatch(syncSkybox());
                  setBusy(false);
                  fetchSyncData();
                }}
                variant="primary"
                className="ms-2 ss-light-button"
              >
                <img src={Run} alt=" " />
                <span>Sync Skybox Purchases</span>
              </Button>
              <Button
                onClick={async () => {
                  setBusy(true);
                  await dispatch(syncSkyboxVendors());
                  setBusy(false);
                  fetchSyncData();
                }}
                variant="primary"
                className="ms-2 ss-light-button"
              >
                <img src={Run} alt=" " />
                <span>Sync Skybox Vendors</span>
              </Button>
            </div>
            <div className="text-end mt-3 d-flex justify-content-end">
              <Button
                onClick={async () => {
                  setBusy(true);
                  await dispatch(syncBanking());
                  setBusy(true);
                  fetchSyncData();
                }}
                variant="primary"
                className="ms-2 ss-light-button"
              >
                <img src={Run} alt=" " />
                <span>Sync Bank Balance</span>
              </Button>
              <Button
                onClick={async (e) => {
                  setBusy(true);
                  await dispatch(runRules());
                  setBusy(true);
                  fetchSyncData();
                }}
                variant="primary"
                className="ms-2 ss-light-button"
              >
                <img src={Run} alt=" " />
                <span>Run All Rules</span>
              </Button>
              {/* <Button
                                onClick={async (e) => {
                                    e.currentTarget.blur();
                                }}
                                variant="primary"
                                className="ms-2 ss-light-button"
                            >
                                <img src={Run} alt=" " />
                                <span>Sync Com Data</span>
                            </Button> */}
              {/* <Button
                                onClick={async (e) => {
                                    e.currentTarget.blur();
                                }}
                                variant="primary"
                                className="ms-2 ss-light-button"
                            >
                                <img src={Run} alt=" " />
                                <span>Sync Vendor Images</span>
                            </Button> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.SYNC,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            dashboard={mixpanel_contants.SYNC}
            defaultValues={filterArray}
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for Date, Sync Name or Status..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              setdropVal({
                vendor: "",
                sport: "",
                team: "",
              });
            }}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        placeholder="Date"
                        {...register("date")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Sync Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Sync Name"
                        {...register("loadName")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Status
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="select"
                        placeholder="Status"
                        {...register("isSuccess")}
                      >
                        <option value={2}>Select</option>
                        <option value={1}>Success</option>
                        <option value={0}>Failure</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <QuickTixTable
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="Sync"
        paging={paging}
        columns={syncDataCol}
        data={syncData || []}
        headOptions={headOptions}
        headButton={headButton}
        setpageNumber={setpageNumber}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : syncDataCol}
            rowData={rowData}
          />
        )}
      />
    </div>
  );
};

export default withLoader(SyncDashboard);
