import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { TbCalendarClock, TbTrophy } from "react-icons/tb";
import { AiOutlineDollar } from "react-icons/ai";
import { LuTicket } from "react-icons/lu";
import { GoPeople } from "react-icons/go";
import styles from "../../styles/selectedinvoice.module.css";
import PdfHeader from "./PdfHeader.jsx";
import { setGlobalInvoiceType } from "../../../../data/reducers/purchaseOrders/po.reducer.js";
import { useDispatch } from "react-redux";

const SelectInvoice = ({
  HandleInvoiceType,
  setSelectedInvoice,
  setSelectedOption1,
  setSelectedOption2,
  selectedInvoice,
  selectedOption1,
  selectedOption2,
  setStep,
  step,
}) => {
  const drop1 = [
    { label: "Full Season", value: "Full Season" },
    { label: "Half Season ", value: "Half Season" },
    { label: "Partial Season", value: "Partial Season" },
    { label: "Flex Purchase", value: "Flex Purchase" },
  ];

  const drop2 = [
    { label: "Full Strip", value: "Full Strip" },
    { label: "Half Strip", value: "Half Strip" },
    { label: "Single Round", value: "Single Round" },
    { label: "Flex Purchase", value: "Flex Purchase" },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedOption1) {
      dispatch(setGlobalInvoiceType(1))
    }
    else if (selectedOption2) {
      dispatch(setGlobalInvoiceType(2))
    }
    else if (selectedInvoice == 3) {
      dispatch(setGlobalInvoiceType(3))
    }
    else if (selectedInvoice == 4) {
      dispatch(setGlobalInvoiceType(4))
    }
    else if (selectedInvoice == 5) {
      dispatch(setGlobalInvoiceType(5))
    }
    else if (selectedInvoice == 6) {
      dispatch(setGlobalInvoiceType(6))
    }
  }, [selectedOption1, selectedOption2, selectedInvoice])

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      fontSize: "14px",
      height: "30px",
      minHeight: "30px",
      maxHeight: "30px",
      backgroundColor: state?.hasValue ? "#ECF5FF" : null,
      border: "none",
      boxShadow: state.isFocused ? "none" : provided.boxShadow, // Remove box shadow on focus
      borderColor: state.isFocused ? "#ccc" : provided.borderColor, // Ad
      display: 'flex',
    }),
    container: (provided, state) => ({
      ...provided,
      bottom: "0",
      fontSize: "14px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "5px",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
      height: "100%",
      display: "flex",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: "500",
      alignItems: "center",
      // fontSize: "13px",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };
  const selectRef = useRef(null);
  const selectRef2 = useRef(null);

  const handleDivClick = (selectedref) => {
    if (selectedref.current) {
      selectedref.current.focus();
    }
  };

  const SingleValue = ({ data }) => (
    <div style={{ padding: '8px', whiteSpace: 'nowrap', width: '90%' }}>{`Regular Season-${data.label}`}</div>
  );
  const SingleValuedrop = ({ data }) => (
    <div style={{ padding: '8px', whiteSpace: 'nowrap', width: '90%' }}>{`Post Season-${data.label}`}</div>
  );



  return (
    <>
      {step == 0 ? (
        <div
          style={{
            boxShadow: "0px 4px 12px 0px #CCCCCC3D",
            padding: "16px",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            gap: "16px",
          }}
        >
          <span style={{ fontWeight: "600", fontSize: "20px" }}>
            Add PO from Invoice
          </span>
          <div
            style={{
              color: "#404040",
              fontSize: "14px",
              fontWeight: "500",
              width: "20%",
            }}
          >
            Select Invoice Type
          </div>
          <div
            className="btn-group mt-3 my-2 d-flex align-items-center justify-content-between"
            style={{ columnGap: "15px" }}
          >
            <div
              style={{
                backgroundColor: selectedOption1 ? "#ECF5FF" : null,
                borderColor: selectedOption1 ? "#0241A0" : null,
              }}
              className={styles.commonStyles}
              onClick={() => handleDivClick(selectRef)}
            >
              <div style={{ width: "20px", height: "20px" }}>
                <TbCalendarClock
                  size={20}
                  color={selectedOption1 ? "#0241A0" : "#a7a7a7"}
                />
              </div>
              <Select
                ref={selectRef}
                openMenuOnFocus={true}
                required
                styles={customStyles}
                defaultValue={drop1[0]}
                options={drop1}
                onChange={(e) => {
                  setSelectedInvoice(-1);
                  setSelectedOption2(null);
                  setSelectedOption1(e);
                  setStep(0);
                }}
                placeholder="Regular season"
                value={selectedOption1}
                components={{ SingleValue }}
              />
            </div>

            <div
              style={{
                backgroundColor: selectedOption2 ? "#ECF5FF" : null,
                borderColor: selectedOption2 ? "#0241A0" : null,
              }}
              className={styles.commonStyles}
              onClick={() => handleDivClick(selectRef2)}
            >
              <div style={{ width: "20px", height: "20px" }}>
                <TbTrophy
                  color={selectedOption2 ? "#0241A0" : "#a7a7a7"}
                  size={20}
                />
              </div>
              <Select
                ref={selectRef2}
                openMenuOnFocus={true}
                required
                styles={customStyles}
                options={drop2}
                defaultValue={drop2[1]}
                onChange={(e) => {
                  setSelectedInvoice(-1);
                  setSelectedOption1(null);
                  setSelectedOption2(e);
                  setStep(0);
                }}
                placeholder="Post season"
                value={selectedOption2}
                components={{ SingleValue: SingleValuedrop }}
              />
            </div>

            <div
              style={{
                backgroundColor: selectedInvoice === 3 ? "#ECF5FF" : null,
                borderColor: selectedInvoice === 3 ? "#0241A0" : null,
              }}
              className={styles.commonStyles}
              onClick={() => {
                setStep(0);
                HandleInvoiceType(3);
              }}
            >
              <div style={{ width: "20px", height: "20px" }}>
                <AiOutlineDollar
                  color={selectedInvoice === 3 ? "#0241A0" : "#a7a7a7"}
                />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor:
                    selectedInvoice === 3 ? "#ECF5FF" : "transparent",
                }}
              >
                Deposit
              </div>
            </div>

            <div
              style={{
                backgroundColor: selectedInvoice === 4 ? "#ECF5FF" : null,
                borderColor: selectedInvoice === 4 ? "#0241A0" : null,
              }}
              className={styles.commonStyles}
              onClick={() => {
                setStep(0);
                HandleInvoiceType(4);
              }}
            >
              <div style={{ width: "20px", height: "20px" }}>
                <GoPeople
                  color={selectedInvoice === 4 ? "#0241A0" : "#a7a7a7"}
                  size={20}
                />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor:
                    selectedInvoice === 4 ? "#ECF5FF" : "transparent",
                }}
              >
                Group Purchase
              </div>
            </div>

            <div
              style={{
                backgroundColor: selectedInvoice === 5 ? "#ECF5FF" : null,
                borderColor: selectedInvoice === 5 ? "#0241A0" : null,
              }}
              className={styles.commonStyles}
              onClick={() => {
                setStep(0);
                HandleInvoiceType(5);
              }}
            >
              <div style={{ width: "20px", height: "20px" }}>
                <LuTicket
                  color={selectedInvoice === 5 ? "#0241A0" : "#a7a7a7"}
                  size={20}
                />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor:
                    selectedInvoice === 5 ? "#ECF5FF" : "transparent",
                }}
              >
                PSL
              </div>
            </div>
            <div
              style={{
                backgroundColor: selectedInvoice === 6 ? "#ECF5FF" : null,
                borderColor: selectedInvoice === 6 ? "#0241A0" : null,
              }}
              className={styles.commonStyles}
              onClick={() => {
                setStep(0);
                HandleInvoiceType(6);
              }}
            >
              <div style={{ width: "20px", height: "20px" }}>
                <AiOutlineDollar
                  color={selectedInvoice === 6 ? "#0241A0" : "#a7a7a7"}
                />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor:
                    selectedInvoice === 6 ? "#ECF5FF" : "transparent",
                }}
              >
                Ticket Associates
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PdfHeader
          selectedOption1={selectedOption1}
          selectedOption2={selectedOption2}
          selectedInvoice={selectedInvoice}
          setSelectedInvoice={setSelectedInvoice}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default SelectInvoice;
