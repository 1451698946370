import Done from "../../../../images/done.svg";
import Not from "../../../../images/notDone.svg";
import { formatMoney } from "../../../../utils/formatter/currencyFormatter";
import { formatDate } from "../../../../utils/formatter/dateFormatter";
import { formatTime } from "../../../../utils/formatter/timeFormatter";
import { Button } from "react-bootstrap";
import EditIcon from "../../../../images/edit.svg";
import DeleteIcon from "../../../../images/delete.svg";
import Down from "../../../../images/downarrow.svg";
import Up from "../../../../images/uparrow.svg";

import Dollar from "../../../../images/$.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import TransactionImg from "../../../../images/transPayment.svg";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { changePOEditState } from "../../../../data/reducers/purchaseOrders/po.reducer";



const charges = (onEdit, onDelete, setSelectedRecord, matchedVisibility, setmatchedVisibility) => {

  return (
    [{
      columnName: "Date/Time",
      sort: true,
      sortName: 'DateAndTime',
      render: (data) => (
        <>
          {formatDate(data?.dateAndTime)}
          <br />
          {formatTime(data?.dateAndTime)}
        </>
      )
    },
    {
      columnName: "Source Type / Id",
      render: (data) => (
        <>
          {data?.sourceType == "MANUAL" ? "MANUAL" : <>
            {data.sourceType}
            <br />
            {data.sourceId}
          </>
          }</>
      )
    },
    // {
    //   columnName: "Card Name Last 4",
    //   render: (data) => (
    //     <>
    //       {data.sourceType}
    //       <br />
    //       {data.sourceId}
    //     </>
    //   )
    // },
    {
      columnName: "Description",
      sort: true,
      sortName: "Description",
      columnKey: "description",
      render: (data) => (
        <div style={{ overflow: 'hidden' }}>
          {/* {data?.description.length > 15 ? data.notes.substr(0, 7) + '...' : data.notes} */}
          {data?.description}
        </div>
      )
    },
    // {
    //   columnName: "Notes",
    //   flexGrow: 0.5,
    //   render: (data) => (
    //     <div>
    //       {data?.notes.length > 15 ? data.notes.substr(0, 7) + '...' : data.notes}

    //     </div>
    //   )
    // },
    {
      columnName: "Vendor",
      sort: true,
      sortName: "VendorId",
      render: (data) => (
        <Link
          variant='link'
          className='ss-link'
          to={'/vendors/' + data.vendorId}>
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
    },
    {
      columnName: "Account",
      sort: true,
      sortName: 'AccountId',
      render: (data) => (
        <>
          {data?.autoGenerated === false ?
            <Link
              variant='link'
              className='ss-link'
              to={'/accounts/' + data.accountId}>
              {data.accountName}<br />
              {data.accountId == 0 ? "" : data.accountId}
            </Link> : <span>
              {data.accountName}<br />
              {data.accountId == 0 ? "" : data.accountId}
            </span>}
        </>
      ),
    },
    {
      columnName: "Purchase Order",
      // sort: true,
      // sortName: "InvoiceId",
      render: (data) => (
        <>
          {
            data.id != (0 || null) &&
              data.purchaseOrderAbbrev ? (< Link
                variant='link'
                className='ss-link'
                to={'/purchases/' + data.id} >
                {data.purchaseOrderAbbrev}
              </Link >) : <div>-</div>
          }
        </>
      ),
      columnKey: "purchaseOrderAbbrev",
    },
    {
      columnName: "Amount",
      sort: true,
      sortName: "Amount",
      render: (data) => (
        <>
          {formatMoney(data?.amount)}
        </>
      )
    },
    // {
    //   columnName: "Matched",
    //   sort: true,
    //   render: (data) => (
    //     <small>
    //       <img src={data?.purchaseOrderId ? Done : Not} alt="" />
    //     </small>
    //   )
    // },
    {
      columnName: "Actions",
      render: (data) => (
        <>
          <small className="table-row-card-actions d-flex">
            {!data?.autoGenerated &&
              <>
                <Button
                  variant='link'
                  className='table-action-button align-items-center red_bg'
                  onClick={() => {
                    setSelectedRecord(data);
                    setmatchedVisibility(!matchedVisibility);
                  }}>
                  <img src={TransactionImg} alt='delete' />
                </Button>
                {data?.sourceType == "MANUAL" &&
                  <>
                    <Button variant="link" className="table-action-button green_bg" onClick={() => onEdit(data)}>
                      <img src={EditIcon} alt=" " />
                    </Button>
                    <Button variant="link" className="table-action-button" onClick={() => onDelete(data)}>
                      <img src={DeleteIcon} alt=" " />
                    </Button>
                  </>}
              </>}
          </small>
        </>
      )
    },
    ]
  );
};



const rules = (onEdit, onDelete) => {
  return (
    [
      {
        columnName: "Name",
        sort: true,
        sortName: "Name",
        columnKey: "name"
      },
      {
        columnName: "Card",
        sort: true,
        sortName: 'Card',
        columnKey: "card"
      },
      {
        columnName: "Actions",
        render: (data) => (
          <small className="table-row-card-actions d-flex">
            <Button variant="link" className="table-action-button green_bg" onClick={() => onEdit(data)}>
              <img src={EditIcon} alt=" " />
            </Button>
            <Button variant="link" className="table-action-button" onClick={() => onDelete(data)}>
              <img src={DeleteIcon} alt=" " />
            </Button>
          </small>
        ),
        flexGrow: 0.5

      },
    ]
  );
};


const purchase = (onEdit, onDelete, setModalVisibility, setSelectedRecordId) => {
  return (
    [{
      columnName: "Purchase Order",
      sort: true,
      sortName: "PurchaseOrderId",
      render: (data) => (
        <>
          {
            data.id != 0 &&
            < Link
              variant='link'
              className='ss-link'
              to={'/purchases/' + data.id} >
              {data.purchaseOrderId}
            </Link >
          }
        </>
      ),
      columnKey: "purchaseOrderId",
    },
    {
      columnName: "Date",
      sort: true,
      sortName: 'PurchaseOrderDate',
      render: (data) => (
        <>
          {data?.purchaseOrderDate ? formatDate(data.purchaseOrderDate) : ""}
        </>
      ),
      flexGrow: 0.5
    },
    {
      columnName: "Description",
      sort: true,
      sortName: "Description",
      columnKey: "description"
    },
    {
      columnName: "Total Scheduled",
      sort: true,
      sortName: "TotalScheduled",

      render: (data) => (
        <>
          {formatMoney(data?.totalScheduled)}
        </>
      ),
    },
    {
      columnName: "Total Paid",
      render: (data) => (
        <>
          {formatMoney(data?.totalPaid)}
        </>
      )
    },
    {
      columnName: "Balance",
      sort: true,
      sortName: "Balance",
      render: (data) => (
        <>
          {formatMoney(data?.balance)}
        </>
      ),
    },
    {
      columnName: "Vendor",
      flexGrow: 0.5,
      sort: true,
      sortName: "VendorId",
      render: (data) => (
        <Link
          variant='link'
          className='ss-link'
          to={'/vendors/' + data.vendorId}>
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
    },
    {
      columnName: "Account",
      flexGrow: 0.5,
      sort: true,
      sortName: 'AccountId',
      render: (data) => (
        <>
          {data?.autoGenerated === false ?
            <Link
              variant='link'
              className='ss-link'
              to={'/accounts/' + data.accountId}>
              {data.accountName}<br />
              {data.accountId == 0 ? "" : data.accountId}
            </Link> : <span>
              {data.accountName}<br />
              {data.accountId == 0 ? "" : data.accountId}
            </span>}
        </>
      ),
    },
    {
      columnName: "SkyBox Ref",
      sort: true,
      sortName: "SkyBoxRef",
      render: (data) => (
        <>{
          data.skyBoxRef && data.skyBoxRef.length > 2 ? (
            <a
              target="blank"
              variant='link'
              className='ss-link'
              href={'https://skybox.vividseats.com/purchases/' + data.skyBoxRef}>
              {data?.skyBoxRef?.length > 5 ? `Skybox/${data?.skyBoxRef.substr(0, 5)}...` : `Skybox/${data?.skyBoxRef}`}
            </a >
          )
            : "--"}</>
      ),
      flexGrow: 0.5
    },
    {
      columnName: "SkyBox Value",
      flexGrow: 0.5,
      render: (data) => (
        <>
          {formatMoney(data?.skyboxRefAmount)}
        </>
      ),
    },
    {
      columnName: "Matched",
      render: (data) => (
        <small>
          <img src={(data.skyBoxRef === "" || data.skyBoxRef === null) ? Not : Done} alt="" />
        </small>
      ),
      flexGrow: 0.5

    },
    {
      columnName: "Actions",
      render: (data) => {
        return (
          <small className="table-row-card-actions d-flex" >
            {data.paymentPlanStatus === false && <Button variant="link" className="table-action-button" onClick={(e) => {
              setSelectedRecordId(data)
              setModalVisibility(true)
            }}>
              <img src={Dollar} alt="" />
            </Button>}
            <>
              <Button variant="link" className="table-action-button green_bg" onClick={() => onEdit(data)}>
                <img src={EditIcon} alt=" " />
              </Button>
              {data?.autoGenerate === false &&
                <Button variant="link" className="table-action-button" onClick={() => onDelete(data)}>
                  <img src={DeleteIcon} alt=" " />
                </Button>}
            </>
          </small >
        )
      }
    },
    ]
  );
};

const locations = (onEdit, onDelete) => {
  return (
    [
      {
        columnName: "Section",
        columnKey: 'section'
        // render: () => (
        //   "Section"
        // )
      },
      {
        columnName: "Row",
        columnKey: 'row'
        // render: () => (
        //   "Row"
        // )
      },
      {
        columnName: "Low Seat",
        columnKey: 'lowSeat'
        // render: () => (
        //   "Low Seat"
        // )
      },
      {
        columnName: "High Seat",
        columnKey: 'highSeat'
        // render: () => (
        //   "High Seat"
        // )
      },
      {
        columnName: "Season Code",
        columnKey: "seasonCode"
        // render: () => (
        //   "Season Code - 2022Rs"
        // )
      },
      {
        columnName: "Actions",
        render: (data) => (
          <small className="table-row-card-actions d-flex">
            <Button variant="link" className="table-action-button green_bg" onClick={() => onEdit(data)}>
              <img src={EditIcon} alt=" " />
            </Button>
            <Button variant="link" className="table-action-button" onClick={() => onDelete(data)}>
              <img src={DeleteIcon} alt=" " />
            </Button>
          </small>
        ),
        flexGrow: 0.5

      },
    ]
  );
};

export const poCol = (onEdit, onDelete, setModalVisibility, setSelectedRecordId, dispatch) => {
  return (
    [
      {
        columnName: "Purchase Order",
        sort: true,
        sortName: 'PurchaseOrderId',
        render: (data) => (
          <>
            <Link
              variant="link"
              className="ss-link"
              to={"/purchases/" + data.id}
            >
              {data.purchaseOrderId}
            </Link>
          </>
        ),
      },
      {
        columnName: "Date",
        sort: true,
        sortName: 'PurchaseOrderDate',
        render: (data) => (
          <>
            {data?.purchaseOrderDate ? formatDate(data.purchaseOrderDate) : ""}
          </>
        )
      },
      {
        columnName: "Description",
        sort: true,
        sortName: 'Description',
        render: (data) => <>{data.description}</>,
      },
      {
        columnName: "Total Scheduled",
        sort: true,
        sortName: "TotalScheduled",
        render: (data) => <>{formatMoney(data.totalScheduled)}</>,
      },
      {
        columnName: "Total Paid",
        render: (data) => <>{formatMoney(data.totalPaid)}</>,
      },
      {
        columnName: "Vendor",
        render: (data) => (
          <Link
            variant="link"
            className="ss-link"
            to={"/vendors/" + data.vendorId}
          >
            {data.vendorName}
            <br />
            {data.vendorId ? data?.vendorId : ""}
          </Link>
        ),
        sort: true,
        sortName: "VendorId",
        // columnKey: "address",
      },
      {
        columnName: "Account",
        sort: true,
        sortName: 'AccountId',
        render: (data) => (
          <> {data?.autoGenerate === false ? <Link
            variant="link"
            className="ss-link"
            to={"/accounts/" + data.accountId}
          >
            {data.accountName} <br />
            {data.accountId == 0 ? "" : data.accountId}
          </Link> : <span>{data.accountName} < br />
            {data.accountId == 0 ? "" : data.accountId}</span>}
          </>

        ),
      },

      {
        columnName: "SkyBox Ref",
        sort: true,
        sortName: "SkyBoxRef",
        render: (row, ind, open, setOpen, getPurchaseOrderRefsData, data) => (
          <div>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                if (open[ind]) {
                  setOpen(Array(data?.length).fill(false));
                } else {
                  const temp = Array(data?.length).fill(false);
                  temp[ind] = !temp[ind];
                  setOpen(temp);
                }
                if (!open[ind]) {
                  getPurchaseOrderRefsData(row?.id)
                }
              }}
            >
              <span style={{ fontSize: '14px' }}>{row.skyboxRefCount}</span>
              <img src={open[ind] ? Up : Down} style={{ marginLeft: '5px' }} alt="up down image" />
            </IconButton>
          </div>
        ),
      },
      {
        columnName: "SkyBox Value",
        render: (data) => (
          <>
            {formatMoney(data?.skyboxRefAmount)}
          </>
        ),
      },
      {
        columnName: "Matched",
        render: (data) => (
          <>
            <img src={((!isNaN(parseInt(data?.skyboxRefCount))) && parseInt(data?.skyboxRefCount) > 0) ? Done : Not} alt="" />
          </>
        ),
        flexGrow: 0.5

      },
      {
        columnName: "Actions",
        render: (data) => {
          return (
            <small className="table-row-card-actions d-flex" >
              {data.paymentPlanStatus === false && <Button variant="link" className="table-action-button" onClick={(e) => {
                setSelectedRecordId(data)
                setModalVisibility(true)
              }}>
                <img style={{ height: '100%', width: '10px' }} src={Dollar} alt="" />
              </Button>}
              <>
                <Button variant="link" className="table-action-button green_bg" onClick={() => {
                  dispatch(changePOEditState(true));
                  onEdit(data)
                }}>
                  <img style={{ height: '100%', width: '13px' }} src={EditIcon} alt=" " />
                </Button>
                {data?.autoGenerate === false &&
                  <Button variant="link" className="table-action-button" onClick={() => onDelete(data)}>
                    <img style={{ height: '100%', width: '13px' }} src={DeleteIcon} alt=" " />
                  </Button>}
              </>
            </small >
          )
        }
      },
    ])
}

const accountPaymentPlanCol = () => {
  return (
    [
      {
        columnName: "Purchase Order",
        render: (data) => (
          <>
            <Link
              variant="link"
              className="ss-link"
              to={"/purchases/" + data.purchaseOrdersId}
            >
              {data.purchaseOrderId}
            </Link>
          </>
        ),
      },
      {
        columnName: "Payment Plan Id",
        sort: true,
        sortName: "PaymentPlan Id",
        render: (data) => (
          <>
            {data?.paymentPlansId ? data?.paymentPlansId : '-'}
          </>
        )
      },
      {
        columnName: "Payment Date",
        sort: true,
        sortName: "PaymentDate",
        render: (data) => (
          <>
            {data?.paymentDate && formatDate(data?.paymentDate)}
          </>
        )
      },
      {
        columnName: "Payment",
        sort: true,
        sortName: "Amount",
        render: (data) => (
          <>
            {data?.amount && formatMoney(data?.amount)}
          </>
        )
      },
      {
        columnName: "Balance",
        render: (data) => (
          <>
            {formatMoney(data?.balance)}
          </>
        )
      },
      {
        columnName: "Matched",
        render: (data) => (
          <>
            <img src={data?.status ? Done : Not} />
          </>
        )
      },
    ]
  );
}


export { charges, rules, purchase, locations, accountPaymentPlanCol };