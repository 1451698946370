import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Copy from "../../../images/copy.svg";
import EyeIcon from "../../../images/view.svg";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import { getEventsTicketTokens } from "../../../data/reducers/events/events.reducers";

const RevokeEventModal = ({
  isVisible,
  handleClose,
  selectedRecord,
  setSelectedRecord,
  setShowConfirmationModalVisible,
  setShowRevokeEventModal,
  setRevokeTokenId,
  setShowTicketInformationModal,
}) => {
  const eventTickets = useSelector((state) => state.events).eventTickets;
  const dispatch = useDispatch();
  useEffect(() => {
    if (isVisible) {
      const data = {
        id: selectedRecord?.id,
        section: selectedRecord?.data?.section,
        row: selectedRecord?.data?.row,
      };

      dispatch(getEventsTicketTokens(data));
    }
  }, [isVisible]);

  const revokeCol = [
    {
      columnName: "Customer Name",
      render: (data) => <>{data?.customerName}</>,
    },
    {
      columnName: "Seats",
      render: (data) => (
        <>
          {data?.seats?.split("-")[0] === data?.seats?.split("-")[1]
            ? data?.seats?.split("-")[0]
            : data?.seats}
        </>
      ),
    },
    {
      columnName: "Barcode Link",
      render: (data) => (
        <>
          <Button
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(data?.tokenUrl);
              dispatch(successMessage("Copied !!!"));
            }}
          >
            <img src={Copy} alt="copy icon" style={{ marginRight: "12px" }} />
            Copy Barcode Link
          </Button>
        </>
      ),
    },
    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions" style={{ maxWidth: "65%" }}>
          <Button
            variant="link"
            className="table-action-button gray_bg"
            onClick={(e) => {
              e.preventDefault();
              setSelectedRecord({
                ...selectedRecord,
                eventTickets: eventTickets?.filter(
                  (x) => x?.tokenId == data?.tokenId
                ),
              });
              setShowRevokeEventModal(false);
              setShowTicketInformationModal(true);
            }}
          >
            <img
              src={EyeIcon}
              style={{
                marginLeft: "-35%",
                marginTop: "-30%",
                zoom: "130%",
              }}
              alt="Eye Icon"
            />
          </Button>
          <Button
            className="table-action-button red_bg"
            onClick={(e) => {
              e.preventDefault();
              setRevokeTokenId({
                soldInventoryId: data?.soldInventoryId,
                tokenId: data?.tokenId,
              });
              setShowRevokeEventModal(false);
              setShowConfirmationModalVisible(true);
            }}
          >
            <span style={{ fontWeight: "bold", color: "rgb(153, 0, 0)" }}>
              X
            </span>
          </Button>
        </small>
      ),
    },
  ];

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>EVENTS REVOKE</Modal.Title>
        </Modal.Header>
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              name: "Events",
              active: true,
            },
            {
              name: `Event - ${selectedRecord?.eventName}`,
              active: true,
            },
            {
              name: "Select Revoke",
              active: true,
            },
          ]}
        />

        <Modal.Body>
          <QuickTixTable
            noHeader={true}
            columns={revokeCol}
            data={eventTickets || []}
            renderRow={(rowData, index) => (
              <TableRowCard
                key={rowData?.id}
                columns={rowData?.nodata ? columnError : revokeCol}
                rowData={rowData}
              />
            )}
          />
        </Modal.Body>

        <div className="d-flex justify-content-center my-4">
          <Button
            variant="light"
            className="ss-modal-secondary-btn mx-2"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RevokeEventModal;
