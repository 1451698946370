import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../../shared/components/loader/Loader";
const SavePendingCorrectionModal = ({
  isVisible,
  handleVisibility,
  handleSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status

  const handleConfirmClick = () => {
    setIsLoading(true); // Set loading to true
    handleSubmit(null, true).finally(() => setIsLoading(false)); // Reset loading state after submit
  };
  return (
    <Modal
      show={isVisible}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleVisibility}
      backdrop={null}
    >
      <Modal.Body style={{ border: "2px solid #e3e8e8" }} className="rounded">
        <>
          {" "}
          <h4
            className="fw-bold text-center"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Confirm Resubmission for Review{" "}
          </h4>
          <p style={{ fontSize: "14px" }} className="text-center">
            Are you sure you want to save changes and resubmit this PO for
            review?
          </p>
          <div className="d-flex justify-content-between">
            <div
              className="justify-content-center rounded d-flex align-items-center  w-50 mx-2"
              style={{
                backgroundColor: "#0241A0",
                color: "white",
                height: "40px",
                cursor: "pointer",
              }}
              onClick={handleConfirmClick}
            >
              {isLoading ? <Loader /> : "Confirm & Submit"}{" "}
              {/* Show loader or text */}
            </div>
            <div
              className="w-50 justify-content-center rounded d-flex align-items-center mx-2 rounded"
              style={{
                border: "2px solid #0241A0",
                color: "#0241A0",
                height: "40px",
                cursor: "pointer",
              }}
              onClick={handleVisibility}
            >
              Cancel
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default SavePendingCorrectionModal;
