import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Loader from "../../../../../../shared/components/loader/Loader";
import { successMessage } from "../../../../../../data/reducers/alerts/alert.reducer";
import {
  setpendingPo,
  updateStatusPO,
  deletePO,
  getInvoiceFeeDropdown,
  setGlobalFeeInputs,
} from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import { useHistory } from "react-router-dom";
import DeleteIcon from "../../../../../../images/Remove.svg";
import "../../../../../../App.css";
import { setNotePendingPO } from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import { flushPurchaseOrders } from "../../../../../../data/reducers/purchaseOrders/cached-po.reducer";
import { flushPurchaseOrdersCards } from "../../../../../../data/reducers/purchaseOrders/cached-po-cards.reducer";
import RejectConfirmationModal from "../../../../../../shared/components/modals/RejectConfirmationModal";
import DeleteConfirmationModal from "../../../../../../shared/components/modals/DeleteConfirmationModal";
import { errorMessage } from "../../../../../../data/reducers/alerts/alert.reducer";
import { setAllPendingPOTab } from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import warninginfo from "../../../../../../images/warninginfo.svg";
import { IoInformationCircleOutline } from "react-icons/io5";
import { setIsPreview } from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import { Note } from "@mui/icons-material";
import { getInvoiceDetails } from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import { constants } from "../../../../../../config";

const AddNote = ({ id, setSteps }) => {
  const history = useHistory();
  const { allPurchaseOrderDetails } = useSelector(
    (state) => state.purchaseOrder
  );
  const { pendingPONote } = useSelector((state) => state.purchaseOrder);
  const isPreviewSkipped = useSelector(
    (state) => state.purchaseOrder.isPreviewSkipped
  );

  const dispatch = useDispatch();
  const { selectedPendingPOTab } = useSelector((state) => state.purchaseOrder);
  const [note, setNote] = useState(
    allPurchaseOrderDetails?.purchaseOrder?.statusNotes ?? ""
  );
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isUnderReview, setUnderReview] = useState(false); // State for under review
  const [isRejected, setRejected] = useState(false); // State for rejected PO
  const [isCorrection, setCorrection] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const { globalInvoiceType, globalFeeDropdown } = useSelector((state) => state.purchaseOrder);

  useEffect(() => {
    //to fetch PO details when Id changes
    dispatch(getInvoiceDetails(id));
  }, [id])

  useEffect(() => {
    const purchaseOrder = allPurchaseOrderDetails?.purchaseOrder;

    if (purchaseOrder && (purchaseOrder?.statusNotes || purchaseOrder?.statusNotes == '')) {
      setNote(purchaseOrder.statusNotes);
    }
  }, [id, allPurchaseOrderDetails]);


  useEffect(() => {
    setCurrentUserEmail(localStorage.getItem(constants.KEY_USER_EMAIL));
  }, []);

  const handleEdit = (id) => {
    dispatch(setIsPreview(true));
    history.push(`/purchase/add-invoice/${id}/0`);
    setSteps(0);
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote(newNote);
    dispatch(setNotePendingPO({ id: Number(id), note: newNote }));
  };

  useEffect(() => {
    const userRole = localStorage.getItem("USER_ROLE");
    const isAdminRole = userRole && userRole.trim() === "Admin";
    setAdmin(isAdminRole);
    const statusId = allPurchaseOrderDetails?.purchaseOrder?.statusId;

    if (statusId === 1) {
      setUnderReview(true);
      setRejected(false);
      setCorrection(false);
    } else if (statusId === 2) {
      setRejected(true);
      setUnderReview(false);
      setCorrection(false);
    } else if (statusId === 3) {
      setCorrection(true);
      setUnderReview(false);
      setRejected(false);
    } else {
      setUnderReview(false);
      setRejected(false);
      setCorrection(false);
    }
  }, [selectedPendingPOTab, allPurchaseOrderDetails]);

  const resetHandler = async () => {
    dispatch(flushPurchaseOrders());
    dispatch(flushPurchaseOrdersCards());
  };

  const handleApprove = () => {
    dispatch(setIsPreview(true));
    setLoadingButton("approve");
    const data = {
      id: [Number(id)],
      statusId: 4,
      statusNotes: note,
    };

    dispatch(updateStatusPO(data))
      .then(() => {
        dispatch(
          successMessage(
            `${allPurchaseOrderDetails.purchaseOrder.mainInvoiceType} purchase order has been approved successfully!`
          )
        );
        resetHandler();
        history.push("/pendingPO");
      })
      .catch((error) => {
        console.error(
          "Error updating purchase orders:",
          error.response ? error.response.data : error
        );
      })
      .finally(() => {
        setLoadingButton(null);
      });
  };

  const handleRestore = () => {
    setLoadingButton("restore");
    const data = {
      id: [Number(id)],
      statusId: 1,
      statusNotes: note,
    };

    dispatch(updateStatusPO(data))
      .then(() => {
        dispatch(
          successMessage(
            `${allPurchaseOrderDetails.purchaseOrder.mainInvoiceType} purchase order has been restored successfully!`
          )
        );
        dispatch(setAllPendingPOTab(0));
        resetHandler();
        history.push("/pendingPO");
      })
      .catch((error) => {
        console.error(
          "Error updating purchase orders:",
          error.response ? error.response.data : error
        );
      })
      .finally(() => {
        setLoadingButton(null);
      });
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const onDeletePurchaseOrder = async (id) => {
    setLoadingButton("delete");
    try {
      const response = await dispatch(deletePO(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteModal(false);
        resetHandler();
        history.push("/pendingPO");
        dispatch(successMessage("Purchase Order was deleted successfully"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setLoadingButton(null);
      setShowRejectModal(false);
    }
  };

  const handleReject = () => {
    setShowRejectModal(true);
  };


  const handleRejectConfirmation = () => {
    setLoadingButton("reject");
    const data = {
      id: [Number(id)],
      statusId: 2,
      statusNotes: note,
    };

    dispatch(updateStatusPO(data))
      .then(() => {
        dispatch(
          successMessage(
            `${allPurchaseOrderDetails?.purchaseOrder?.mainInvoiceType} purchase order has been rejected successfully!`
          )
        );
        resetHandler();
        history.push("/pendingPO");
      })
      .catch((error) => {
        console.error(
          "Error updating purchase orders:",
          error.response ? error.response.data : error
        );
      })
      .finally(() => {
        setLoadingButton(null);
        setShowRejectModal(false);
      });
  };

  const handleRequest = () => {
    setLoadingButton("request");
    const data = {
      id: [Number(id)],
      statusId: 3,
      statusNotes: note,
    };

    dispatch(updateStatusPO(data))
      .then(() => {
        dispatch(
          successMessage(
            `The changes for the ${allPurchaseOrderDetails.purchaseOrder.mainInvoiceType} purchase order have been requested.`
          )
        );
        resetHandler();
        history.push("/pendingPO");
      })
      .catch((error) => {
        console.error(
          "Error updating purchase orders:",
          error.response ? error.response.data : error
        );
      })
      .finally(() => {
        setLoadingButton(null);
      });
  };

  const handleCancel = () => {
    history.push("/pendingPO");
    dispatch(setIsPreview(true));
  };
  const [isNoteVisible, setIsNoteVisible] = useState(true);

  const toggleNoteVisibility = () => {
    setIsNoteVisible((prev) => !prev);
  };

  return (
    <div className="w-full p-4 bg-white">
      {/* {isUnderReview && !isAdmin && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "14px",
            borderRadius: "4px",
            backgroundColor: "#ECF5FF", // Default background color
            marginBottom: "10px",
          }}
        >
          <span className="mx-2"></span>
          <IoInformationCircleOutline
            style={{ color: "#0241A0", marginRight: "8px" }}
            size={20}
          />
          <span style={{ color: "#404040", fontSize: "12px" }}>
            <span>
              This Purchase Order is currently under review. You can view the
              details, but edits are disabled until any requested changes are
              made by the reviewer.
            </span>
          </span>
        </div>
      )} */}

      {isRejected && !isAdmin && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "14px",
            borderRadius: "4px",
            backgroundColor: "#ECF5FF", // Default background color
            marginBottom: "10px",
          }}
        >
          <IoInformationCircleOutline
            style={{ color: "#0241A0", marginRight: "8px" }}
            size={20}
          />
          <span style={{ color: "#404040", fontSize: "12px" }}>
            This PO has been rejected. Please wait for the admin to restore it
            before making any changes.
          </span>
        </div>
      )}
      {isNoteVisible &&
        (isAdmin || (!isAdmin && !isUnderReview)) && (
          <>
            <h2 style={{ fontSize: "14px" }} className="font-bold">
              {/* if the user is admin and the current user has not created the current purchase Order then only we will show the Add Note text  */}
              {isAdmin && currentUserEmail != allPurchaseOrderDetails?.purchaseOrder?.createdBy ? "Add Note" : "Note"}
            </h2>

            <textarea
              placeholder={isAdmin && currentUserEmail != allPurchaseOrderDetails?.purchaseOrder?.createdBy ? "Type your notes..." : ""}
              value={note}
              onChange={handleNoteChange}
              style={{
                border: isAdmin && currentUserEmail != allPurchaseOrderDetails?.purchaseOrder?.createdBy ? "1px solid #404040" : "1px solid #e3e3e3",
                background: "transparent",
                padding: "10px",
                fontSize: "0.8rem",
                color: "#404040",
                width: "100%",
                resize: "none",
                outline: "none",
                fontFamily: "inherit",
                cursor: isUnderReview && !isAdmin ? "default" : "text",
              }}
              readOnly={!isAdmin || currentUserEmail == allPurchaseOrderDetails?.purchaseOrder?.createdBy}
            />
          </>
        )}

      <div className="d-flex justify-content-between align-items-center">
        {(!isAdmin && !isPreviewSkipped) || (!isPreviewSkipped && isAdmin && currentUserEmail == allPurchaseOrderDetails?.purchaseOrder?.createdBy) ? (
          <>
            {!isUnderReview && !isRejected && (
              <Button
                onClick={toggleNoteVisibility}
                className="btn border-0 bg-transparent text-primary"
              >
                {isNoteVisible ? "Hide Note" : "Show Note"}
              </Button>
            )}
            <div className="ms-auto d-flex">
              <Button
                onClick={handleCancel}
                className="btn border-0 me-2 bg-transparent text-primary"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="mx-2"
                disabled={(isRejected)}
                onClick={() => handleEdit(id)}
              >
                Edit Purchase Order
              </Button>
            </div>
          </>
        ) : null}
      </div>

      {/* if the current user has the same email as the purchaseOrder which is being opened then we are not gonna show the approve, request changes and reject button */}
      {currentUserEmail != allPurchaseOrderDetails?.purchaseOrder?.createdBy ? < div className="d-flex justify-content-end  mt-3">
        {/* Assuming isAdmin is a boolean indicating admin presence */}

        {/* {!isAdmin && isPreviewSkipped ? (
          <div className="d-flex align-items-end mt-3">
            <Button
              onClick={handleCancel}
              className="btn border-0 me-2 bg-transparent text-primary"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => handleEdit(id)}
            >
              Edit Purchase Order
            </Button>
          </div>
        ) : null} */}

        <div className="d-flex justify-content-end  mt-3">
          {/* Assuming isAdmin is a boolean indicating admin presence */}

          {!isAdmin ? null : (
            <>
              {selectedPendingPOTab === 0 || selectedPendingPOTab === 1 ? (
                <>
                  <Button
                    variant="primary"
                    onClick={handleCancel}
                    className="mx-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-2 ss-light-button"
                    onClick={handleReject}
                  >
                    <span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#FF0000",
                          fontWeight: "500",
                        }}
                      >
                        {loadingButton === "reject" ? (
                          <Loader size="sm" />
                        ) : (
                          "Reject"
                        )}
                      </div>
                    </span>
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-2 ss-light-button"
                    onClick={handleRequest}
                    disabled={selectedPendingPOTab == 1} // disabling the button for the tab index 1, which is pending corrections. 
                  >
                    <span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "500",
                        }}
                      >
                        {loadingButton === "request" ? (
                          <Loader size="sm" />
                        ) : (
                          "Request Changes"
                        )}
                      </div>
                    </span>
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={handleApprove}
                    disabled={loadingButton === "approve" || allPurchaseOrderDetails?.purchaseOrder?.createdBy == currentUserEmail}
                  >
                    <span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "500",
                          color: "#FFFFFF",
                        }}
                      >
                        {loadingButton === "approve" ? <Loader /> : "Approve"}
                      </div>
                    </span>
                  </Button>

                  <RejectConfirmationModal
                    text={`Are you sure you want to reject '${allPurchaseOrderDetails?.purchaseOrder?.mainInvoiceType}' Purchase Order?`}
                    onConfirmation={handleRejectConfirmation}
                    isVisible={showRejectModal}
                    handleClose={() => setShowRejectModal(false)}
                    loading={loadingButton === "reject"}
                  />
                </>
              ) : selectedPendingPOTab === 2 ? (
                <>
                  <div className="d-flex justify-content-end align-items-center">
                    <Button
                      variant="primary"
                      onClick={handleCancel}
                      className="mx-2"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      style={{ textWrap: "nowrap" }}
                      className="mx-2 ss-light-button"
                      onClick={handleDelete}
                    >
                      <img
                        className="img-button"
                        style={{
                          height: "100%",
                          width: "16px",
                          marginRight: "4px",
                        }}
                        src={DeleteIcon}
                        alt="Delete Purchase Orders"
                      />
                      Delete
                    </Button>
                    <Button
                      variant="primary"
                      className="mx-2 "
                      onClick={handleRestore}
                    >
                      <span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "500",
                            color: "#FFFFFF",
                          }}
                        >
                          {loadingButton === "restore" ? (
                            <Loader size="sm" />
                          ) : (
                            "Restore"
                          )}
                        </div>
                      </span>
                    </Button>
                    <DeleteConfirmationModal
                      text={`Are you sure you want to delete '${allPurchaseOrderDetails?.purchaseOrder?.mainInvoiceType}' Purchase Order?`}
                      onConfirmation={() => onDeletePurchaseOrder(id)}
                      isVisible={showDeleteModal}
                      handleClose={() => setDeleteModal(false)}
                      loading={loadingButton === "delete"}
                    />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
        : null}
    </div >
  );
};

export default AddNote;
