import React, { useEffect, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { mixpanel_contants } from "../../mixpanel";
import FilterPanel2024 from "../../shared/components/panels/filter-panel/FilterPanel2024";
import {
  getAccountsList,
  getAccountSportList,
  getAccountTeamList,
} from "../../data/reducers/accounts/accounts.reducers";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";

const AccountsFilterPanel = ({
  filters,
  setFilters,
  resetHandler,
  error,
  cardError,
}) => {
  const dispatch = useDispatch();

  const { sportList, teamList, accountsList } = useSelector(
    (state) => state.accounts
  );

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );

  useEffect(() => {
    if (!accountsList.length) {
      dispatch(getAccountsList());
    }
  }, [accountsList]);

  useEffect(() => {
    if (!vendorsListForDropdown?.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  useEffect(() => {
    if (!sportList.length) {
      dispatch(getAccountSportList());
    }
  }, [sportList]);

  useEffect(() => {
    if (!teamList.length) {
      dispatch(getAccountTeamList());
    }
  }, [teamList]);

  const defaultValues = useMemo(() => {
    return Object.entries(filters).map(([key, value]) => ({ key, value }));
  }, [filters]);

  //style for dropdown
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  return (
    <>
      <FilterPanel2024
        defaultValues={defaultValues}
        filtersCount={
          Object.entries(filters).filter(
            ([key, value]) =>
              value !== "" &&
              key !== "categoryId" &&
              key !== "searchString" &&
              key !== "PageNumber" &&
              key !== "PageSize" &&
              key !== "SortDir" &&
              key !== "SortField"
          ).length
        }
        searchPlaceholder="Search for name, address, vendor, account name or account number..."
        dashboard={mixpanel_contants.ACCOUNT}
        onReset={() => {
          setFilters({
            ...filters,
            PageNumber: 1,
            searchString: "",
            Name: "",
            Email: "",
            TeamId: "",
            SportId: "",
            Address: "",
            VendorId: "",
            AccountId: "",
          });
          if (error || cardError) {
            resetHandler();
          }
        }}
        onSearch={(data, filterPanelVisible) => {
          setFilters({
            ...filters,
            PageNumber: 1,
            searchString: filterPanelVisible ? "" : data.searchString,
            Name: filterPanelVisible ? data.Name : "",
            Email: filterPanelVisible ? data.Email : "",
            TeamId: filterPanelVisible ? data.TeamId : "",
            SportId: filterPanelVisible ? data.SportId : "",
            VendorId: filterPanelVisible ? data.VendorId : "",
            Address: filterPanelVisible ? data.Address : "",
            AccountId: filterPanelVisible ? data.AccountId : "",
          });
        }}
        renderAdvancedPanel={(
          register,
          handelSubmit,
          errros,
          control,
          closePanel,
          getValues
        ) => (
          <Row>
            <Col>
              <Form.Group as={Col}>
                <Form.Label sm="4">Vendor</Form.Label>
                <Col sm="11">
                  <Controller
                    name="VendorId"
                    control={control}
                    render={({ field }) => {
                      const value = vendorsListForDropdown?.find(
                        (v) => v.id == field.value
                      );
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e.id)}
                          styles={customStyles}
                          options={vendorsListForDropdown}
                          getOptionLabel={(option) =>
                            `${option.id}  ${option.name}`
                          }
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="pt-2">
                <Form.Label sm="4">Account</Form.Label>
                <Col sm="11">
                  <Controller
                    name="AccountId"
                    control={control}
                    render={({ field }) => {
                      const value = accountsList.find(
                        (v) => v.accountId == field.value
                      );

                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e?.accountId)}
                          styles={customStyles}
                          options={accountsList}
                          getOptionLabel={(option) =>
                            `${option.accountId}  ${option.name}`
                          }
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Name
                </Form.Label>
                <Col sm="8">
                  <Form.Control placeholder="Name" {...register("Name")} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pt-3">
                <Form.Label column sm="4">
                  Email
                </Form.Label>
                <Col sm="8">
                  <Form.Control placeholder="Email" {...register("Email")} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pt-3">
                <Form.Label style={{ whiteSpace: "nowrap" }} column sm="4">
                  Address
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    {...register("Address")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}></Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Team
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="TeamId"
                    control={control}
                    render={({ field }) => {
                      const value = teamList.find((v) => v.id == field.value);
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e.id)}
                          styles={customStyles}
                          options={teamList}
                          getOptionLabel={(option) => `${option.teamName}`}
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="pt-3">
                <Form.Label column sm="4">
                  Sport
                </Form.Label>
                <Col sm="8">
                  <Controller
                    name="SportId"
                    control={control}
                    render={({ field }) => {
                      const value = sportList.find((v) => v.id == field.value);
                      return (
                        <Select
                          value={value === undefined ? null : value}
                          onChange={(e) => field.onChange(e.id)}
                          styles={customStyles}
                          // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                          options={sportList?.filter((x) => x.id > 0)}
                          getOptionLabel={(option) => `${option.sportName}`}
                        />
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        )}
      />
    </>
  );
};

export default React.memo(AccountsFilterPanel);
