export const formatMoney = (value, format = "en-US", currency = "USD") => {
  var formatter = new Intl.NumberFormat(format, {
    style: "currency",
    currency,
  });

  if (isNaN(value)) {
    return formatter.format(0);
  }

  return formatter.format(value);
};

export const formatNumberToFourDigits = (number, divider = "-") => {
  const givenNumber = String(number);
  const result = givenNumber.match(/.{1,4}/g) ?? [];
  return result.join(divider);
};

export const formatMobileNumber = (num) => {
  if (!num) {
    return "-";
  }
  const length = num?.length;
  const mbNum = num?.slice(length - 10);
  const formattedMbNO = mbNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  return formattedMbNO;
};
export const formatDollarMoney = (integerPrice) => {
  if (typeof integerPrice === "string") {
    let cleanedString = integerPrice.replace(/^USD\s*/, "");

    cleanedString = cleanedString.replace(/,/g, "");
    cleanedString = cleanedString.replace("$", "");

    integerPrice = parseFloat(cleanedString);
  }
  return integerPrice;
};

export const extractNumber = (text) => {
  const trimmedText = text.trim();
  const match = trimmedText.match(/[\d.]+/);
  return match ? parseFloat(match[0]) : 0;
};

export const isDigitsOnly = (str) => {
  return /^\d+$/.test(str);
};

export const formatMoneyWithCommas = (value) => {
  if ((isNaN(value) || !value) && value!=0) return "";
  // Convert to string if it's a number
  const stringValue = typeof value === "number" ? value.toString() : value;

  const [integer, decimal] = stringValue.split(".");
  const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimal !== undefined ? `${formattedInteger}.${decimal.slice(0, 2)}` : formattedInteger;
};


// Function to remove commas from the number
export const removeCommas = (value) => {
  return value.replace(/,/g, "");
};


// Handle input change
export const handleMoneyChangeWithCommas = (e) => {
  const rawValue = e.target.value; // Value with commas
  const numericValue = removeCommas(rawValue); // Remove commas
  if (/^\d*\.?\d*$/.test(numericValue)) {
    return numericValue;
  }
  return '';
};

export const cleanPhoneNumber = (input) => {
  // Convert input to a string and remove non-numeric characters
  let sanitized = input.toString().replace(/[^\d]/g, "");

  // If the sanitized number starts with "1" and is longer than 10 digits, remove the "1"
  if (sanitized.startsWith("1") && sanitized.length > 10) {
    sanitized = sanitized.slice(1);
  }

  return sanitized;
}

// function formatPhoneNumber(input) {
//   // Remove all non-numeric characters
//   const cleaned = input.toString().replace(/[^\d]/g, "");

//   // Ensure the number has at least 10 digits
//   if (cleaned.length < 10) {
//     return "Invalid phone number";
//   }
//   const areaCode = cleaned.slice(-10, -7); // Last 10 digits, first 3 are area code
//   const centralOfficeCode = cleaned.slice(-7, -4); // Next 3 digits
//   const lineNumber = cleaned.slice(-4); // Last 4 digits

//   // Format the phone number
//   return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
// }

export const formatPhoneNumber = (value) => {
  if (!value) {
    return "";
  }

  let cleaned = value?.replace(/\D/g, ""); // Remove non-digit characters

  // Check if the number starts with +1 and remove it if present
  if (cleaned.startsWith("1")) {
    cleaned = cleaned.slice(1);  // Remove the leading '1'
  }

  // Format based on the cleaned length
  if (cleaned.length <= 3) {
    return `+1 ${cleaned}`;  // Start formatting after 3 digits
  } else if (cleaned.length <= 6) {
    return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;  // Format after 6 digits
  } else {
    return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;  // Full format when 10 digits entered
  }
}
export const handleMoneyChangeWithCommansForValue = (value) => {
  const rawValue = value; // Value with commas
  const numericValue = removeCommas(rawValue); // Remove commas
  if (/^\d*\.?\d*$/.test(numericValue)) {
    return numericValue;
  }
  return '';
};



