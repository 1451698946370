import { SportsAndTeamsServices } from "../../services/sports&teams/sports&teams.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";

export const getAllTeams = createAsyncThunk(
  "accounts/getAllTeams",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.getAllTeams(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllSports = createAsyncThunk(
  "accounts/getAllSports",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.getAllSports(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const createTeam = createAsyncThunk(
  "accounts/createTeam",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.createTeam(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const createSport = createAsyncThunk(
  "accounts/createSport",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.createSport(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "accounts/UpdateTeam",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.updateTeam(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteTeam = createAsyncThunk(
  "accounts/deleteTeam",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.deleteTeam(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateSport = createAsyncThunk(
  "accounts/UpdateSport",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.updateSport(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const filterTeams = createAsyncThunk(
  "accounts/filterTeams",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.filterTeams(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVTeams = createAsyncThunk(
  "accounts/exportCSVTeams",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.exportCSVTeams(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Teams.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPDFTeams = createAsyncThunk(
  "accounts/exportPDFTeams",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.exportPdfTeams(payload);
    // if (response.isSuccessful === true) {
    //     // fileDownload(response.data, "Teams.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const exportSelectedAsCSVTeams = createAsyncThunk(
  "accounts/exportSelectedAsCSVTeams",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.exportSelectedAsCSVTeams(
      payload
    );
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "TeamsFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsPdfTeams = createAsyncThunk(
  "accounts/exportSelectedAsPdfTeams",
  async (payload, thunkAPI) => {
    const response = await SportsAndTeamsServices.exportSelectedAsPdfTeams(
      payload
    );
    // if (response.isSuccessful === true) {
    //     // fileDownload(response.data, "TeamsFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

const seasonSlice = createSlice({
  name: "sportsAndTeams",
  initialState: {
    sports: [],
    allTeams: [],
    allSports: [],
    paging: {},
    newlyCreated: {
      team: "",
      sport: "",
    },
  },
  extraReducers: {
    [getAllTeams.fulfilled]: (state, action) => {
      state.allTeams = action.payload.data;
      state.teamsPaging = action.payload.paging;
    },
    [getAllSports.fulfilled]: (state, action) => {
      state.allSports = action.payload.data;
      state.sportsPaging = action.payload.paging;
    },
    [filterTeams.fulfilled]: (state, action) => {
      state.allTeams = action.payload.data;
      state.teamsPaging = action.payload.paging;
    },
    [createSport.fulfilled]: (state, action) => {
      state.allSports.push(action.payload);
      state.newlyCreated.sport = action.payload;
    },
    [createTeam.fulfilled]: (state, action) => {
      state.allTeams.push(action.payload);
      state.newlyCreated.team = action.payload;
    },
  },
});

export const { removeSelected } = seasonSlice.actions;

export default seasonSlice.reducer;
