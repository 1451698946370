import ModalBreadcrumb from "../../../../shared/components/modals/ModalBreadcrumb";

import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { withLoader } from "../../../../utils/hoc/withLoader";
import { getLinkToken, getAccessToken } from "../../../../data/reducers/plaid/plaid.reducer";
import {
	PlaidLink,

} from "react-plaid-link";
import { useCallback } from "react";
import { errorMessage } from "../../../../data/reducers/alerts/alert.reducer";

const AddEditPaypalModal = ({ setBusy, isVisible, handleClose, onSubmit, selectedRecord }) => {
	const dispatch = useDispatch();
	const [token, setToken] = useState(null);
	const [accessToken, setAccessToken] = useState(null);
	const [data, setData] = useState(null);

	useEffect(() => {
		async function createLinkToken() {
			const response = await dispatch(getLinkToken());
			setToken(response.payload.data.link_token);
		}
		createLinkToken();
	}, []);


	// setup react hook form
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		control
	} = useForm();

	const [isEdit, setIsEdit] = useState(false);
	useEffect(() => {
		reset();
		setData(null)
	}, [isVisible]);


	//Handling Reset Button for moda;
	const handleReset = () => {
		for (const [key, value] of Object.entries(selectedRecord)) {
			setValue(key, value, {
				shouldValidate: true,
				shouldDirty: true,
			});
		}
		setData(null)
	};
	const onSuccess = useCallback(
		async (public_token, metadata) => {
			// send public_token to server
			const response = await dispatch(getAccessToken({ publicToken: public_token }))
			setAccessToken(response.payload.data.access_token)
		},
		[]
	);

	//handling clear button for modal
	const handleClear = () => {
		reset();
		setData(null)
	}
	const getData = async (data) => {
		setData(data)
	}

	useEffect(() => {
		if (selectedRecord) {
			setIsEdit(true);

			for (const [key, value] of Object.entries(selectedRecord)) {
				setValue(key, value, {
					shouldValidate: true,
					shouldDirty: true,
				});
			}
		} else {
			setData(null)
			setAccessToken(null);
			setIsEdit(false)
		}
	}, [selectedRecord, isVisible])

	useEffect(() => {
		if (data && accessToken) {
			onSubmit(data, accessToken)
		}
	}, [data, accessToken]);

	return (
		<>
			<Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
				<Form onSubmit={handleSubmit(getData)}>
					<Modal.Header closeButton>
						<Modal.Title>Fund Sources</Modal.Title>
					</Modal.Header>
					<ModalBreadcrumb
						breadcrumbLinks={[
							{
								name: "Fund Sources",
								active: true,
							},
							{
								to: "/fundSources/paypal-accounts",
								name: "Paypal Accounts",
								active: false,
							},
							{
								name: isEdit ? "Edit Account" : "Add Account",
								active: true,
							}
						]}
					/>
					<Modal.Body>
						<div className="container-fluid p-2 w-100 h-100">
							<Row>
								<p className="title-accounts">Basic Information</p>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm="4">
											Name
										</Form.Label>
										<Col sm="7">
											<Form.Control
												isInvalid={!!errors.name}
												{...register("name", { required: true })}
												placeholder="First Name"
											/>
											<Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm="4">
											Institution
										</Form.Label>
										<Col sm="7">
											<Form.Control
												isInvalid={!!errors.institution}
												{...register("institution", { required: true })}
												placeholder="Institution"
											/>
											<Form.Control.Feedback type="invalid"> Institution is required.</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>
							</Row>
							<hr />
							<Row>
								<p className="title-accounts">Item Details</p>
								<Col>
									<Form.Group as={Row}>
										<Form.Label column sm="4">
											ID
										</Form.Label>
										<Col sm="7">
											<Form.Control
												isInvalid={!!errors.itemId}
												{...register("itemId", { required: true })}
												placeholder="ID"
											/>
											<Form.Control.Feedback type="invalid">ID is required.</Form.Control.Feedback>
										</Col>
									</Form.Group>
								</Col>
								<Col />
							</Row>


						</div>
					</Modal.Body>
					<div className="d-flex justify-content-center my-4">
						{token === null ? (
							<div className=""></div>
						) : (
							<>
								{data == null && <Button type="submit" className="p-0 m-0 ss-modal-primary-btn text-white" variant="primary">
									Add Credential
								</Button>}
								{data && !errors.institution && !errors.name && !errors.itemId ?
									<Button className="p-0 m-0 ss-modal-primary-btn text-white" variant="primary">
										<PlaidLink
											token={token}
											className="px-2 py-0 m-0 bg-primary w-100 h-100 text-white"
											onSuccess={onSuccess}
										>
											Navigate to Plaid
										</PlaidLink>
									</Button> : (<span className="text-white">
									</span>)}
							</>
						)}
						<Button variant="light" onClick={() => isEdit ? handleReset() : handleClear()} className="ss-modal-secondary-btn mx-2">
							{isEdit ? "Reset" : "Clear"}
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default withLoader(AddEditPaypalModal);
