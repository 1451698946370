import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authServices } from "../../services/authentication/auth.service";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (payload, thunkAPI) => {
    const response = await authServices.loginAuthentication(
      payload.username,
      payload.password,
      payload.remember
    );
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload, thunkAPI) => {
    const response = await authServices.sendTempPass(payload);
    return response;
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (payload, thunkAPI) => {
    const response = await authServices.changePassword(payload);
    return response;
  }
);
export const checkLogin = createAsyncThunk(
  "auth/checkLogin",
  async (payload, thunkAPI) => {
    const loginInfo = authServices.checkLogin();
    if (loginInfo.loggedIn) {
      return loginInfo;
    } else {
      thunkAPI.rejectWithValue("Unauthorized. Please login.");
      throw new Error("Unauthorized. Please login.");
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, thunkAPI) => {
    authServices.logoutUser();
    return true;
  }
);

export const googleLoginUser = createAsyncThunk(
  "auth/googleLoginUser",
  async (payload, thunkAPI) => {
    const response = await authServices.googleAuthLogin(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const googleRefreshToken = createAsyncThunk(
  "auth/googleRefreshToken",
  async (payload, thunkAPI) => {
    const response = await authServices.googleRefreshToken(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {},
  extraReducers: {
    [loginUser.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.userName = action.payload.userName;
      state.role = action.payload.role;
    },
    [loginUser.rejected]: (state, action) => {
      state = {};
    },
    [checkLogin.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.userName = action.payload.userName;
      state.role = action.payload.role;
    },
    [logoutUser.fulfilled]: (state, action) => {
      state.loggedIn = false;
    },
    [googleLoginUser.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.role = action.payload.role;
    },
    [googleRefreshToken.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.role = action.payload.role;
    },
  },
});

export default authSlice.reducer;
