import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";


const transactionCols = (onSelect, selectId) => {
    return (
        [{
            columnName: "",
            flexGrow: 0.5,
            render: (data) => (
                <>
                    <input
                        type="radio"
                        name="matched"
                        checked={selectId?.id == data.id ? true : false}
                        onClick={() => onSelect(data)}
                    />
                </>
            )
        },
        {
            columnName: "Date",
            render: (data) => (
                <>
                    {formatDate(data?.dateAndTime)}
                </>
            )
        },
        {
            columnName: "Vendor",
            render: (data) => (
                <Link
                    variant="link"
                    className="ss-link"
                    to={"/vendors/" + data.vendorId}
                >
                    {data.vendorName}
                    <br />
                    {data.vendorId ? data?.vendorId : ""}
                </Link>
            ),
            sort: true,
            sortName: "VendorId",
        },
        {
            columnName: "Account",
            sort: true,
            sortName: 'AccountId',
            render: (data) => (
                <Link
                    variant='link'
                    className='ss-link'
                    to={'/accounts/' + data.accountId}>
                    {data.accountName}<br />
                    {data.accountId == 0 ? "" : data.accountId}
                </Link>
            ),
        },
        {
            columnName: "Description",
            columnKey: "description", render: (data) => (
                <>
                    {data.description}
                </>
            )
        },
        {
            columnName: "Amount",
            render: (data) => (
                <>
                    {formatMoney(data.amount)}
                </>
            )
        },
        ]
    );
};

export { transactionCols };