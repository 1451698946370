import { buildUrlWithParams } from "../api";
import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  getCSV,
  getPDF,
} from "../services.common";

const runRules = async () => {
  const url = `${api}CardInfo/runrules`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getRecordsToMatch = async (data) => {
  const url = `${api}CardInfo/reviewandmatchedlist?id=${data.id}&update=${data.update}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const unmatchTransactions = async (data) => {
  const url = `${api}CardInfo/matchingtrancation?id=${
    data.trans?.id
  }&update=${false}&PaymentId=1&purchaseId=0`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const matchTransactions = async (data) => {
  const url = `${api}CardInfo/matchingtrancation?id=${
    data.trans?.id
  }&update=${true}&PaymentId=${data.select?.paymentPlanId}&purchaseId=${
    data.select?.purchaseId
  }`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportCSVTransactions = async (data) => {
  const url = `${api}Charges/export-transactions/csv?month=${data.month}&isReviewed=${data.isReviewed}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const uploadTemplateTrans = async (payload) => {
  const url = `${api}CardInfo/importtransactionstemplatecsv`;
  const authHeader = getAuthHeaders();
  return await post(url, payload, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};

const downloadTemplateTrans = async () => {
  const url = `${api}CardInfo/transactionstemplatecsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const monthDropdown = async () => {
  const url = `${api}Values/get-month-range`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const modalSearch = async (data) => {
  const url = `${api}CardInfo/searchreviewandmatchedtrancation?searchString=${
    data.searchText || ""
  }&id=${data.id || ""}&update=${data?.update}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const exportSelectedCSVTrans = async (data) => {
  const url = buildUrlWithParams(`${api}Charges/export-transactions/csv`, data);
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfTrans = async (data) => {
  const url = `${api}CardInfo/exportpdf`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "Cards.pdf");
};

const exportSelectedPdfTrans = async (data) => {
  const url = buildUrlWithParams(`${api}Charges/export-transactions/pdf`, data);
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "TransactionsFilter.pdf");
};

const exportPdfTransactions = async (data) => {
  const url = `${api}Charges/export-transactions/pdf?month=${data.month}&isReviewed=${data.isReviewed}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "Transactions.pdf");
};

const resetAccount = async (data) => {
  const url = `${api}CardInfo/resetaccount/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const resetVendor = async (data) => {
  const url = `${api}CardInfo/resetvendor/${data.id}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

export const TransactionsServices = {
  exportPdfTrans,
  exportSelectedPdfTrans,
  exportPdfTransactions,
  getRecordsToMatch,
  unmatchTransactions,
  matchTransactions,
  exportCSVTransactions,
  monthDropdown,
  exportSelectedCSVTrans,
  modalSearch,
  runRules,
  uploadTemplateTrans,
  downloadTemplateTrans,
  resetVendor,
  resetAccount,
};
