import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const StoreDetails = createAsyncThunk("newInvoice/StoreDetails", async (payload) => {
     return payload
});


export const ClearDetails=createAsyncThunk("newInvoice/ClearDetails",async(payload)=>{
      return payload
})

const newInvoice = createSlice({
    name: "newInvoice",
    initialState: {
        InvoiceDetails: {}
    },
    extraReducers: {
        [StoreDetails.fulfilled]: (state, action) => {
            state.InvoiceDetails = {...state.InvoiceDetails,...action.payload}
        }
        
    }
});

export default newInvoice.reducer;