import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const WarningModal = ({ isVisible, type, handleVisibility, handleSubmit, handleChangeInvoice, hanldeSubmitchangeInvoice, selectedInvoice, selectedNumber, selectedOption1, selectedOption2 }) => {
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const [prevSelected, setPrevSelected] = useState(selectedInvoice);
  const [current, setCurrent] = useState(selectedNumber);
  useEffect(() => {
    if (selectedInvoice == 3) {
      setPrevSelected("Deposit")
    }
    else if (selectedInvoice == 4) {
      setPrevSelected("Group Purchase")
    }
    else if (selectedInvoice == 5) {
      setPrevSelected("PSL")
    }
    else if (selectedInvoice == 6) {
      setPrevSelected("Ticket Associates")
    }
    else if (selectedOption1) {
      setPrevSelected("Full Season")
    }
    else if (selectedOption2) {
      setPrevSelected("Post Season")
    }

  }, [selectedInvoice])
  useEffect(() => {
    if (selectedNumber == 3) {
      setCurrent("Deposit")
    }
    else if (selectedNumber == 4) {
      setCurrent("Group Purchase")
    }
    else if (selectedNumber == 5) {
      setCurrent("PSL")
    }
    else if (selectedNumber == 6) {
      setCurrent("Ticket Associates")
    }
    else if (selectedOption1) {
      setPrevSelected("Full Season")
    }
    else if (selectedOption2) {
      setPrevSelected("Full Season")
    }

  }, [selectedNumber])

  return (
    <Modal
      show={isVisible}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleVisibility}
      backdrop={null}

    >
      <Modal.Body style={{ border: '2px solid #e3e8e8' }} className="rounded">
        {type === "invoice" && (
          <>
            {" "}
            <h4 className="fw-bold" style={{ fontSize: "20px", fontWeight: "600" }} >Change Invoice Type?</h4>

            {prevSelected != -1 ?
              <p className="text-center" style={{ fontSize: "14px" }}>
                Are you sure you want to change the invoice type from {prevSelected} to {current} ? Changing the invoice type will remove
                all the details you have entered so far.
              </p>
              :
              <p className="text-center" style={{ fontSize: "14px" }}>
                Are you sure you want to change the invoice type to {current} ? Changing the invoice type will remove
                all the details you have entered so far.
              </p>
            }
            <div className="d-flex justify-content-between">
              <div className="justify-content-center rounded d-flex align-items-center  w-50 mx-2" style={{ backgroundColor: "#0241A0", color: "white", height: "40px", cursor: "pointer" }} onClick={() => hanldeSubmitchangeInvoice(null, true)}>Yes, Change</div>
              <div className="w-50 justify-content-center rounded d-flex align-items-center mx-2 rounded" style={{ border: "2px solid #0241A0", color: "#0241A0", height: "40px", cursor: "pointer" }} onClick={() => hanldeSubmitchangeInvoice(null, false)}>Cancel</div>
            </div>
          </>
        )}
        {type === "file" && (
          <>
            {" "}
            <h4 className="fw-bold" style={{ fontSize: "20px", fontWeight: "600" }}>Upload Invoice to Scan?</h4>
            <p style={{ fontSize: "14px" }} className="text-center">
              Are you sure you want to upload an invoice for scanning? Uploading
              the invoice file will remove all the details you have entered so
              far.
            </p>
            <div className="d-flex justify-content-between">
              <div className="justify-content-center rounded d-flex align-items-center  w-50 mx-2" style={{ backgroundColor: "#0241A0", color: "white", height: "40px", cursor: "pointer" }} onClick={() => handleSubmit(null, true)}>Yes, Upload</div>
              <div className="w-50 justify-content-center rounded d-flex align-items-center mx-2 rounded" style={{ border: "2px solid #0241A0", color: "#0241A0", height: "40px", cursor: "pointer" }} onClick={() => handleSubmit(null, false)}>Cancel</div>
            </div>
          </>
        )}
        {type === "reset" && (
          <>
            {" "}
            <h4 className="fw-bold" style={{ fontSize: "20px", fontWeight: "600" }}>Reset Invoice?</h4>
            <p style={{ fontSize: "14px" }} className="text-center">
              Are you sure you want to reset invoice will remove all the details you have entered so
              far.
            </p>
            <div className="d-flex justify-content-between">
              <div className="justify-content-center rounded d-flex align-items-center  w-50 mx-2" style={{ backgroundColor: "#0241A0", color: "white", height: "40px", cursor: "pointer" }} onClick={() => handleSubmit(null, true)}>Yes, Reset</div>
              <div className="w-50 justify-content-center rounded d-flex align-items-center mx-2 rounded" style={{ border: "2px solid #0241A0", color: "#0241A0", height: "40px", cursor: "pointer" }} onClick={() => handleSubmit(null, false)}>Cancel</div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal >
  );
};

export default WarningModal;
