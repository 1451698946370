const custom_styles = {
  font_label: {
    fontSize: "14px",
    color: "#404040",
    width: "120px",
  },
  details: {
    fontWeight: "600",
    fontSize: "14px",
  },
};

const KeyValueLabel = ({ label, value, fullWidth, justify }) => {
  return (
    <div
      className={`form-group d-flex ${justify ? "" : "align-items-center"} ${
        fullWidth ? "w-100" : "w-50"
      }`}
    >
      <label style={{ ...custom_styles.font_label }}>{label}</label>
      <label style={{ ...custom_styles.details }}>{value ?? "-"}</label>
    </div>
  );
};

export default KeyValueLabel;
