import React, { useState, useEffect } from "react";
import FulfillmentInventoryModal from "./modals/FulfillmentInventoryModal";
import FulfillmentEventModal from "./modals/FulfillmentEventModal";
import { withLoader } from "../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { errorMessage } from "../../data/reducers/alerts/alert.reducer";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

//components
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";

// images
import NextWindowIcon from "../../images/next_window.svg";
import EyeIcon from "../../images/view.svg";

import "../../shared/styles/ModalStyles.scss";

import {
  getAllInventory,
  filterInventory,
  getDeliveryMessagesValues,
  revokeTicket,
} from "../../data/reducers/inventory/inventory.reducers";
import TicketInformationModal from "./modals/TicketInformationModal";
import { Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../../shared/components/modals/ConfirmationModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";

const InventoryDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [selectedTabIndex, setTabIndex] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.SALES,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.SALES,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "amount", value: filterUrlData?.amount },
        { key: "eventDate", value: filterUrlData?.eventDate },
        { key: "eventId", value: filterUrlData?.eventId },
        { key: "eventName", value: filterUrlData?.eventName },
        { key: "performerName", value: filterUrlData?.performerName },
        { key: "quantity", value: filterUrlData?.quantity },
        { key: "row", value: filterUrlData?.row },
        { key: "section", value: filterUrlData?.section },
        { key: "vendorId", value: filterUrlData?.vendorId },
      ]);
    }
  }, [filterUrlData, sortDir, sortField, selectedTabIndex]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        amount: queryParameters.get("amount"),
        eventDate: queryParameters.get("eventDate"),
        eventId: queryParameters.get("eventId"),
        eventName: queryParameters.get("eventName"),
        performerName: queryParameters.get("performerName"),
        quantity: queryParameters.get("quantity"),
        row: queryParameters.get("row"),
        section: queryParameters.get("section"),
        vendorId: queryParameters.get("vendorId"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/inventory?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(`/inventory?page=${Number(pageNumber) - 1}`);
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/inventory?page=${Number(pageNumber) + 1}`);
    setpageNumber(Number(pageNumber + 1));
  };

  const filterNavigation = (data) => {
    // var url = `/inventory?page=${data.searchText ? 1 : page}`
    var url = `/inventory?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.amount) url += `&&amount=${data.amount}`;
    if (data.eventDate) url += `&&eventDate=${data.eventDate}`;
    if (data.eventId) url += `&&eventId=${data.eventId}`;
    if (data.eventName) url += `&&eventName=${data.eventName}`;
    if (data.performerName) url += `&&performerName=${data.performerName}`;
    if (data.quantity) url += `&&quantity=${data.quantity}`;
    if (data.row) url += `&&row=${data.row}`;
    if (data.section) url += `&&section=${data.section}`;
    if (data.vendorId) url += `&&vendorId=${data.vendorId}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //use states
  const [addEditAddressModalVisibility, setAddEditAddressModalVisibility] =
    useState(false);
  const [addInventoryModalVisible, setAddEditInventoryModalVisibility] =
    useState(false);
  const [
    addInventoryEventModalVisible,
    setAddEditInventoryEventModalVisibility,
  ] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [searchData, setsearchData] = useState(
    location?.state?.vendorId ? { vendorId: location?.state?.vendorId } : null
  );
  const [selectedTickets, setSelectedTickets] = useState({
    open: null,
    section: null,
    row: null,
    tickets: [],
    ticketsId: [],
  });
  const [showTicketInformationModal, setShowTicketInformationModal] =
    React.useState(false);
  const [dropVal, setdropVal] = useState({
    vendorId: location?.state?.vendorId ? location?.state?.vendorId : null,
  });
  const [showConfirmationModalVisible, setShowConfirmationModalVisible] =
    useState(false);

  //resets the url state data.
  window.history.replaceState({}, document.title);

  //selectors
  const { vendorsListForDropdown: vendorDetails } = useSelector(
    (state) => state.cachedVendors
  );

  const inventoryList = useSelector((state) => state.inventory).inventory;
  const paging = useSelector((state) => state.inventory).paging;
  const { deliveryMessages } = useSelector((state) => state.inventory);
  useEffect(() => {
    document.getElementById("filterButton").click();
  }, []);

  useEffect(() => {
    if (!vendorDetails.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorDetails]);

  //----------------------- setting default filter -------------------//
  useEffect(() => {
    if (filterUrlData) {
      setdropVal({ ...dropVal, vendorId: Number(filterUrlData.vendorId) });
    }
  }, [filterUrlData]);

  // fetch all account information
  const fetchAccountsData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize,
        sortDir,
        selectedTabIndex,
        sortField,
      };

      await dispatch(getAllInventory(data));
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //fetch send delivery message values
  const fetchDeliveryMessagesValues = async () => {
    try {
      await dispatch(getDeliveryMessagesValues());
    } catch (e) {
      dispatch(errorMessage(e));
    }
  };

  const onRevokeTicket = async (id) => {
    try {
      setBusy(true);
      const data = {
        fromEvent: false,
        id,
      };
      await dispatch(revokeTicket(data));
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
      onSubmit(searchData);
      setShowConfirmationModalVisible(false);
    }
  };
  //search data
  const onSubmit = async (data) => {
    if (data && data.eventId == undefined) {
      data.eventId = "";
    }
    if (data && data.eventName == undefined) {
      data.eventName = "";
    }
    if (data && data.vendor == undefined) {
      data.vendor = "";
    }
    if (data && data.performerName == undefined) {
      data.performerName = "";
    }
    if (data && data.section == undefined) {
      data.section = "";
    }
    if (data && data.row == undefined) {
      data.row = "";
    }
    if (data && data.quantity == undefined) {
      data.quantity = "";
    }
    if (data && data.amount == undefined) {
      data.amount = "";
    }
    if (data && data?.searchText == undefined) {
      data.searchText = "";
    }
    if (data && data?.eventDate === undefined) {
      data.eventDate = "";
    }
    const formData = {
      ...data,
      searchText: data?.searchText !== undefined ? data.searchText.trim() : "",
      eventDate: data?.eventDate
        ? moment(data.eventDate).format("YYYY-MM-DD")
        : "",
      vendorId: data?.vendorId ?? "",
      pageNumber,
      sortField,
      sortDir,
      pageSize,
      selectedTabIndex,
    };

    setBusy(true);

    if (
      formData.eventId ||
      formData.eventName ||
      formData.vendor ||
      formData.performerName ||
      formData.section ||
      formData.row ||
      formData.quantity ||
      formData.amount ||
      formData.searchText ||
      formData.eventDate ||
      formData?.vendorId
    ) {
      setsearchData(formData);
      await dispatch(filterInventory(formData));
    } else {
      setpageNumber(1);
      setsearchData(null);
      await fetchAccountsData();
    }
    await fetchDeliveryMessagesValues();
    setBusy(false);
  };

  const onSubmitForm = (data) => {
    setAddEditInventoryEventModalVisibility(false);
  };

  //accounts table row and data
  const inventoryCol = [
    {
      columnName: "Date",
      render: (data) => <>{moment(data?.eventDateTime).format("MM/DD/YYYY")}</>,
    },
    {
      columnName: "Vendor/Performer",
      sort: true,
      sortName: "Vendor",
      render: (data) => (
        <>
          {data?.vendor}
          <br />
          {data?.perfomerName}
        </>
      ),
    },
    {
      columnName: "Event Name/ID",
      sort: true,
      sortName: "EventId",
      render: (data) => (
        <>
          {data?.eventName}
          <br />
          {data?.eventId}
        </>
      ),
    },
    {
      columnName: "Section",
      sort: true,
      sortName: "Section",
      render: (data) => <>{data?.section}</>,
    },
    {
      columnName: "Row",
      sort: true,
      sortName: "Row",
      render: (data) => <>{data?.row}</>,
    },
    {
      columnName: "Seat",
      sort: true,
      sortName: "highSeat",
      render: (data) => (
        <>
          {data?.lowSeat} - {data?.highSeat}
        </>
      ),
    },
    {
      columnName: "Qty",
      sort: true,
      sortName: "quantity",
      render: (data) => <>{data?.quantity}</>,
    },

    {
      columnName: "Amount",
      sort: true,
      sortName: "total",
      render: (data) => <>{formatMoney(data?.total)}</>,
    },
    {
      columnName: "Actions",
      render: (data) => (
        <>
          {selectedTabIndex !== 1 &&
            data.eventDateTime >= moment(new Date()).format("YYYY-MM-DD") &&
            (data.generationStatus !== "Generating" ? (
              <>
                <small className="table-row-card-actions d-flex">
                  <Button
                    variant="link"
                    className="table-action-button blue_bg"
                    onClick={() => {
                      setSelectedRecord(data);
                      if (selectedTabIndex === 0) {
                        setSelectedTickets({
                          open: null,
                          section: null,
                          row: null,
                          tickets: [],
                          ticketsId: [],
                        });
                        setAddEditInventoryModalVisibility(true);
                      } else if (selectedTabIndex === 1) {
                        setShowTicketInformationModal(true);
                      }
                    }}
                  >
                    <img src={NextWindowIcon} alt="Next Window Icon" />
                  </Button>
                </small>
                {data.generationStatus === "Failed" && (
                  <div
                    className="table-row-card-actions d-flex"
                    style={{
                      color: "red",
                      fontSize: "80%",
                      marginTop: "5%",
                    }}
                  >
                    Token Generation Failed
                  </div>
                )}
              </>
            ) : (
              <div
                style={{
                  color: "#198754",
                }}
              >
                Generating Token
              </div>
            ))}
          {selectedTabIndex === 1 && data.recipientTicketDetails && (
            <small className="table-row-card-actions d-flex">
              <Button
                variant="link"
                className="table-action-button blue_bg"
                onClick={() => {
                  setSelectedRecord(data);
                  if (selectedTabIndex === 0) {
                    setSelectedTickets({
                      open: null,
                      section: null,
                      row: null,
                      tickets: [],
                      ticketsId: [],
                    });
                    setAddEditInventoryModalVisibility(true);
                  } else if (selectedTabIndex === 1) {
                    setShowTicketInformationModal(true);
                  }
                }}
              >
                <img
                  src={EyeIcon}
                  style={{
                    marginLeft: "-35%",
                    marginTop: "-30%",
                    zoom: "130%",
                  }}
                  alt="Eye Icon"
                />
              </Button>
              <Button
                className="table-action-button red_bg"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedRecord(data);
                  setShowConfirmationModalVisible(true);
                }}
              >
                <span style={{ fontWeight: "bold", color: "rgb(153, 0, 0)" }}>
                  X
                </span>
              </Button>
            </small>
          )}
        </>
      ),
    },
  ];

  const inventoryModalCol = [
    {
      columnName: "Date",
      render: (data) => <>{moment(data?.eventDateTime).format("MM/DD/YYYY")}</>,
    },
    {
      columnName: "Vendor/Performer",
      render: (data) => (
        <>
          {data?.vendor}
          <br />
          {data?.perfomerName}
        </>
      ),
    },
    {
      columnName: "Event Name/ID",
      render: (data) => (
        <>
          {data?.eventName}
          <br />
          {data?.eventId}
        </>
      ),
    },
    {
      columnName: "Section",
      render: (data) => <>{data?.section}</>,
    },
    {
      columnName: "Row",
      render: (data) => <>{data?.row}</>,
    },
    {
      columnName: "Seat",
      render: (data) => (
        <>
          {data?.lowSeat} - {data?.highSeat}
        </>
      ),
    },
    {
      columnName: "Qty",
      render: (data) => <>{data?.quantity}</>,
    },

    {
      columnName: "Amount",
      render: (data) => <>{formatMoney(data?.total)}</>,
    },
  ];

  //head details
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
      </>
    );
  };

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.SALES,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for name, vendor, account name or account number..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              setdropVal({
                vendor: null,
              });
            }}
            dashboard={mixpanel_contants.SALES}
            onSubmit={filterNavigation}
            resetValues={
              location?.state?.vendorId
                ? [{ name: "vendorId", resetValue: null }]
                : null
            }
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Event Id
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Event Id"
                        {...register("eventId")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Event Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Event Name"
                        {...register("eventName")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Event Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        // dateFormat="mm-dd-yyyy"
                        placeholder="Start Date"
                        type="date"
                        {...register("eventDate")}
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Quantity
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Quantity"
                        {...register("quantity")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Amount
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Amount"
                        {...register("amount")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Performer Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Performer Name"
                        {...register("performerName")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Section
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        placeholder="Section"
                        {...register("section")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Row
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control placeholder="Row" {...register("row")} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Vendor Name
                    </Form.Label>
                    <Col sm="7">
                      <Controller
                        name="vendorId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={
                                vendorDetails?.find(
                                  (vendor) =>
                                    String(vendor?.id) ===
                                    String(dropVal?.vendorId)
                                ) ?? null
                              }
                              onChange={(e) => {
                                field.onChange(e?.id);
                                setdropVal({
                                  ...dropVal,
                                  vendorId: e ? e?.id : null,
                                });
                              }}
                              styles={customStyles}
                              options={vendorDetails}
                              getOptionLabel={(option) =>
                                `${option?.id}  ${option.name}`
                              }
                              isClearable
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <QuickTixTable
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="Sales"
        paging={paging}
        pageType="inventory"
        columns={inventoryCol}
        data={inventoryList || []}
        tabs={["Pending", "Completed"]}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={(i) => {
          setpageNumber(1);
          setTabIndex(i);
        }}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : inventoryCol}
            rowData={rowData}
          />
        )}
      />

      <FulfillmentInventoryModal
        isVisible={addInventoryModalVisible}
        handleClose={() => {
          setAddEditInventoryModalVisibility(false);
          setSelectedTickets({
            open: null,
            section: null,
            row: null,
            tickets: [],
            ticketsId: [],
          });
        }}
        modalOpen={addEditAddressModalVisibility}
        setModalOpen={setAddEditAddressModalVisibility}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        selectedTabIndex={selectedTabIndex}
        paging={paging}
        setSortField={setSortField}
        sortDir={sortDir}
        sortField={sortField}
        setSortDir={setSortDir}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
        inventoryCol={inventoryModalCol}
        setTabIndex={setTabIndex}
        setAddEditInventoryModalVisibility={setAddEditInventoryModalVisibility}
        setAddEditInventoryEventModalVisibility={
          setAddEditInventoryEventModalVisibility
        }
      />

      <FulfillmentEventModal
        isVisible={addInventoryEventModalVisible}
        selectedRecord={selectedRecord}
        setAddEditInventoryModalVisibility={setAddEditInventoryModalVisibility}
        handleClose={() => {
          if (searchData) {
            onSubmit(searchData);
          } else {
            fetchAccountsData();
          }
          setAddEditInventoryEventModalVisibility(false);
        }}
        selectedTickets={selectedTickets}
        onSubmit={onSubmitForm}
        setBusy={setBusy}
        deliveryMessages={deliveryMessages}
      />

      <TicketInformationModal
        isVisible={showTicketInformationModal}
        handleClose={() => setShowTicketInformationModal(false)}
        selectedRecord={selectedRecord}
        deliveryMessages={deliveryMessages}
      />
      <ConfirmationModal
        isVisible={showConfirmationModalVisible}
        handleClose={() => setShowConfirmationModalVisible(false)}
        onConfirmation={() => onRevokeTicket(selectedRecord?.id)}
        confirmationTxt="Are you sure you want to revoke the Ticket?"
      />
    </div>
  );
};

export default withLoader(InventoryDashboard);
