import { addressBookServices } from "../../services/addressBook/addressBook.service";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from 'js-file-download';

export const createAddress = createAsyncThunk(
  "addressBook/CreateAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.createAddress(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAddressByID = createAsyncThunk(
  "addressBook/getAddressByID",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.getAddressById(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "addressBook/updateAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.updateAddress(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "addressBook/deleteAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.deleteAddress(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllAddress = createAsyncThunk(
  "addressBook/getAllAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.getAllAddress(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAddressList = createAsyncThunk(
  "addressBook/getAddressList",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.getAddressList(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const exportCSVAddressBook = createAsyncThunk(
  'addressBook/exportcsv',
  async (thunkAPI) => {
    const response = await addressBookServices.exportCSVAddressBook();
    if (response.isSuccessful === true) {
      await fileDownload(response.data?.data, 'AddressBook.csv')
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVAddress = createAsyncThunk(
  "addressbook/exportCSVAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.exportSelectedAsCSVAddress(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "AddressBookFilter.csv")
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfAddressBook = createAsyncThunk(
  'addressBook/exportPdfAddressBook',
  async (thunkAPI) => {
    const response = await addressBookServices.exportPdfAddressBook();
    if (response.isSuccessful === true) {
      await fileDownload(response.data?.data, 'AddressBook.pdf')
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsPdfAddress = createAsyncThunk(
  "addressbook/exportSelectedAsPdfAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.exportSelectedAsPdfAddress(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data?.data, "AddressBookFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const filterAddress = createAsyncThunk(
  "addressBook/filterAddress",
  async (payload, thunkAPI) => {
    const response = await addressBookServices.filterAddress(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCardsByAddressId = createAsyncThunk(
  "addressBook/getCardsByAddressId",
  async (addressId, thunkAPI) => {
    const response = await addressBookServices.getCardsByAddressId(addressId);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.message);
    }
  }
);

export const getAccountsByAddressId = createAsyncThunk(
  "addressBook/getAccountsByAddressId",
  async (addressId, thunkAPI) => {
    const response = await addressBookServices.getAccountsByAddressId(addressId);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.message);
    }
  }
);


export const RemoveSelectedAddress = createAsyncThunk("addressBook/RemoveSelectedAddress", async (payload) => {
  return {}
})


export const EditSelectedAddress = createAsyncThunk("addressBook/EditSelectedAddress", async (payload) => {
  return payload
})


const addressBookSlice = createSlice({
  name: "addressBook",
  initialState: {
    address: [],
    selectedAddress: {},
    addressList: [],
    paging: {},
    cardsByAddress: [],
    accountsByAddress: [],
  },
  extraReducers: {
    [getAllAddress.fulfilled]: (state, action) => {
      state.address = action.payload.data;
      state.paging = action.payload.paging;
    },
    [getAddressList.fulfilled]: (state, action) => {
      state.addressList = action.payload;
    },
    [createAddress.fulfilled]: (state, action) => {
      state.address.push(action.payload);
      state.addressList.push(action.payload)
    },
    [getAddressByID.fulfilled]: (state, action) => {
      state.selectedAddress = action.payload;
    },
    [updateAddress.fulfilled]: (state, action) => {
      const index = state.address.findIndex((po) => po.id == action.payload.id);
      state.address[index] = action.payload;
      state.selectedAddress = action.payload;
    },
    [filterAddress.fulfilled]: (state, action) => {
      state.address = action.payload.data;
      state.paging = action.payload.paging;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.address = [
        ...state.address.filter(
          (po) => po.id !== action.payload.id
        ),
      ];
    },
    [getCardsByAddressId.fulfilled]: (state, action) => {
      state.cardsByAddress = action.payload;
    },
    [getAccountsByAddressId.fulfilled]: (state, action) => {
      state.accountsByAddress = action.payload;
    },
    [RemoveSelectedAddress.fulfilled]: (state, action) => {
      state.selectedAddress = action.payload;
    },
    [EditSelectedAddress.fulfilled]: (state, action) => {
      state.selectedAddress = { ...state.selectedAddress, ...action.payload }
    }
  },
});

export const { removeSelected } = addressBookSlice.actions;

export default addressBookSlice.reducer;
