import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, ModalTitle, Row } from "react-bootstrap";
import { withLoader } from "../../../utils/hoc/withLoader";
import "./historyModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { fetchPurchaseOrders } from "../../../data/reducers/purchaseOrders/cached-po.reducer";
import { setIsPendingPOChanged, updateStatusPO } from "../../../data/reducers/purchaseOrders/po.reducer";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import { setNotePendingPO } from "../../../data/reducers/purchaseOrders/po.reducer";
import Loader from "../../../shared/components/loader/Loader";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";

const PendingPOModal = ({
  isVisible,
  handleClose,
  selectedCheckboxes,
  actionType,
  resetHandler,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // Get pendingPONote from Redux state
  const { pendingPONote } = useSelector((state) => state.purchaseOrder);
  const selectedPurchaseOrders = useSelector(
    (state) => state.purchaseOrder.SelectedPurchaseOrders
  );

  // Get the first selected PO ID to use as the note ID
  const firstSelectedId = Object.keys(selectedCheckboxes)
    .filter((key) => selectedCheckboxes[key] === true)
    .map(Number)[0];

  // Initialize note from Redux state
  const [note, setNote] = useState("");

  useEffect(() => {
    if (isVisible && selectedCheckboxes.length > 0) {
      dispatch(fetchPurchaseOrders(selectedCheckboxes));
    }
  }, [isVisible, selectedCheckboxes, dispatch]);

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote(newNote);
    // Update note in Redux store
    if (firstSelectedId) {
      dispatch(setNotePendingPO({ id: firstSelectedId, note: newNote }));
    }
  };

  const handleApprove = () => {
    const trueIds = Object.keys(selectedCheckboxes)
      .filter((key) => selectedCheckboxes[key] === true)
      .map(Number);

    const statusId =
      actionType === "approve" ? 4 : actionType === "reject" ? 2 : 3;

    const data = {
      id: trueIds,
      statusId: statusId,
      statusNotes: note,
    };

    const selectedCount = trueIds.length;
    setIsLoading(true);

    dispatch(updateStatusPO(data))
      .then(() => {
        const successMsg =
          actionType === "approve"
            ? `${selectedCount} Purchase order${
                selectedCount > 1 ? "s" : ""
              } approved successfully.`
            : actionType === "reject"
            ? `${selectedCount} purchase order${
                selectedCount > 1 ? "s" : ""
              } rejected successfully.`
            : `Changes requested for${selectedCount} purchase order${
                selectedCount > 1 ? "s" : ""
              } successfully.`;

        dispatch(successMessage(successMsg));
        resetHandler();

        handleClose();
      })
      .catch((error) => {
        console.error("Error updating purchase orders:", error);
      })
      .finally(() => {
        setIsLoading(false);
        dispatch(setIsPendingPOChanged(true));
      });
  };

  const selectedCount = Object.values(selectedCheckboxes).filter(
    (value) => value === true
  ).length;

  const modalTitle =
    actionType === "approve"
      ? "Approve Purchase Orders"
      : actionType === "reject"
      ? "Reject Purchase Orders"
      : "Request Purchase Orders Changes";

  const buttonLabel =
    actionType === "approve"
      ? "Confirm Approval"
      : actionType === "reject"
      ? "Confirm Reject"
      : "Submit Request";

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        className="historyModal"
        show={isVisible}
        onHide={handleClose}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="upperCon">
              <Row>
                <Col sm={4} className="light">
                  {selectedCount} PO{selectedCount > 1 ? "s" : ""} Selected
                  <span className="dark" style={{ color: "black" }}>
                    {selectedCheckboxes?.purchaseOrderId}
                  </span>
                </Col>
                <Col sm={4} />
              </Row>
            </Container>

            <div className="tableCover">
              <Container className="tableHeader">
                <Row>
                  <Col style={{ fontSize: "0.9rem" }}>Purchase Order ID</Col>
                  <Col style={{ fontSize: "0.9rem" }}>Date</Col>
                  <Col style={{ fontSize: "0.9rem" }}>Vendor</Col>
                  <Col style={{ fontSize: "0.9rem" }}>Account Name</Col>
                  <Col style={{ fontSize: "0.9rem" }}>Season</Col>
                  <Col style={{ fontSize: "0.9rem" }}>Total Scheduled</Col>
                  <Col style={{ fontSize: "0.9rem" }}>Total Paid</Col>
                </Row>
              </Container>

              <Container className="tableBody">
                {Array.isArray(selectedPurchaseOrders) &&
                selectedPurchaseOrders.length > 0 ? (
                  selectedPurchaseOrders.map((po, index) => (
                    <Row
                      key={index}
                      className="tableRow"
                      style={{
                        backgroundColor: "white",
                        marginBottom: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Col>{po.purchaseOrderId}</Col>
                      <Col>
                        {new Date(po.purchaseOrderDate).toLocaleDateString()}
                      </Col>
                      <Col>{po.vendorName}</Col>
                      <Col>{po.accountName}</Col>
                      <Col>{po.seasonName}</Col>
                      <Col>{formatMoney(po.totalScheduled)}</Col>
                      <Col>
                        {po.totalPaid !== null
                          ? formatMoney(po.totalPaid)
                          : "N/A"}
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <Col>No purchase orders found.</Col>
                  </Row>
                )}
              </Container>
            </div>

            <div className="w-full p-4 bg-white">
              <h2 style={{ fontSize: "14px" }} className="font-bold">
                Add Note
              </h2>
              <textarea
                className="w-100 max-w-[1088px] p-2 h-[72px] border border-gray-300 rounded-md resize-none"
                placeholder="Type your notes..."
                value={note}
                style={{ resize: "none", fontSize: "0.8rem" }}
                onChange={handleNoteChange}
              />
              <Container className="upperCon">
                <Row>
                  <Col sm={4} className="light">
                    This note will apply to all selected POs.
                  </Col>
                  <Col sm={4} />
                </Row>
              </Container>
              <div className="text-end mt-3 d-flex justify-content-end">
                <Button
                  variant="light"
                  className="ss-modal-secondary-btn mx-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleApprove}
                  className="ss-modal-primary-btn mr-2"
                  style={{
                    backgroundColor: actionType === "reject" ? "#ff0000" : "",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : buttonLabel}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(PendingPOModal);
