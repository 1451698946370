import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { Col, Button, Dropdown, Form, Container } from "react-bootstrap";

import Export from "../../images/export.svg";
import Add from "../../images/add.svg";
import Run from "../../images/run.svg";
import History from "../../images/history.svg";
import SelectMatch from "../transactions/modals/SelectMatch";
import AddEditLocationModal from "./modals/AddEditLocationModal";
import LocationHistoryModal from "./modals/LocationHistoryModal";
import AddEditSeasonModal from "../season/modals/AddEditSeasonModal";
import ModalBreadcrumb from "../../shared/components/modals/ModalBreadcrumb";
import AccountInactiveModal from "./modals/AccountInactiveModal";
import AddEditAccountRulesModal from "./modals/AddEditRulesModal";
import Loader from "../../shared/components/loader/Loader.jsx";
import CollapsibleTable from "../purchaseOrders/CollapsibleTable";
import AddEditPOModal from "../purchaseOrders/modals/AddEditPOModal.js";
import { getPurchaseOrderRefs } from "../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import AddEditChargesModal from "./modals/AddEditChargesModal";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import AccountDetails from "./account-details/index.jsx";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import PaymentPlanModal from "../purchaseOrders/modals/paymentPlan";
import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import { createPOPayment } from "../../data/reducers/purchaseOrders/po-paymentPlans.reducer";
import {
  deletePO,
  filterPO,
  exportSelectedAsCSVPO,
  exportSelectedAsPdfPO,
  changePOEditState,
} from "../../data/reducers/purchaseOrders/po.reducer";
//reducers
import {
  createAccountRules,
  updateAccountRules,
  deleteAccountRules,
  getAccountRules,
  exportCSVAccountRules,
  exportPdfAccountRules,
} from "../../data/reducers/accounts/account-rules.reducer";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  getAccountIdDetails,
  getCardsByAccountId,
  getActiveEventsCountByAccountId,
  getPaymentPlansByAccount,
} from "../../data/reducers/accounts/accounts.reducers";
import {
  createAccountCharges,
  updateAccountCharges,
  deleteAccountCharges,
  getAccountCharges,
  exportPdfAccCharges,
  exportCSVAccount,
} from "../../data/reducers/accounts/account-charges.reducer";
import {
  charges,
  rules,
  purchase,
  locations,
  poCol,
  accountPaymentPlanCol,
} from "../../shared/components/table/data/accountsData";
import {
  updatePO,
  createPO,
} from "../../data/reducers/purchaseOrders/po.reducer";
import {
  createSeason,
  getAllSeason,
  getSeasonsList,
} from "../../data/reducers/season/season.reducer";
import {
  createAccountLocation,
  deleteAccountLocation,
  getAllAccountLocationDetails,
  updateAccountLocation,
} from "../../data/reducers/accounts/account-location.reducer";
import { flushAccounts } from "../../data/reducers/accounts/cached-accounts.reducers";
import { flushAccountsCards } from "../../data/reducers/accounts/cached-accounts-cards.reducers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import { formatPlanDate } from "../../utils/formatter/dateFormatter.js";

const startDate = "2000-01-01";

const SingleAccountDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* Note: id here is NOT the main ID, but rather the sar */
  const { id, tabIdx } = useParams();

  // Finding all the query parameters of this tab.
  // Get the current location object using useLocation hook
  const location = useLocation();

  // Access the search property of location, which contains the query string
  const queryString = location.search;

  // Parse the query string into an object
  const params = new URLSearchParams(queryString);

  /* useStates */
  const [loading, setLoading] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  const [ModalVisible, setModalVisibility] = useState(false);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedTabIndex, setTabIndex] = useState(
    params.get("tabIdx") ? Number(params.get("tabIdx")) : 0
  );
  const [accountChargesModalVisible, setAccountChargesModalVisible] =
    useState(false);
  const [accountRulesModalVisible, setAccountRulesModalVisible] =
    useState(false);
  const [
    accountPurchaseOrdersModalVisible,
    setAccountPurchaseOrdersModalVisible,
  ] = useState(false);
  const [accountLocationModalVisible, setAccountLocationModalVisible] =
    useState(false);
  const [
    accountLocationHistoryModalVisible,
    setAccountLocationHistoryModalVisible,
  ] = React.useState(false);
  const [accountInactiveModal, setAccountInactiveModal] = useState(false);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [paging, setpaging] = useState({});
  const [addSeasonModalVisibility, setAddSeasonModalVisibility] =
    useState(false);
  const [myMainId, setMainId] = useState(undefined); // Note: MainID is the superset of AccountID
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [column, setColumn] = useState(charges);
  const [data, setData] = useState([]);
  const [paymentPlansExist, setPaymentPlansExist] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState(null);
  /* useSelectors */
  const cards = useSelector((state) => state.accounts).cardsById;
  const activeEvents = useSelector((state) => state.accounts).activeEvents;
  const poList = useSelector((state) => state.purchaseOrder).purchaseOrders;
  const poListPaging = useSelector((state) => state.purchaseOrder).paging;
  const chargesPag = useSelector((state) => state.accountCharges).paging;
  const rulesPag = useSelector((state) => state.accountRules).paging;
  const details = useSelector((state) => state.accounts).selectedAccountDetails;
  const { accountLocationRecords, paging: locationPag } = useSelector(
    (state) => state.accountLocation
  );
  const paymentPlansDataWithPaging = useSelector(
    (state) => state.accounts
  ).paymentPlansInfo;

  const chargesList = useSelector(
    (state) => state.accountCharges
  ).accountsCharges;
  const rulesList = useSelector((state) => state.accountRules).accountsRules;

  /* API Dispatches */
  const onEntrySubmitted = async (data, option) => {
    try {
      setInnerLoading(option);
      const message = data.accountId
        ? `Account ${tabs[selectedTabIndex]} Updated Succesfully`
        : `New Account ${tabs[selectedTabIndex]} Created Succesfully`;
      switch (option) {
        case 0: {
          let matched;
          if (data.matched) {
            if (data.matched === true || data.matched === "true")
              matched = true;
            if (data.matched === false || data.matched === "false")
              matched = false;
          }
          const action = data.accountId
            ? updateAccountCharges({
              formData: {
                ...data,
                matched,
                // "matched": data?.matched === true ? true : false,
              },
              Id: id,
            })
            : createAccountCharges({
              formData: {
                ...data,
                amount: parseFloat(data?.amount),
                accountId: id,
                matched: data?.purchaseOrderId ? true : false,
                purchaseOrderId: data?.purchaseOrderId,
                vendorId: parseInt(details?.vendorId),
              },
              Id: id,
            });
          const response = await dispatch(action);
          if (response.error) {
            throw new Error(response.error);
          } else {
            dispatch(getCardsByAccountId(id));
            dispatch(successMessage(message));
            setAccountChargesModalVisible(false);
            fetchAll(option, sortField, sortDir);
          }
          break;
        }
        case 1: {
          const formData = {
            ...data,
          };
          const action = data.id
            ? updateAccountRules(formData)
            : createAccountRules({
              formData,
              Id: id,
            });
          const response = await dispatch(action);
          if (response.error) {
            throw new Error(response.error);
          } else {
            // dispatch(getAccountRules(id));
            dispatch(getCardsByAccountId(id));
            dispatch(successMessage(message));
            setAccountRulesModalVisible(false);
            fetchAll(option, sortField, sortDir);
          }
          break;
        }
        case 2: {
          const action = data.id
            ? updatePO({
              ...data,
              seasonId: data.seasonId ? data.seasonId : 0,
              purchaseOrderId: String(data?.purchaseOrderId),
              totalScheduled:
                data?.totalScheduled != ""
                  ? parseFloat(data?.totalScheduled)
                  : parseFloat(0),
              accountId: id,
            })
            : createPO({
              ...data,
              seasonId: data.seasonId ? data.seasonId : 0,
              purchaseOrderId: String(data?.purchaseOrderId),
              totalScheduled:
                data?.totalScheduled != ""
                  ? parseFloat(data?.totalScheduled)
                  : parseFloat(0),
              skyBoxRef:
                data?.skyBoxRef === "" ? null : data?.skyBoxRef?.toString(),
              accountId: id,
            });
          const response = await dispatch(action);
          if (response.error) {
            throw new Error(response.error);
          } else {
            dispatch(getCardsByAccountId(id));
            dispatch(successMessage(message));
            setAccountPurchaseOrdersModalVisible(false);
            fetchAll(option, sortField, sortDir);
          }
          break;
        }
      }
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  const addPaymentPlan = async (data) => {
    try {
      setInnerLoading("paymentPlan");
      const plans = [];
      let sum = 0;
      const message = `Payment Plan created Succesfully`;
      for (let i = 0; i < parseInt(data?.period); i++) {
        const date = data["dateInterval" + i];
        const amount = parseFloat(data["amountPerInterval" + i]);
        sum += amount;
        plans.push({
          amount,
          paymentDate: date,
          purchaseOrderId: selectedRecordId?.purchaseOrderId,
          id: 0,
          matchingTransaction: 0,
          purchaseOrdersId: selectedRecordId.id,
        });
      }

      const action = createPOPayment({ plans });
      const response = await dispatch(action);
      const skyBoxData = await getOrderRefs();
      if (response.error) {
        throw new Error(response.error);
      } else {
        const action = updatePO({
          ...selectedRecordId,
          listSkyboxRefs: [...skyBoxData],
          totalScheduled: parseFloat(sum),
        });
        dispatch(action);
        dispatch(successMessage(message));
        setModalVisibility(false);
        fetchAll(2);
      }
    } catch (e) {
      dispatch(errorMessage(e?.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  const getOrderRefs = async () => {
    let data = "";
    try {
      setLoading(true);
      const currentData = await dispatch(
        getPurchaseOrderRefs(selectedRecordId.id)
      );
      data = currentData?.payload?.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    return data;
  };
  /*Delete Charges/Rules/PurchaseOrders*/
  const onDelete = async (mainId, option) => {
    try {
      setInnerLoading("delete");
      let response;
      switch (option) {
        case 0:
          response = await dispatch(deleteAccountCharges({ mainId, id }));
          dispatch(successMessage("Account charge deleted successfuly"))
          break;
        case 1:
          response = await dispatch(deleteAccountRules({ mainId, id }));
          dispatch(successMessage("Account rule deleted successfuly"))
          break;
        case 2:
          response = await dispatch(deletePO(mainId));
          dispatch(successMessage("Purchase Order deleted successfuly"))
          break;
        case 3:
          response = await dispatch(
            deleteAccountLocation({ accountId: details?.id, id: mainId })
          );
          dispatch(successMessage("Account location deleted successfuly"))
          break;
      }
      if (response.error) {
        throw new Error(response.error);
      } else {
        // close modal
        dispatch(getCardsByAccountId(id));
        setDeleteConfirmationVisibility(false);
        fetchAll(option, sortField, sortDir);
      }
    } catch (e) {
      dispatch(errorMessage(e?.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  const fetchAll = async (option) => {
    try {
      const d = {
        id,
        pageNumber,
        pageSize,
        sortDir,
        sortField,
      };
      switch (option) {
        case 0:
          await dispatch(getAccountCharges(d));
          break;
        case 1:
          await dispatch(getAccountRules(d));
          break;
        case 2: {
          const data = {
            pageNumber,
            pageSize,
            sortDir,
            sortField,
            accountId: id,
            vendorId: "",
            searchText: "",
            description: "",
            purchaseOrderId: "",
            sportId: "",
            teamId: "",
            startDate,
            endDate: formatPlanDate(new Date()),
            seasonId: "",
            isReviewed: "ALL",
            findPaymentPlanStatus: true,
          };
          await dispatch(filterPO(data));
          break;
        }
        case 3:
          await dispatch(
            getAllAccountLocationDetails({ ...d, accountId: details?.id })
          );
          break;

        case 4:
          await dispatch(getPaymentPlansByAccount(d));
          break;
      }
    } catch (err) {
      dispatch(errorMessage(err?.message ?? "Something went wrong"));
    }
  };

  const onEditAction = (data) => {
    setMainId(data?.id);
    setSelectedRecord(data);
    if (selectedTabIndex === 3 && id) {
      setAccountLocationModalVisible(true);
    } else if (selectedTabIndex === 0 && id) {
      setAccountChargesModalVisible(true);
      // dispatch()
    } else if (
      selectedTabIndex === 1 &&
      id &&
      details?.autoGenerated == false
    ) {
      setAccountRulesModalVisible(true);
    } else if (selectedTabIndex === 1 && id && details?.autoGenerated) {
      setSelectedDataId(data?.id);
      setAccountPurchaseOrdersModalVisible(true);
    } else if (selectedTabIndex === 2 && id) {
      if (details?.categoryId === 1) {
        setAccountPurchaseOrdersModalVisible(true);
        setSelectedDataId(data?.id);
      } else {
        setAccountInactiveModal(true);
      }
    }
  };

  const onDeleteAction = (data) => {
    setMainId(data?.id);
    setSelectedRecord(data);
    setDeleteConfirmationVisibility(true);
  };

  let tabs = [];
  if (details && details?.autoGenerated) {
    tabs = ["All Charges", "Purchase Orders", "Location", "Payment Plan"];
  } else {
    tabs = [
      "All Charges",
      "Rules",
      "Purchase Orders",
      "Location",
      "Payment Plan",
    ];
  }
  const fetch = useCallback(async () => {
    await dispatch(getAccountIdDetails(id));
    await dispatch(getCardsByAccountId(id));
    await dispatch(getActiveEventsCountByAccountId(id));
    setLoading(false);
    setInnerLoading(false);
  }, [id, dispatch]);

  useEffect(() => {
    if (id != undefined) {
      setLoading(true);
      fetch();
    }
  }, [id]);

  const addSeason = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      try {
        setInnerLoading("season");
        const message = data.id
          ? `Season Updated Succesfully`
          : `New Season Created Succesfully`;

        const action = createSeason(data);
        const response = await dispatch(action);
        if (response.error) {
          throw new Error(response.error);
        } else {
          dispatch(successMessage(message));
          dispatch(
            getAllSeason({
              pageNumber: 1,
              pageSize: 40,
              sortDir: "Ascending",
              sortField: "",
            })
          );
          dispatch(getSeasonsList());
        }
      } catch (e) {
        dispatch(errorMessage(e?.message ?? "Something went wrong"));
      } finally {
        setAddSeasonModalVisibility(false);
        selectedTabIndex === 3
          ? setAccountLocationModalVisible(true)
          : setAccountPurchaseOrdersModalVisible(true);
        setInnerLoading(false);
      }
    }
  };

  //Run Rules
  const callRunRules = async () => {
    try {
      setInnerLoading("runRules");
      await dispatch(runRules());
      const data = {
        id,
        pageNumber,
        pageSize,
        sortDir,
        sortField,
      };
      setColumn(rules(onEditAction, onDeleteAction));
      await dispatch(getAccountRules(data));
    } catch (e) {
      dispatch(errorMessage(e.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  const callRunRules0 = async () => {
    try {
      setInnerLoading("runRules0");
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage(e.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  useEffect(async () => {
    setInnerLoading("table");
    try {
      const data = {
        id,
        pageNumber,
        pageSize,
        sortDir,
        sortField,
      };
      if (selectedTabIndex === 0 && id) {
        setColumn(
          charges(
            onEditAction,
            onDeleteAction,
            setSelectedRecord,
            matchedVisibility,
            setmatchedVisibility
          )
        );
        await dispatch(getAccountCharges(data));
      } else if (selectedTabIndex === 1 && id && details?.autoGenerated) {
        setColumn(
          purchase(
            onEditAction,
            onDeleteAction,
            setModalVisibility,
            setSelectedRecordId
          )
        );
        const data = {
          pageNumber,
          pageSize,
          sortDir,
          sortField,
          accountId: id,
          vendorId: "",
          searchText: "",
          description: "",
          purchaseOrderId: "",
          sportId: "",
          teamId: "",
          startDate,
          endDate: formatPlanDate(new Date()),
          seasonId: "",
          isReviewed: "ALL",
          findPaymentPlanStatus: true,
        };
        await dispatch(filterPO(data));
      } else if (selectedTabIndex === 1 && id) {
        setColumn(rules(onEditAction, onDeleteAction));
        await dispatch(getAccountRules(data));
      } else if (selectedTabIndex === 2 && id) {
        setColumn(
          purchase(
            onEditAction,
            onDeleteAction,
            setModalVisibility,
            setSelectedRecordId
          )
        );
        const data = {
          pageNumber,
          pageSize,
          sortDir,
          sortField,
          accountId: id,
          vendorId: "",
          searchText: "",
          description: "",
          purchaseOrderId: "",
          sportId: "",
          teamId: "",
          startDate,
          endDate: formatPlanDate(new Date()),
          seasonId: "",
          isReviewed: "ALL",
          findPaymentPlanStatus: true,
        };
        await dispatch(filterPO(data));
      } else if (selectedTabIndex === 3 && id) {
        setColumn(
          locations(
            onEditAction,
            onDeleteAction,
            setModalVisibility,
            setSelectedRecordId
          )
        );
        await dispatch(
          getAllAccountLocationDetails({ ...data, accountId: details?.id })
        );
      } else if (selectedTabIndex === 4 && id) {
        setColumn(accountPaymentPlanCol());
        await dispatch(
          getPaymentPlansByAccount({ ...data, accountId: data.id })
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }

    setInnerLoading(false);
  }, [
    selectedTabIndex,
    pageSize,
    pageNumber,
    sortDir,
    sortField,
    paymentPlansExist.length,
  ]);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setData(chargesList);
      setpaging(chargesPag);
    } else if (selectedTabIndex === 1 && details?.autoGenerated) {
      setData(poList);
      setpaging(poListPaging);
    } else if (selectedTabIndex === 1) {
      setData(rulesList);
      setpaging(rulesPag);
    } else if (selectedTabIndex === 2) {
      setData(poList);
      setpaging(poListPaging);
    } else if (selectedTabIndex === 3) {
      setData(accountLocationRecords);
      setpaging(locationPag);
    } else if (selectedTabIndex === 4) {
      const dataList =
        paymentPlansDataWithPaging?.data?.paymentPlansByAccountList;
      const newDataList = [];
      if (dataList && dataList.length > 0) {
        const totalScheduled = paymentPlansDataWithPaging?.data?.totalScheduled;
        let mightBePaid = 0;
        for (let i = 0; i < dataList.length; i++) {
          mightBePaid += dataList[i].amount;
          newDataList.push({
            ...dataList[i],
            balance: totalScheduled - mightBePaid,
          });
        }
      }
      setData(newDataList);
      setpaging(paymentPlansDataWithPaging?.paging);
    }
  }, [
    chargesList,
    rulesList,
    poList,
    selectedTabIndex,
    accountLocationRecords,
    locationPag,
    paymentPlansDataWithPaging,
  ]);
  const ExportCSV = async (option) => {
    try {
      setInnerLoading("export");
      let response;
      switch (option) {
        case 0:
          response = await dispatch(exportCSVAccount(id));
          break;
        case 1:
          response = await dispatch(exportCSVAccountRules(id));
          break;
        case 2: {
          const data = {
            pageNumber,
            pageSize,
            sortDir,
            sortField,
            accountId: id,
            vendorId: "",
            searchText: "",
            description: "",
            purchaseOrderId: "",
            sportId: "",
            teamId: "",
            startDate,
            endDate: formatPlanDate(new Date()),
            seasonId: "",
            isReviewed: "ALL",
            findPaymentPlanStatus: true,
          };
          response = await dispatch(exportSelectedAsCSVPO(data));
          break;
        }
      }
      if (response.error) {
        throw new Error(response.error);
      }
    } catch (e) {
      dispatch(errorMessage(e?.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };
  const previousPage = () => {
    setpageNumber(pageNumber - 1);
  };
  const nextPage = () => {
    setpageNumber(pageNumber + 1);
  };

  const onExportPdf = async (option) => {
    try {
      setInnerLoading("export");
      let response;
      switch (option) {
        case 0:
          response = await dispatch(exportPdfAccCharges(id));
          break;
        case 1:
          response = await dispatch(exportPdfAccountRules(id));
          break;
        case 2: {
          const data = {
            pageNumber,
            pageSize,
            sortDir,
            sortField,
            accountId: id,
            vendorId: "",
            searchText: "",
            description: "",
            purchaseOrderId: "",
            sportId: "",
            teamId: "",
            startDate,
            endDate: formatPlanDate(new Date()),
            seasonId: "",
            isReviewed: "ALL",
            findPaymentPlanStatus: true,
          };
          response = await dispatch(exportSelectedAsPdfPO(data));
          break;
        }
      }
      if (response.error) {
        throw new Error(response.error);
      }
    } catch (e) {
      dispatch(errorMessage(e?.message ?? "Something went wrong"));
    } finally {
      setInnerLoading(false);
    }
  };

  const resetHandler = () => {
    setInnerLoading("table");
    fetch();
  };

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small className="text-muted">Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => setpageSize(e.target.value)}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          {selectedTabIndex === 0 && (
            <Button
              variant="primary"
              style={{ textWrap: "nowrap" }}
              className="mx-2 ss-light-button"
              disabled={innerLoading === "runRules0"}
              onClick={async (e) => {
                e.currentTarget.blur();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.RUNRULES,
                  page: mixpanel_contants.TRANSACTIONS,
                });
                await callRunRules0();
                resetHandler();
              }}
            >
              {innerLoading === "runRules0" ? (
                <Loader />
              ) : (
                <img src={Run} alt=" " />
              )}
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Run Rules
                </div>
              </span>
            </Button>
          )}

          {selectedTabIndex !== 3 && selectedTabIndex != 4 && (
            <Dropdown>
              <Dropdown.Toggle
                disabled={innerLoading === "export"}
                className="ss-light-button"
                id="dropdown-basic"
              >
                <img src={Export} alt=" " />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginLeft: "8px" }}>Export</span>

                  {innerLoading === "export" && (
                    <Loader style={{ marginLeft: "8px" }} />
                  )}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => ExportCSV(selectedTabIndex)}>
                  Export as CSV
                </Dropdown.Item>

                <hr className="hr-full" />
                <Dropdown.Item onClick={() => onExportPdf(selectedTabIndex)}>
                  Export as PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {selectedTabIndex === 3 && (
            <Button
              variant="primary"
              className="ms-2 ss-light-button"
              onClick={(e) => {
                e.currentTarget.blur();
                setAccountLocationHistoryModalVisible(true);
              }}
            >
              <img src={History} alt=" " />
              <span>History</span>
            </Button>
          )}
          {selectedTabIndex == 1 && details?.autoGenerated == false && (
            <Button
              disabled={innerLoading === "runRules"}
              variant="primary"
              className="ms-2 ss-light-button"
              onClick={(e) => {
                e.currentTarget.blur();
                callRunRules();
              }}
            >
              <img src={Run} alt=" " />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "8px",
                }}
              >
                Run
                {innerLoading === "runRules" && (
                  <Loader style={{ marginLeft: "8px" }} />
                )}
              </div>
            </Button>
          )}
          {selectedTabIndex != 4 ? (
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                dispatch(changePOEditState(false));
                await setSelectedRecord(undefined);
                await setMainId(undefined);

                if (selectedTabIndex === 3) {
                  setAccountLocationModalVisible(true);
                } else {
                  selectedTabIndex === 0
                    ? setAccountChargesModalVisible(true)
                    : selectedTabIndex === 1 && !details?.autoGenerated
                      ? setAccountRulesModalVisible(true)
                      : details.categoryId === 1
                        ? setAccountPurchaseOrdersModalVisible(true)
                        : setAccountInactiveModal(true);
                }
              }}
              variant="primary"
              className="ms-2 ss-light-button"
            >
              <img src={Add} alt=" " />
              <span>Add</span>
            </Button>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const onSubmitLocation = async (data) => {
    const message = data.accountId
      ? `Account Location Updated Succesfully`
      : `New Account Location Created Succesfully`;
    try {
      setInnerLoading("location");
      const action = data.accountId
        ? updateAccountLocation({
          ...data,
        })
        : createAccountLocation({
          ...data,
          accountId: details?.id,
          isActive: true,
        });
      const response = await dispatch(action);
      if (response.error) {
        throw new Error(response.error);
      } else {
        // dispatch(getAccountCharges(id));
        dispatch(successMessage(message));
        setAccountLocationModalVisible(false);
        await fetchAll(3);
      }
    } catch (error) {
      console.error(error?.message ?? "Something went wrong");
    } finally {
      setInnerLoading(false);
    }
  };

  const goToEvents = () => {
    history.push({
      pathname: "/events",
      state: { email: details.email },
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <div className="container-fluid pt-3">
      {/* Upper Navigation */}
      <Container className="page-upper-navigation d-flex justify-content-between align-items-center">
        {/* <Row> */}

        <Col md={4}>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                name: "Accounts",
                active: true,
              },
              {
                name: details.accountId ? details.accountId : "",
                active: false,
              },
            ]}
          />
        </Col>
        <Col md={{ span: 4, offset: 4 }} className="text-end">
          <div style={{ display: "inline-block" }} className="px-3">
            <button
              className="ss-back-button text-end -2"
              style={{ border: "none" }}
              onClick={goToEvents}
            >
              <small>Go to Events : {activeEvents} (Active)</small>
            </button>
          </div>
          <NavLink className="ss-back-button text-end" to="/accounts">
            <small>Back to Accounts</small>
          </NavLink>
        </Col>
        {/* </Row> */}
      </Container>

      {/* Account Details */}
      <AccountDetails
        details={details}
        cards={cards}
        tabIndex={selectedTabIndex}
        paymentPlansCards={paymentPlansDataWithPaging?.data}
      />

      {!(
        selectedTabIndex == 2 ||
        (selectedTabIndex === 1 && details?.autoGenerated)
      ) && (
          <QuickTixTable
            loading={innerLoading === "table"}
            headerName="ACCOUNTS"
            paging={paging}
            tabs={tabs}
            setSortField={setSortField}
            sortDir={sortDir}
            sortField={sortField}
            setSortDir={setSortDir}
            selectedTabIndex={selectedTabIndex}
            onTabSelectionChange={(i) => {
              setpageNumber(1);
              if (selectedTabIndex != i) {
                setpageSize(40);
              }
              setTabIndex(i)
            }}
            nextPage={nextPage}
            previousPage={previousPage}
            columns={column}
            data={data || []}
            headOptions={headOptions}
            setpageNumber={setpageNumber}
            renderRow={(rowData) => (
              <TableRowCard
                key={rowData.id}
                columns={rowData.nodata ? columnError : column}
                rowData={rowData}
              />
            )}
          />
        )}
      {/* Data Table */}

      {(selectedTabIndex == 2 ||
        (selectedTabIndex === 1 && details?.autoGenerated)) && (
          <CollapsibleTable
            loading={innerLoading === "table"}
            headerName="ACCOUNTS"
            paging={paging}
            pageType="purchase-orders"
            columns={poCol(
              onEditAction,
              onDeleteAction,
              setModalVisibility,
              setSelectedRecordId,
              dispatch
            )}
            data={poList || []}
            headOptions={headOptions}
            sortDir={sortDir}
            setSortDir={setSortDir}
            tabs={tabs}
            selectedTabIndex={selectedTabIndex}
            onTabSelectionChange={(i) => {
              setpageNumber(1);
              if (selectedTabIndex != i) {
                setpageSize(40);
              }
              setTabIndex(i);
            }}
            nextPage={nextPage}
            previousPage={previousPage}
            sortField={sortField}
            setSortField={setSortField}
            setpageNumber={setpageNumber}
            renderRow={(rowData) => (
              <TableRowCard
                key={rowData.id}
                columns={
                  rowData.nodata
                    ? columnError
                    : poCol(
                      onEditAction,
                      onDeleteAction,
                      setModalVisibility,
                      setSelectedRecordId,
                      dispatch
                    )
                }
                rowData={rowData}
              />
            )}
          />
        )}

      {accountChargesModalVisible && (
        <AddEditChargesModal
          preFilledId={id}
          isVisible={accountChargesModalVisible}
          handleClose={() => setAccountChargesModalVisible(false)}
          onSubmit={(data) => onEntrySubmitted(data, 0)}
          loading={innerLoading === 0}
          selectedRecord={selectedRecord}
        />
      )}

      {accountRulesModalVisible && (
        <AddEditAccountRulesModal
          isVisible={accountRulesModalVisible}
          handleClose={() => setAccountRulesModalVisible(false)}
          onSubmit={(data) => onEntrySubmitted(data, 1)}
          loading={innerLoading === 1}
          selectedRecord={selectedRecord}
        />
      )}

      {accountPurchaseOrdersModalVisible && (
        <AddEditPOModal
          isVisible={accountPurchaseOrdersModalVisible}
          handleClose={() => setAccountPurchaseOrdersModalVisible(false)}
          onSubmit={(data) => onEntrySubmitted(data, 2)}
          loading={innerLoading === 2}
          selectedRecord={selectedRecord}
          setModalOpen={setAddSeasonModalVisibility}
          setSelectedRecord={setSelectedRecord}
          accountsData={id}
        />
      )}

      {accountLocationModalVisible && (
        <AddEditLocationModal
          isVisible={accountLocationModalVisible}
          handleClose={() => setAccountLocationModalVisible(false)}
          onSubmit={onSubmitLocation}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          setModalOpen={setAddSeasonModalVisibility}
          loading={innerLoading === "location"}
        />
      )}

      {deleteConfirmationVisible && (
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisible}
          handleClose={() => setDeleteConfirmationVisibility(false)}
          onConfirmation={() => onDelete(myMainId, selectedTabIndex)}
          loading={innerLoading === "delete"}
        />
      )}

      {ModalVisible && (
        <PaymentPlanModal
          isVisible={ModalVisible}
          handleClose={() => setModalVisibility(false)}
          onSubmit={(data) => addPaymentPlan(data)}
          loading={innerLoading === "paymentPlan"}
          purchaseOrderId={selectedRecordId}
        />
      )}

      {addSeasonModalVisibility && (
        <AddEditSeasonModal
          isVisible={addSeasonModalVisibility}
          onSubmit={addSeason}
          loading={innerLoading === "season"}
          handleClose={() => {
            setAddSeasonModalVisibility(false);
            selectedTabIndex === 3
              ? setAccountLocationModalVisible(true)
              : setAccountPurchaseOrdersModalVisible(true);
          }}
        />
      )}

      {matchedVisibility && (
        <SelectMatch
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={() => setmatchedVisibility(false)}
          match={true}
          selectedRecord={selectedRecord}
        />
      )}

      {accountInactiveModal && (
        <AccountInactiveModal
          isVisible={accountInactiveModal}
          handleClose={() => setAccountInactiveModal(false)}
          selectedRecord={selectedRecord}
          selectedAccount={details}
        />
      )}

      {accountLocationHistoryModalVisible && (
        <LocationHistoryModal
          isVisible={accountLocationHistoryModalVisible}
          handleClose={() => setAccountLocationHistoryModalVisible(false)}
          accountId={details?.id}
        />
      )}
    </div>
  );
};

export default SingleAccountDetail;
