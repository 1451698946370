import { split } from "lodash";

export const formatDate = (value) => {
  const myDate = value;
  const date = new Date(myDate);
  let d = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (d < 10) {
    d = "0" + d;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${month}/${d}/${year}`;
};

export const formatPlanDate = (value) => {
  if (!value) return null;
  const myDate = value;
  const date = new Date(myDate);
  let d = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (d < 10) {
    d = "0" + d;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${year}-${month}-${d}`;
};

export const inputDate = (end) => {
  const date = new Date();
  let d = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (end) {
    year = year + 1;
  }

  if (d < 10) {
    d = "0" + d;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${year}-${month}-${d}`;
};

export const compareDate = (date1, date2) => {
  // Extract year, month, and day from each date
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  if (year1 > year2) return true;
  if (year1 === year2 && month1 > month2) return true;
  if (year1 == year2 && month1 == month2 && day1 > day2) return true;
  return false;
};

export const dateSlashFormatToDateObject = (date1) => {
  if (!date1) return null;
  const split1 = date1.split("/");
  const firstDateObject = new Date();
  firstDateObject.setMonth(Number(split1[0]) - 1);
  firstDateObject.setFullYear(split1[2]);
  firstDateObject.setDate(split1[1]);
  return firstDateObject;
};

export const dateHyephenToDateObject = (dateString) => {
  if (!dateString) return null;
  const splitDate = dateString.split("-");
  const year = Number(splitDate[0]);
  const month = Number(splitDate[1]) - 1; // Month is zero-indexed
  const day = Number(splitDate[2]);
  return new Date(year, month, day);
};

export const isDatePartEqual = (date1, date2) => {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();
  if (year1 == year2 && month1 == month2 && day1 == day2) return true;
  return false;
};

export const formatSlashDate = (value) => {
  if (!value) return null;
  const split1 = value.split("/");
  const year = split1[2];
  const month = split1[0];
  const date = split1[1];

  return `${year}-${month}-${date}`;
}
