import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accountServices } from "../../services/accounts/account.service";
import { eventServices } from "../../services/events/event.service";
import fileDownload from "js-file-download";
import { errorMessage } from "../alerts/alert.reducer";
import { createAddress } from "../addressBook/addressBook.reducer";

export const createAccount = createAsyncThunk(
  "accounts/CreateAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.createAccount(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(JSON.stringify(response.data));
      errorMessage(response.message);

      throw new Error(JSON.stringify(response));
    }
  }
);

export const updateAccount = createAsyncThunk(
  "accounts/UpdateAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.updateAccount(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAllAccounts = createAsyncThunk(
  "accounts/getAllAccounts",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAllAccounts(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const lookupAccounts = createAsyncThunk(
  "accounts/lookupAccounts",
  async (payload, thunkAPI) => {
    const response = await accountServices.lookupAccounts(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportCSVAccount = createAsyncThunk(
  "accounts/exportCSVAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportCSVAccount(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "Accounts.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportSelectedAsCSVAccount = createAsyncThunk(
  "accounts/exportSelectedAsCSVAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportSelectedAsCSVAccount(payload);
    if (response.isSuccessful === true) {
      fileDownload(response.data?.data, "AccountsFilter.csv");
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const exportPdfAccount = createAsyncThunk(
  "accounts/exportPdfAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportPdfAccount(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "Accounts.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const exportSelectedAsPdfAccount = createAsyncThunk(
  "accounts/exportSelectedAsPdfAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.exportSelectedAsPdfAccount(payload);
    // if (response.isSuccessful === true) {
    //   fileDownload(response.data, "AccountsFilter.pdf")
    // } else {
    //   thunkAPI.rejectWithValue(response.message);
    //   throw new Error(response.message);
    // }
  }
);

export const getAccountsList = createAsyncThunk(
  "accounts/getAccountsList",
  async (thunkAPI) => {
    const response = await accountServices.getAccountsList();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsDetails = createAsyncThunk(
  "accounts/GetMondayAccountDetails",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountDetails(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsListByVendor = createAsyncThunk(
  "accounts/getAccountsListByVendor",
  async (thunkAPI) => {
    const response = await accountServices.getAccountsList(true);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsCards = createAsyncThunk(
  "accounts/getAccountsCards",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountsCards(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const filterAccount = createAsyncThunk(
  "accounts/filterAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.filterAccount(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountIdDetails = createAsyncThunk(
  "accounts/getAccountIdDetails",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountIdDetails(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAccountTeamList = createAsyncThunk(
  "accounts/getAccountTeamList",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountTeams(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);
export const getAccountSportList = createAsyncThunk(
  "accounts/getAccountSportList",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountSports();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountCardList = createAsyncThunk(
  "CardInfo/getAccountCardList",
  async (payload, thunkAPI) => {
    const response = await accountServices.getCardsList();
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getCardsByAccountId = createAsyncThunk(
  "CardInfo/getAccountCardListById",
  async (payload, thunkAPI) => {
    const response = await accountServices.getCardsByAccountId(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getActiveEventsCountByAccountId = createAsyncThunk(
  "accounts/getActiveEventsCountByAccountId",
  async (payload, thunkAPI) => {
    const response = await eventServices.getActiveEventsCountByAccountId(
      payload
    );
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountCardsInfo = createAsyncThunk(
  "CardInfo/getAccountCardsInfo",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountCardsInfo(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getPaymentPlansByAccount = createAsyncThunk(
  "Accounts/GetPaymentPlansByAccount",
  async (payload, thunkAPI) => {
    const response = await accountServices.getPaymentPlansByAccount(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getAccountsbysearch = createAsyncThunk(
  "accounts/getAccountsbysearch",
  async (payload, thunkAPI) => {
    const response = await accountServices.getAccountsbySearch(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const getSelectedAccount = createAsyncThunk(
  "accounts/getSelectedAccount",
  async (payload, { dispatch }) => {
    const response = await accountServices.getSelectedAccountDetails(payload);
    if (response.isSuccessful === true) {
      //here we will create an address in the database if the address is coming from the monday CRM. 
      if (response && response?.data?.length > 0) {
        const data = response.data[0];
        if (data?.address) {
          const createAddressResponse = await dispatch(createAddress({
            address1: data?.address ?? '',
            address2: data?.address2 ?? '',
            city: data?.city ?? '',
            email: data?.email ?? '',
            mobileNumber: data?.ssPhone ?? '',
            postalCode: data?.zipcode ?? '',
            state: data?.state ?? ''
          }))
          response.data[0].mobileNo = data?.ssPhone
          if (createAddressResponse?.error && createAddressResponse?.error?.message?.includes("Address already exists")) {
            const addressBookId = Number(createAddressResponse?.error?.message?.split("Address already exists ")[1]);
            response.data[0].addressBookId = addressBookId;
          }
          else if (!createAddressResponse?.error) {
            const addressBookId = createAddressResponse?.payload?.id;
            response.data[0].addressBookId = addressBookId;
          }
        }
      }
      return response.data;
    } else {
      throw new Error(response.message);
    }
  }
);
export const getMondayDetails = createAsyncThunk(
  "accounts/getMondayDetails",
  async (payload, thunkAPI) => {
    const response = await accountServices.getSelectedAccountDetails(payload);
    if (response.isSuccessful === true) {
      console.log("response", response.data)
      return response.data;
    } else {
      thunkAPI.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const EditSelectedAccount = createAsyncThunk(
  "Accounts/EditSelectedAccount",
  async (payload) => {
    return payload;
  }
);

export const removeAccountList = createAsyncThunk(
  "Accounts/removeAccountList",
  async (payload) => {
    return [];
  }
);

export const removeSelectedAccount = createAsyncThunk(
  "Accounts/removeSelectedAccount",
  async (payload) => {
    return {};
  }
);


export const setAccountRejectedData = createAsyncThunk(
  "Accounts/setAccountRejectedData",
  async (payload) => {
    return payload
  }
)

const accountSlice = createSlice({
  name: "account",
  initialState: {
    accounts: [],
    accountsList: [],
    FetchedAccounts: [],
    accountListByVendor: [],
    paging: {},
    cards: {},
    cardsById: {},
    selectedAccountDetails: {},
    csvContent: [],
    teamList: [],
    sportList: [],
    cardList: [],
    newlyCreated: {
      team: {},
      sport: {},
    },
    teamsPaging: {},
    sportsPaging: {},
    allTeams: [],
    allSports: [],
    categories: [],
    activeEvents: 0,
    accountCardsInfo: [],
    paymentPlansInfo: {},
    poAttachment: null,
    ticketDetails: {},
    accountVendorCombination: [],
    mondayAccountDetails: [],
    accountRejectedData: undefined
  },
  reducers: {
    setSelectedAccountDetails: (state, action) => {
      state.selectedAccountDetails = action.payload;
    },
    setUploadPoAttachment: (state, action) => {
      state.poAttachment = action.payload;
    },
    setSelectedTicketDetails: (state, action) => {
      state.ticketDetails = action.payload;
    },
  },
  extraReducers: {
    [getAccountCardsInfo.fulfilled]: (state, action) => {
      state.accountCardsInfo = action.payload;
    },
    [getAccountsListByVendor.fulfilled]: (state, action) => {
      state.accountListByVendor = action.payload;
    },
    [getAccountIdDetails.fulfilled]: (state, action) => {
      state.selectedAccountDetails = action.payload;
    },
    [getAccountsDetails.fulfilled]: (state, action) => {
      state.accountVendorCombination = action.payload;
      state.selectedAccountDetails = action.payload;
    },
    [getMondayDetails.fulfilled]: (state, action) => {
      state.mondayAccountDetails = action.payload;
    },

    [getAllAccounts.fulfilled]: (state, action) => {
      state.accounts = action.payload.data;
      state.paging = action.payload.paging;
    },

    [getAccountsCards.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },
    [filterAccount.fulfilled]: (state, action) => {
      state.accounts = action.payload.data;
      state.paging = action.payload.paging;
    },
    [createAccount.fulfilled]: (state, action) => {
      state.accounts.push(action.payload);
    },
    [createAccount.rejected]: (state, action) => {
      const data = JSON.parse(action.error.message)
      if (data?.message?.includes('AccountId and VendorId combination')) {
        state.accountRejectedData = data?.data;
      }
    },
    [setAccountRejectedData.fulfilled]: (state, action) => {
      state.accountRejectedData = action.payload
    },
    [updateAccount.fulfilled]: (state, action) => {
      const index = state.accounts.findIndex(
        (account) => account.id == action.payload.id
      );
      state.accounts[index] = action.payload;
      state.selectedAccountDetails = action.payload;
    },

    [getAccountTeamList.fulfilled]: (state, action) => {
      state.teamList = action.payload;
    },
    [getAccountsList.fulfilled]: (state, action) => {
      state.accountsList = action.payload;
    },
    [getAccountSportList.fulfilled]: (state, action) => {
      state.sportList = action.payload;
    },
    [getAccountCardList.fulfilled]: (state, action) => {
      state.cardList = action.payload;
    },
    [lookupAccounts.fulfilled]: (state, action) => {
      state.categories = action.payload.data;
    },
    [getCardsByAccountId.fulfilled]: (state, action) => {
      state.cardsById = action.payload;
    },
    [getActiveEventsCountByAccountId.fulfilled]: (state, action) => {
      state.activeEvents = action.payload;
    },
    [getPaymentPlansByAccount.fulfilled]: (state, action) => {
      state.paymentPlansInfo = action.payload;
    },
    [EditSelectedAccount.fulfilled]: (state, action) => {
      state.selectedAccountDetails = {
        ...state.selectedAccountDetails,
        ...action.payload,
      };
    },
    [getAccountsbysearch.fulfilled]: (state, action) => {
      state.FetchedAccounts = action.payload;
    },
    [removeAccountList.fulfilled]: (state, action) => {
      state.FetchedAccounts = action.payload;
    },
    [getSelectedAccount.fulfilled]: (state, action) => {
      state.selectedAccountDetails = {
        name: action.payload[0]?.name,
        email: action.payload[0]?.email,
        mobileNo: action.payload[0]?.ssPhone,
        addressBookId: action.payload[0]?.addressBookId,
      }
    },
    [removeSelectedAccount.fulfilled]: (state, action) => {
      state.selectedAccountDetails = action.payload;
    },
  },
});

export const {
  removeSelected,
  setUploadPoAttachment,
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} = accountSlice.actions;

export default accountSlice.reducer;
