import {
  api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  download,
  getCSV,
  getPDF,
  secrets,
} from "../services.common";
import axios from "axios";
const getAllEmail = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}api/GoogleEmails?PageNumber=${data.pageNumber || 1
    }&PageSize=${data.pageSize || 10}&SortDir=${sortDir}&SortField=${data.sortField || ""
    }&FilterOption=${data.filterOption || "all"}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

// filter Data by passing arguments

const filterData = async (data) => {
  const sortDir = data.sortDir == false ? "Descending" : "Ascending";
  const url = `${api}api/GoogleEmails?PageNumber=${data.pageNumber || ""
    }&PageSize=${data.pageSize || ""}&SortDir=${sortDir}&SortField=${data.sortField || ""
    }&Search=${data.searchText || ""}&FirstName=${encodeURIComponent(data.firstName) || ""
    }&LastName=${data.lastName}&username=${encodeURIComponent(data.username) || ""
    }&MobileNumber=${data.mobileNumber}&GenderId=${data.genderId}&Date=${data.dateOfBirth || ""
    }&FilterOption=${data.filterOption || "all"}&IsGeneric=${data.isGeneric === null ? "" : data.isGeneric}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const createEmail = async (data) => {
  const url = `${api}api/GoogleEmails`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const genderEmail = async () => {
  const url = `${api}api/GoogleEmails/getgenders`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const downloadTemplateEmail = async () => {
  const url = `${api}api/GoogleEmails/csvtemplate`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const downloadTampleteEmailData = async () => {
  const url = `${api}api/GoogleEmails/getuploadedexcels`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const downloadUploadedFiles = async (data) => {
  const url = `${api}api/GoogleEmails/downloaduploadedfile/${data?.id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const uploadCsvFiles = async (data) => {
  const url = `${api}api/GoogleEmails/uploadcsv`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const createRecoveryEmail = async (email) => {
  const url = `${api}api/GoogleEmails/createRecoveryEmail?email=${email}`;
  const authHeader = getAuthHeaders();
  return await post(url, {}, { ...authHeader });
};

const setDefaultRecoveryEmail = async (email) => {
  const url = `${api}api/GoogleEmails/setDefaultRecoveryEmail?email=${email}`;
  const authHeader = getAuthHeaders();
  return await post(url, {}, { ...authHeader });
};

const getAllRecoveryEmail = async () => {
  const url = `${api}api/GoogleEmails/getAllRecoveryEmails`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

// download email List
const downloadEmailList = async () => {
  const url = `${api}api/GoogleEmails/exportCsv`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const getEmailData = async (id) => {
  const url = `${api}api/GoogleEmails/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const updateEmail = async (data) => {
  const url = `${api}api/GoogleEmails`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const getBalance = async () => {
  const url = `${api}api/GoogleEmails/getSMSBalances`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//------------------ upload services ------------------//
const getUploadEmails = async (data) => {

  const url = `${api}api/GoogleEmailUpload/get-all?name=${data?.name || ""}&email=${data?.email || ""}&lastLogin=${data?.lastLogin || ""}&search=${data?.searchText ?? ""
    }&PageSize=${data?.PageSize ?? 10}&PageNumber=${data?.pageNumber ?? 1
    }&SortDir=${"Ascending"}&SortField=${""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const downloadUploadEmailTemplate = async () => {
  const url = `${api}api/GoogleEmailUpload/csv-template`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const uploadEmailCsvFiles = async (data) => {
  const url = `${api}api/GoogleEmailUpload/upload-csv`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const createUploadEmail = async (data) => {
  const url = `${api}api/GoogleEmailUpload/create`;
  const authHeader = getAuthHeaders();
  return await post(url, { ...data, email: data?.username }, { ...authHeader });
};

const getEmailLogs = async (username) => {
  const url = `${api}api/GoogleEmails/googlefarmingactivities/${username}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const emailServices = {
  getAllEmail,
  createEmail,
  genderEmail,
  downloadTemplateEmail,
  downloadTampleteEmailData,
  downloadUploadedFiles,
  uploadCsvFiles,
  filterData,
  downloadEmailList,
  getEmailData,
  getBalance,
  updateEmail,
  createRecoveryEmail,
  setDefaultRecoveryEmail,
  getAllRecoveryEmail,
  getUploadEmails,
  downloadUploadEmailTemplate,
  uploadEmailCsvFiles,
  createUploadEmail,
  getEmailLogs,
};
