import { Route, Switch } from "react-router-dom";
import PurchaseOrderDashboard from "../../../pages/purchaseOrders/PurchaseOrderDashboard";
import SinglePurchaseOrderDetail from "../../../pages/purchaseOrders/SinglePurchaseOrderDetails";
import AddFromInvoiceWizard from "../../../pages/purchaseOrders/invoice/AddFromInvoiceWizard";
import PendingPo from "../../../pages/purchaseOrders/PendingPODasboard";

const PORoutes = () => {
  return (
    <Switch>
       <Route path="/pendingPO">
        <PendingPo/> 
      </Route>
      <Route path="/purchases" exact>
        <PurchaseOrderDashboard/> 

      </Route>
      <Route path="/purchases/:id" exact>
        <SinglePurchaseOrderDetail />
      </Route>
      <Route path="/purchase/add-invoice/:id/:step" exact>
        <AddFromInvoiceWizard />
      </Route>
      
    </Switch>
  );
};

export default PORoutes;
