import { Form, InputGroup, Table } from "react-bootstrap";
import Header from "../../../../components/Header";
import KeyValueLabel from "../../../../components/KeyValueLabel";
import { useSelector } from "react-redux";
import { formatPlanDate } from "../../../../../../../utils/formatter/dateFormatter";

const TicketDetailsPreview = ({ data, custom_styles, setSteps }) => {
  const { purchaseOrderData, globalInvoiceType, globalFeeInputs } = useSelector((state) => state.purchaseOrder);
  return (
    <>
      <div className="p-4 d-flex flex-column gap-4">
        <Header
          title="Ticket Details"
          subTitle="Please enter ticket details as listed on the invoice."
          onEdit={() => setSteps(2)}
        />

        {/* Sport and Team */}
        <div className="d-flex flex-column gap-4">
          <div className="d-flex justify-content-between ">
            {/* Sport  */}
            <KeyValueLabel
              label={"Sport"}
              value={data?.ticketDetails?.sport?.label}
            />
            {/* Team */}
            <KeyValueLabel
              label={"Team"}
              value={data?.ticketDetails?.team?.label}
            />
          </div>
          {/* Season */}
          {
            globalInvoiceType != 4 && globalInvoiceType != 3 &&
            <KeyValueLabel
              label={"Season"}
              value={data?.ticketDetails?.season?.label}
            />

          }
        </div>

        <div style={{ ...custom_styles.border2, margin: "0" }}></div>

        {/* Ticket Season  */}
        {
          globalInvoiceType !== 3 ?
            <>

              <div className="d-flex justify-content-between ">
                {/* Ticket purchased in current season */}
                <div className="form-group d-flex align-items-center w-50">
                  <Form.Group>
                    <Form.Check
                      readOnly
                      className="remember-forgotpass"
                      type="checkbox"
                      id="payment_check"
                      checked={data?.ticketDetails?.isTicketPurchasesInCurrentSeason ? true : false}
                      label={
                        <label
                          htmlFor="payment_check"
                          style={{ fontSize: "14px", cursor: "pointer" }}
                        >
                          Ticket Purchased in current season
                        </label>
                      }


                    />
                  </Form.Group>

                </div>
                {/* First Purchased date */}
                {data?.ticketDetails?.isTicketPurchasesInCurrentSeason ? (
                  <div className="form-group d-flex justify-content-start align-items-center w-50">
                    <label
                      className="form-label col-form-label w-25"
                      style={{ ...custom_styles.font_label }}
                    >
                      First Game Date
                    </label>
                    <label className="ms-4" style={{ ...custom_styles.details }}>
                      {data.ticketDetails.firstPuchaseDate}
                    </label>
                  </div>
                ) : null}
              </div>

              <div style={{ ...custom_styles.border2, margin: "0" }}></div>

              {/* Ticket Table */}

              <div className="d-flex flex-column gap-2">
                <span className="text-muted  " style={{ fontSize: "14px" }}>
                  Ticket Details
                </span>

                <div>
                  <Table bordered hover>
                    <thead style={{ backgroundColor: "#f5f5f5" }}>
                      <tr
                        // style={{ ...custom_styles.details }}
                        className="text-center"
                      >
                        {globalInvoiceType != 1 && globalInvoiceType != 2 ?
                          <th style={{ width: '14%' }}>
                            Event Date{" "}
                            {/* <span
                        style={{
                          color: "#868686",
                          fontWeight: "400",
                          width: "200px",
                        }}
                      > */}
                            (optional)
                            {/* </span> */}
                          </th>
                          :
                          null
                        }
                        <th style={{ width: '14%' }}>Section</th>
                        <th style={{ width: '14%' }}>Row</th>
                        <th style={{ width: '14%' }}>Low Seat</th>
                        <th style={{ width: '14%' }}>High Seat</th>
                        <th style={{ width: '14%' }}>Qty</th>
                        <th style={{ width: '14%' }}>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!data?.ticketDetails?.TableData?.length ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Data
                          </td>{" "}
                        </tr>
                      ) : (
                        data?.ticketDetails?.TableData.map((val, i) => {
                          return (
                            <tr
                              style={{ ...custom_styles.font_label }}
                              className="text-center"
                              key={i}
                            >
                              {globalInvoiceType != 1 && globalInvoiceType != 2 ?
                                <td className="w-20px">{val.eventDate ? formatPlanDate(val.eventDate) : "-"}</td> : null}
                              <td>{val.section}</td>
                              <td>{val.row}</td>
                              <td>{val.lowSeat}</td>
                              <td>{val.highSeat}</td>
                              <td>{val.qty}</td>
                              <td>{val.price ? `$${val.price}` : "-"}</td>
                            </tr>
                          );
                        }))}
                    </tbody>
                  </Table>
                </div>

              </div>
            </>
            :
            null
        }

        <div className="d-flex flex-column gap-2">
          <span className="text-muted">Fee Details (Optional)</span>
          <div className="d-flex flex-column gap-2">

            {

              globalFeeInputs.length > 0 ?


                <div className="d-flex flex-wrap justify-content-between">
                  {globalFeeInputs?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="form-group d-flex align-items-center w-50 my-2"
                      >
                        <span
                          className="form-label col-form-label text-wrap text-break"

                          style={{ ...custom_styles.font_label, border: 'none', outline: 'none', padding: "0px", width: "15%", boxShadow: "none" }}
                        >
                          {item?.name}
                        </span>

                        <div className="d-flex align-items-center small gap-2 w-75 fw-bold">
                          <span> $ {item?.code}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                :
                <div className="small fw-bold">
                  No Fees Added
                </div>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default TicketDetailsPreview;
