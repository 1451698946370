import Select from "react-select";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import Loader from "../../../shared/components/loader/Loader";
import { getSeasonsList } from "../../../data/reducers/season/season.reducer";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import DeleteConfirmationModal from "../../../shared/components/modals/DeleteConfirmationModal";
import { getAccountSportList } from "../../../data/reducers/accounts/accounts.reducers";

const AddEditSeasonModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  setRemoveLocation,
  loading,
}) => {
  const dispatch = useDispatch();

  //selectors
  const { sportList } = useSelector((state) => state.accounts);
  const { seasonList } = useSelector((state) => state.season);

  useEffect(() => {
    if (!seasonList.length) {
      dispatch(getSeasonsList());
    }
  }, [seasonList]);

  useEffect(() => {
    if (!sportList.length) {
      dispatch(getAccountSportList());
    }
  }, [sportList]);

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);
  const [dropVal, setdropVal] = useState({
    vendor: "",
    team: "",
    sport: "",
    copyLocationSeasonId: "",
  });

  const [locationConfirmText, setLocationConfirmText] = useState(false);
  const [removeConfirmVisible, setRemoveConfirmVisible] = useState(false);

  // reset form on visibility toggle
  useEffect(() => {
    reset();
    setdropVal({ ...dropVal, sport: "", copyLocationSeasonId: "" });
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setIsEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setdropVal({
        ...dropVal,
        sport: { sportName: selectedRecord?.sportName },
        copyLocationSeasonId: seasonList.find(
          (x) => x.id === selectedRecord?.copyLocationSeasonId
        ),
      });
      setValue("startDate", selectedRecord.startDate?.substr(0, 10));
      setValue("endDate", selectedRecord.endDate?.substr(0, 10));
    } else {
      // if selected record is undefined, reset
      setIsEdit(false);
      reset();
      setdropVal({ ...dropVal, sport: "", copyLocationSeasonId: "" });
    }
  }, [selectedRecord, isVisible]);

  //Handling Reset Button for moda;
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setdropVal({
      ...dropVal,
      sport: { sportName: selectedRecord?.sportName },
      copyLocationSeasonId: seasonList.find(
        (x) => x.id === selectedRecord?.copyLocationSeasonId
      ),
    });
    setValue("startDate", selectedRecord.startDate?.substr(0, 10));
    setValue("endDate", selectedRecord.endDate?.substr(0, 10));
    setLocationConfirmText(false);
  };

  //handling clear button for modal
  const handleClear = () => {
    reset();
    setdropVal({ ...dropVal, copyLocationSeasonId: "" });
  };

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible && !removeConfirmVisible}
        onHide={() => {
          handleClose();
          setLocationConfirmText(false);
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>SEASON</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/season",
                name: "Season",
                active: false,
              },
              {
                name: isEdit ? "Edit Season" : "Add Season",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Season Details</p>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm={7}>
                      <Controller
                        name="sportName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <Select
                                required
                                isClearable
                                isInvalid={!!errors.sportName}
                                value={dropVal?.sport}
                                onChange={async (e) => {
                                  field.onChange(e?.sportName);
                                  setdropVal({ ...dropVal, sport: e });
                                }}
                                styles={customStyles}
                                // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                                options={sportList?.filter((x) => x.id > 0)}
                                getOptionLabel={(option) => option.sportName}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Sport Name is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Season Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.seasonName}
                        {...register("seasonName", { required: true })}
                        placeholder="Season Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Season Name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Start Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="date"
                        isInvalid={!!errors.startDate}
                        {...register("startDate", { required: true })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Start Date is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      End Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="date"
                        isInvalid={!!errors.endDate}
                        {...register("endDate", { required: true })}
                      />
                      <Form.Control.Feedback type="invalid">
                        End Date is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Season Code
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        isInvalid={!!errors.seasonCode}
                        {...register("seasonCode", { required: true })}
                        placeholder="Season Code"
                      />
                      <Form.Control.Feedback type="invalid">
                        Season Code is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                {!isEdit || (isEdit && !dropVal?.copyLocationSeasonId) ? (
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Copy Location
                      </Form.Label>
                      <Col sm={7}>
                        <Controller
                          name="copyLocationSeasonId"
                          control={control}
                          rules={{ required: false }}
                          render={({ field }) => {
                            return (
                              <>
                                <Select
                                  value={dropVal?.copyLocationSeasonId}
                                  onChange={async (e) => {
                                    field.onChange(e?.id);
                                    setdropVal({
                                      ...dropVal,
                                      copyLocationSeasonId: e,
                                    });
                                    if (e) {
                                      setLocationConfirmText(true);
                                    }
                                  }}
                                  styles={customStyles}
                                  options={seasonList.filter(
                                    (x) => x.id !== selectedRecord?.id
                                  )}
                                  getOptionLabel={(option) => option.seasonName}
                                />
                              </>
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                ) : isEdit && dropVal?.copyLocationSeasonId ? (
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Copy Location
                      </Form.Label>
                      <Col sm={7}>
                        <Form.Control
                          disabled={true}
                          value={dropVal?.copyLocationSeasonId?.seasonName}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col></Col>
                )}
              </Row>
              {locationConfirmText && (
                <Row className="py-4">
                  <Col
                    className="d-flex justify-content-center p-4 text-bold"
                    style={{ backgroundColor: "#dff5e2" }}
                  >
                    Are you sure you want to copy locations from "
                    {dropVal?.copyLocationSeasonId?.seasonName}"?
                  </Col>
                </Row>
              )}
            </div>
          </Modal.Body>
          <>
            {!locationConfirmText && (
              <div className="d-flex justify-content-center my-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="ss-modal-primary-btn mx-2"
                  disabled={loading}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isEdit ? "Save" : "Add"}
                    {loading && <Loader style={{ marginLeft: "8px" }} />}
                  </div>
                </Button>
                <Button
                  variant="light"
                  onClick={() => (isEdit ? handleReset() : handleClear())}
                  className="ss-modal-secondary-btn mx-2"
                >
                  {isEdit ? "Reset" : "Clear"}
                </Button>
                {dropVal?.copyLocationSeasonId &&
                  selectedRecord?.copyLocationSeasonId > 0 && (
                    <Button
                      variant="light"
                      onClick={() => setRemoveConfirmVisible(true)}
                      className="ss-modal-secondary-btn mx-2"
                    >
                      Remove Locations
                    </Button>
                  )}
              </div>
            )}
          </>
        </Form>
        {locationConfirmText && (
          <div className="d-flex justify-content-center mb-4">
            <Button
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              onClick={() => {
                setLocationConfirmText(false);
                setValue(
                  "copyLocationSeasonId",
                  dropVal?.copyLocationSeasonId?.id
                );
              }}
            >
              Yes
            </Button>
            <Button
              variant="light"
              onClick={() => {
                setdropVal({ ...dropVal, copyLocationSeasonId: "" });
                setLocationConfirmText(false);
              }}
              className="ss-modal-secondary-btn mx-2"
            >
              Clear Location
            </Button>
          </div>
        )}
      </Modal>
      <DeleteConfirmationModal
        isVisible={removeConfirmVisible}
        handleClose={() => setRemoveConfirmVisible(false)}
        onConfirmation={() => {
          setdropVal({ ...dropVal, copyLocationSeasonId: "" });
          setRemoveLocation(true);
          setRemoveConfirmVisible(false);
          setValue("copyLocationSeasonId", null);
        }}
        text="Do you really want to remove all locations for the season? This process cannot be undone."
      />
    </>
  );
};

export default AddEditSeasonModal;
