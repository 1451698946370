import { Modal, Button } from "react-bootstrap";
import crossdelete from "../../../images/delete_prompt.svg";
import Loader from "../loader/Loader";

const RejectConfirmationModal = ({
  text,
  onConfirmation,
  isVisible,
  handleClose,
  customMessage,
  loading = false,
}) => {
  return (
    <Modal
      dialogClassName="modal-md"
      show={isVisible}
      onHide={handleClose}
      centered
    >
      <div className="delete-svg-div d-flex flex-row justify-content-center align-items-center p-4">
        <h3 className="modal-header-title">Reject Purchase Order</h3>
      </div>

      <div className="d-flex flex-column justify-content-evenly align-items-center p-3">
        {text ? (
          <p> {text} </p>
        ) : (
          <>
            <p className="my-text">
              {customMessage ? "" : "Do you really want to delete this record?"}
            </p>
            <p className="my-text">
              {customMessage ? customMessage : "This process cannot be undone."}
            </p>
          </>
        )}
      </div>
      <Modal.Footer className="justify-content-end">
        <Button
          variant="primary"
          style={{ textWrap: "nowrap" }}
          className="mx-2 ss-light-button"
          onClick={handleClose}
        >
          <span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Cancel
            </div>
          </span>
        </Button>
        <Button
          variant="primary"
          style={{ textWrap: "nowrap", background: "#FF0000" }}
          className="mx-2 ss-light-button"
          disabled={loading}
          onClick={onConfirmation}
        >
          <span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "white",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Yes, reject
              {loading && <Loader style={{ marginLeft: "8px" }} />}
            </div>
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectConfirmationModal;
