/* eslint-disable no-mixed-spaces-and-tabs */
//default imports
import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import Select from "react-select";

//reducers
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  getAccountTeamList,
  getAccountSportList,
} from "../../data/reducers/accounts/accounts.reducers";
import {
  getAllPSL,
  deletePSL,
  filterPSL,
  updatePSL,
  createPSL,
  headerInfo,
  exportSelectedAsCSVPSL,
  exportSelectedAsPdfPSL,
  exportCSVPSL,
  exportPdfPSL,
} from "../../data/reducers/psl/psl.reducer";

//components
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import { withLoader } from "../../utils/hoc/withLoader";
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import CardPanel from "../../shared/components/panels/card-panel/CardPanel";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import AddEditPSLModal from "./modals/AddEditPSLModal";

// images
import Export from "../../images/export.svg";
import Add from "../../images/add.svg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

//css
import "../../shared/styles/ModalStyles.scss";
import { formatDate } from "../../utils/formatter/dateFormatter";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";

const PSLDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.PSL,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.PSL,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "cost", value: filterUrlData?.cost },
        { key: "date", value: filterUrlData?.date },
        { key: "quantity", value: filterUrlData?.quantity },
        { key: "row", value: filterUrlData?.row },
        { key: "seats", value: filterUrlData?.seats },
        { key: "section", value: filterUrlData?.section },
        { key: "source", value: filterUrlData?.source },
        { key: "sportId", value: filterUrlData?.sportId },
        { key: "teamId", value: filterUrlData?.teamId },
        { key: "vendorId", value: filterUrlData?.vendorId },
      ]);
    }
  }, [filterUrlData]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        cost: queryParameters.get("cost"),
        date: queryParameters.get("date"),
        quantity: queryParameters.get("quantity"),
        row: queryParameters.get("row"),
        seats: queryParameters.get("seats"),
        section: queryParameters.get("section"),
        source: queryParameters.get("source"),
        sportId: queryParameters.get("sportId"),
        teamId: queryParameters.get("teamId"),
        vendorId: queryParameters.get("vendorId"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/psl?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(`/psl?page=${Number(pageNumber) - 1}${searchQuery()}`);
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/psl?page=${Number(pageNumber) + 1}${searchQuery()}`);
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/psl?page=${data.searchText ? 1 : page}`
    var url = `/psl?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.cost) url += `&&cost=${data.cost}`;
    if (data.date) url += `&&date=${data.date}`;
    if (data.quantity) url += `&&quantity=${data.quantity}`;
    if (data.row) url += `&&row=${data.row}`;
    if (data.seats) url += `&&seats=${data.seats}`;
    if (data.section) url += `&&section=${data.section}`;
    if (data.source) url += `&&source=${data.source}`;
    if (data.sportId) url += `&&sportId=${data.sportId}`;
    if (data.teamId) url += `&&teamId=${data.teamId}`;
    if (data.vendorId) url += `&&vendorId=${data.vendorId}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //selectors
  const pslList = useSelector((state) => state.psl).psl;
  const paging = useSelector((state) => state.psl).paging;
  const teams = useSelector((state) => state.accounts).teamList;
  const sports = useSelector((state) => state.accounts).sportList;
  const cards = useSelector((state) => state.psl).cards;

  //usestate hooks
  const [addPSLModalVisible, setAddEditPSLModalVisibility] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [time, setTime] = useState("");
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [dropVal, setdropVal] = useState({
    vendor: "",
    team: "",
    sport: "",
  });
  const [searchData, setsearchData] = useState(null);

  //useeffect
  useEffect(() => {
    dispatch(headerInfo());
    dispatch(getAccountSportList());
    dispatch(getAccountTeamList());
  }, []);

  useEffect(() => {
    fetchPSLData();
  }, [sortDir, sortField]);

  //--------------------- set Default value ---------------------//

  useEffect(() => {
    if (teams && filterUrlData) {
      teams.map((obj) => {
        if (obj.id == filterUrlData.teamId) {
          setdropVal({ ...dropVal, team: obj });
        }
      });
    }
  }, [teams, filterUrlData]);

  useEffect(() => {
    if (sports && filterUrlData) {
      sports.map((obj) => {
        if (obj.id == filterUrlData.sportId) {
          setdropVal({ ...dropVal, sport: obj });
        }
      });
    }
  }, [sports, filterUrlData]);

  //fetching psl data
  const fetchPSLData = async () => {
    try {
      setBusy(true);
      const data = {
        pageNumber: page,
        pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllPSL(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //on clicking delete button
  const onDeletePSL = async (pslId) => {
    try {
      setBusy(true);
      const response = await dispatch(deletePSL(pslId));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        fetchPSLData();
        dispatch(headerInfo());
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setSelectedRecordId(null);
      setBusy(false);
    }
  };
  //on clicking search
  const onSubmit = async (data) => {
    if (data && data.vendorId == undefined) {
      data.vendorId = "";
    }
    if (data && data.teamId == undefined) {
      data.teamId = "";
    }
    if (data && data.sportId == undefined) {
      data.sportId = "";
    }
    const formData = {
      ...data,
      cost: parseFloat(data.cost),
      pageNumber,
      pageSize,
      searchText: data.searchText?.trim(),
      sortField,
      sortDir,
    };

    setBusy(true);

    if (
      formData.cost ||
      formData.date ||
      formData.quantity ||
      formData.row ||
      formData.searchText ||
      formData.seats ||
      formData.section ||
      formData.source ||
      formData.sportId ||
      formData.teamId ||
      formData.vendorId
    ) {
      setsearchData(formData);
      await dispatch(filterPSL(formData));
    }
    setBusy(false);
    setAddEditPSLModalVisibility(false);
  };

  const exportSelectedAsCSV = async () => {
    setBusy(true);
    if (searchData) {
      await dispatch(exportSelectedAsCSVPSL(searchData));
    } else {
      await dispatch(exportCSVPSL());
    }
    setBusy(false);
  };

  const onExportSelectedAsPdf = async () => {
    setBusy(true);
    if (searchData) {
      await dispatch(exportSelectedAsPdfPSL(searchData));
    } else {
      await dispatch(exportPdfPSL());
    }
    setBusy(false);
  };

  //To add or edit records
  const onEntrySubmitted = async (data) => {
    try {
      setBusy(true);
      const message = data.id
        ? `PSL Updated Succesfully`
        : `New PSL Created Succesfully`;
      const action = data?.id
        ? updatePSL({
            ...data,
            date: data.date.substr(0, 10),
          })
        : createPSL({
            ...data,
            vendorId: parseInt(data.vendorId),
            teamId: parseInt(data.teamId),
          });
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setAddEditPSLModalVisibility(false);
        fetchPSLData();
        await dispatch(headerInfo());
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //header info
  const cardsData = [
    {
      head: "Quantities",
      val: cards?.quantities === null ? "N/A" : cards.quantities,
    },
    {
      head: "PSL Cost",
      val: cards?.pslCost === null ? "N/A" : formatMoney(cards.pslCost),
    },
    {
      head: "Inventory",
      val: cards?.inventory === null ? "N/A" : formatMoney(cards.inventory),
    },
    {
      head: "Transactions",
      val: cards?.transactions === null ? "N/A" : cards.transactions,
    },
  ];

  //column name and data
  const pslCol = [
    {
      columnName: "Date",
      sort: true,
      sortName: "Date",
      render: (data) => <>{formatDate(data?.date)}</>,
    },
    {
      columnName: "Source",
      sort: true,
      sortName: "Source",
      render: (data) => <>{data?.source}</>,
    },
    {
      columnName: "Sport/Team",
      sort: true,
      sortName: "TeamId",
      render: (data) => (
        <>
          {data?.sportName}
          <br />
          {data?.teamName}
        </>
      ),
    },
    {
      columnName: "Qty",
      columnKey: "quantity",
      sort: true,
      sortName: "Quantity",
      flexGrow: 0.5,
    },
    {
      columnName: "Section",
      columnKey: "section",
      sort: true,
      sortName: "Section",
      flexGrow: 0.5,
    },
    {
      columnName: "Row",
      sort: true,
      sortName: "Row",
      columnKey: "row",
      flexGrow: 0.5,
    },
    {
      columnName: "Seats",
      columnKey: "seats",
      sort: true,
      sortName: "Seats",
    },
    {
      columnName: (
        <p className="mb-0">
          {" "}
          PSL <br /> Cost{" "}
        </p>
      ),
      sort: true,
      sortName: "Cost",
      render: (data) => <>{formatMoney(data?.cost)}</>,
    },

    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={() => {
              setSelectedRecord(data);
              setSelectedRecordId(data.id);
              setTime(String(data?.date)?.substr(10));
              setAddEditPSLModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          <Dropdown>
            <Dropdown.Toggle className="ss-light-button" id="dropdown-basic">
              <img src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={exportSelectedAsCSV}>
                Export as CSV
              </Dropdown.Item>
              <hr className="hr-half" />
              <Dropdown.Item onClick={onExportSelectedAsPdf}>
                Export as PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={async () => {
              await setSelectedRecord(undefined);
              await setSelectedRecordId(undefined);
              await setAddEditPSLModalVisibility(true);
            }}
            variant="primary"
            className="ms-2 ss-light-button"
          >
            <img src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.PSL,
        });
      }}
      className="container-fluid pt-3 overflow-y-hidden"
    >
      {/* Summary Cards */}
      <CardPanel data={cardsData} />

      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            setPageNumber={setpageNumber}
            dashboard={mixpanel_contants.PSL}
            searchPlaceholder="Search for Source, Team, Sports, PSL Cost, Seats, ..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              fetchPSLData();
              navigateToMainPage();
              setdropVal({
                sport: "",
                team: "",
              });
            }}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Cost
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="float"
                          placeholder="PSL Cost"
                          {...register("cost")}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        placeholder="Date"
                        {...register("date")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Source
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Source"
                        {...register("source")}
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Seats
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Seats"
                        {...register("seats")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Quantity
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="number"
                        placeholder="Quantity"
                        {...register("quantity")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row} className="">
                    <Form.Label column sm="4">
                      Section
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="number"
                        placeholder="Section"
                        {...register("section")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Row
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="number"
                        placeholder="Row"
                        {...register("row")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Team
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="teamId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={dropVal.team}
                              onChange={(e) => {
                                field.onChange(e.id);
                                setdropVal({
                                  ...dropVal,
                                  team: e,
                                });
                              }}
                              styles={customStyles}
                              options={teams}
                              getOptionLabel={(option) => `${option.teamName}`}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="sportId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={dropVal.sport}
                              onChange={(e) => {
                                field.onChange(e.id);
                                setdropVal({
                                  ...dropVal,
                                  sport: e,
                                });
                              }}
                              styles={customStyles}
                              // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                              options={sports?.filter((x) => x.id > 0)}
                              getOptionLabel={(option) => `${option.sportName}`}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      <QuickTixTable
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="PSL"
        paging={paging}
        pageType="psl"
        columns={pslCol}
        data={pslList || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : pslCol}
            rowData={rowData}
          />
        )}
      />
      {/* Modals */}
      <AddEditPSLModal
        isVisible={addPSLModalVisible}
        handleClose={() => setAddEditPSLModalVisibility(false)}
        onSubmit={onEntrySubmitted}
        selectedRecord={selectedRecord}
      />

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDeletePSL(selectedRecordId)}
      />
    </div>
  );
};

export default withLoader(PSLDashboard);
