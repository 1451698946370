import React from "react";
import { StepLabel, Step, Stepper } from "@mui/material";
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import StepIcon from "@mui/material/StepIcon";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalMaxStep } from "../../../../data/reducers/purchaseOrders/po.reducer";
import { withLoader } from "../../../../utils/hoc/withLoader";
import { setIsPreview } from "../../../../data/reducers/purchaseOrders/po.reducer";
const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : "#ccc",
  color: "white",
  zIndex: 1,
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check
          style={{
            backgroundColor: "#045F07",
            borderRadius: "50%",
            padding: "3px",
          }}
        />
      ) : (
        <StepIcon {...props} />
      )}
    </CustomStepIconRoot>
  );
}

function InvoiceProgressBar({
  setBusy,
  steps,
  current,
  handleSteps,
  handleUpdateInvoicePO,
  callCreateAccountFunctionWithFieldsCheck,
  setIsPreviewSkipped
}) {
  const { maxCompletedStep, globalInvoiceType, isAddFromInvoice } = useSelector(
    (state) => state.purchaseOrder
  );

  const dispatch = useDispatch();
  // function to handle the validation from multistep form
  const handleMultiStep = async (index, current) => {
    if (index <= maxCompletedStep) {
      setBusy(true);
      let isSuccess = true;
      if (current == 1) {
        isSuccess = await handleUpdateInvoicePO(true);
        if (isSuccess) {
          isSuccess = await callCreateAccountFunctionWithFieldsCheck();
        }
      } else {
        if (
          (current != 5 && globalInvoiceType != 3) ||
          (globalInvoiceType == 3 && current != 4)
        ) {
          isSuccess = await handleUpdateInvoicePO(true);
        }
      }
      if (isSuccess && index) {
        handleSteps(index);
      }
      if (!isAddFromInvoice) {
        dispatch(setIsPreview(true));

      }


      setBusy(false);
    }
  };
  const { showStepperPO } = useSelector((state) => state.purchaseOrder);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: "16px",
        paddingTop: "32px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      }}
    >
      {showStepperPO && (
        <Stepper
          style={{ width: "100%" }}
          nonLinear
          activeStep={current}
          alternativeLabel
        >
          {steps.map((label, index) => (
            <Step
              completed={index < maxCompletedStep && index !== current}
              key={label}
              onClick={() => {

                if (!isAddFromInvoice) {
                  (setIsPreviewSkipped(true));

                }



                handleMultiStep(index, current);
              }}
              style={{
                fontSize: "14px",
                border: "none",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "red",
              }}
            >
              <StepLabel
                sx={{ color: "blue" }}
                StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </div>
  );
}

export default withLoader(InvoiceProgressBar);
