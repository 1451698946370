import {
  api,
  getAuthHeaders,
  post,
  update,
  del,
  get,
  getCSV,
  getPDF
} from "../services.common";

const createPurchaseOrderPayment = async (data) => {
  const url = `${api}PurchaseOrderPaymentPlans/create?purchaseOrderId=${data.plans[0].purchaseOrdersId}&id=${data.plans[0]?.purchaseOrdersId}`;
  const authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

const updatePurchaseOrderPayment = async (data) => {
  const url = `${api}PurchaseOrderPaymentPlans/update?purchaseOrderId=${data.plans[0].purchaseOrdersId}`;
  const authHeader = getAuthHeaders();
  return await update(url, data, { ...authHeader });
};

const deletePurchaseOrderPayment = async (data) => {
  const url = `${api}PurchaseOrderPaymentPlans/delete/${data.mainId}?purchaseOrderId=${data.id}`;
  const authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

const exportCSVPayment = async (id) => {
  const url = `${api}PurchaseOrderPaymentPlans/exportcsv?purchaseOrderId=${id}`;
  const authHeader = getAuthHeaders();
  return await getCSV(url, { ...authHeader });
};

const exportPdfPayment = async (id) => {
  const url = `${api}PurchaseOrderPaymentPlans/exportpdf?purchaseOrderId=${id}`;
  const authHeader = getAuthHeaders();
  return await getPDF(url, { ...authHeader }, "PurchaseOrderPaymentPlans.pdf");
};


const getPurchaseOrderPayment = async (data) => {
  const sortDir = data.sortDir == false ? 'Descending' : 'Ascending'
  const url = `${api}PurchaseOrderPaymentPlans/getall?paymentPlanId=${data.id}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&SortDir=${sortDir}&SortField=${data.sortField || ""}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const getPurchaseOrderPaymentById = async (id) => {
  const url = `${api}PurchaseOrderPaymentPlans/get/${id}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

const uploadTemplatePOPP = async (payload) => {
	const url = `${api}PurchaseOrderPaymentPlans/importpaymentplanstemplatecsv?purchaseOrderId=${payload?.id}`;
	const authHeader = getAuthHeaders();
	return await post(url, payload?.file, {
		...authHeader,
		"Content-Type": "multipart/form-data",
	});
};

const downloadTemplatePOPP = async () => {
	const url = `${api}PurchaseOrderPaymentPlans/templatepaymentplans`;
	const authHeader = getAuthHeaders();
	return await getCSV(url, { ...authHeader });
};

export const poPaymentServices = {
  uploadTemplatePOPP,
  downloadTemplatePOPP,
  createPurchaseOrderPayment,
  updatePurchaseOrderPayment,
  deletePurchaseOrderPayment,
  getPurchaseOrderPayment,
  getPurchaseOrderPaymentById,
  exportCSVPayment,
  exportPdfPayment
};
