import React, { useEffect, useState } from "react";

import CreatableSelect from "react-select/creatable";
import { Controller, useForm } from "react-hook-form";
import { Button, Row, Col, Form, InputGroup, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TagInput from "rsuite/TagInput";
import Select from "react-select";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  getListSkyboxForMultipeRefs,
  getPurchaseOrderRefs,
} from "../../../../../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import {
  setAdditionalDetails,
  setInvoiceType,
} from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import TableComponent from "../../../TableComponent";
import { formatMoney } from "../../../../../../utils/formatter/currencyFormatter";
import { errorMessage } from "../../../../../../data/reducers/alerts/alert.reducer";
import DeleteIcon from "../../../../../../images/delete.svg";
import Search from "../../../../../../images/search-white.svg";
import { reactSelectStyle } from "../../../style";
import Loader from "../../../../../../shared/components/loader/Loader";
import { FaStackOverflow } from "react-icons/fa";
import { cloneDeep } from "lodash";

const custom_styles = {
  font_label: {
    fontSize: "14px",
    color: "#404040",
  },
  add_btn: {
    width: "32px",
    height: "32px",
  },
  form_input: {
    height: "32px",
  },
  border1: {
    backgroundColor: "#c1c1c1",
    height: "1px",
    opacity: "0.8",
  },
  border2: {
    backgroundColor: "#dfdfdf",
    height: "1px",
    opacity: "0.5",
  },
};

const InvoiceAdditionalDetails = ({ type }) => {
  const dispatch = useDispatch();
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);

  const { watch } = useForm();
  const watchAllFields = watch();
  let latestId = 1;
  const [listSkyBoxRefs, setListSkyBoxRefs] = useState([]);


  const { id: InvoicePoId } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [loadingData, setLoadingData] = useState({});
  const [checkskybox, setCheckSkyBox] = useState(false);
  const [masking, setMasking] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [generatemasking, setGenerateMasking] = useState(false);
  const { globalInvoiceType } = useSelector((state) => state.purchaseOrder);

  const handleNewRow = () => {
    const newRow = {
      maskingRow: '',
      maskingVariation: '',
      maximumRow: '',
      rowExclusion: '', // Updated to an empty string for better handling
      generateMasking: {
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
      },
      maskinggenerated: false,
    };
    setMasking((prev) => [...prev, newRow]);
    setGenerateMasking(true);
  };

  const data = useSelector((state) => state.accounts);
  const [inventory, setInventory] = useState({
    sport: data?.ticketDetails?.sport?.label ?? "",
    season: data?.ticketDetails?.season?.label ?? "",
    type: purchaseOrderData?.InvoicePO?.data?.subInvoiceType ?? "",
  });

  useEffect(() => {
    //we are setting the list of sky box ref in this useEffect for its initial value only.
    if ((!listSkyBoxRefs || listSkyBoxRefs?.length == 0) && purchaseOrderData?.additionalDetails?.listSkyBoxRefs?.length > 0) {
      setListSkyBoxRefs(purchaseOrderData?.additionalDetails?.listSkyBoxRefs ?? []);
    }

    if ((!inventory?.type || inventory?.isGoogleSheet == undefined) && purchaseOrderData?.additionalDetails?.inventory?.isGoogleSheet != undefined) {
      setInventory({
        sport: data?.ticketDetails?.sport?.label ?? "",
        season: data?.ticketDetails?.season?.label ?? "",
        type: purchaseOrderData?.InvoicePO?.data?.subInvoiceType ?? "",
        additionalTags: purchaseOrderData.additionalDetails?.inventory?.additionalTags ?? [],
        internalNotes:
          purchaseOrderData.additionalDetails?.inventory?.internalNotes ?? [],
        attendanceBreakdown:
          purchaseOrderData.additionalDetails?.inventory?.attendanceBreakdown,
        isGoogleSheet:
          purchaseOrderData.additionalDetails?.inventory?.isGoogleSheet === false
            ? false
            : true,
        isMondayCRM:
          purchaseOrderData.additionalDetails?.inventory?.isMondayCRM === false ? false : true,
        listSkyBoxRefs: purchaseOrderData?.additionalDetails?.listSkyBoxRefs
      })
    }

    if ((!masking || masking?.length == 0) && purchaseOrderData?.additionalDetails?.masking?.length > 0) {
      setMasking(purchaseOrderData?.additionalDetails?.masking)
    }

  }, [purchaseOrderData?.additionalDetails, data?.ticketDetails?.sport])


  const [showPrompt, setShowPrompt] = useState(true);
  const { skyboxMultipleRefData } = useSelector((state) => state.skybox);


  const skyBoxRefDataList = useSelector((state) => state.skybox).skyBoxRefData;

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setInventory((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleMaskingCheckboxChange = (val) => {
    setIsChecked(val);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInventory((prevInventory) => ({
      ...prevInventory,
      [name]: value,
    }));
  };
  // Handle changes for internal notes (CreatableSelect)
  const handleInternalNotesChange = (newValue) => {
    setInventory((prevInventory) => ({
      ...prevInventory,
      internalNotes: newValue ? newValue.map((item) => item.value) : [],
    }));
  };

  const handleAdditionalTagsChange = (newValue) => {
    setInventory((prevInventory) => ({
      ...prevInventory,
      additionalTags: newValue ? newValue : [],
    }));
  };

  useEffect(() => {
    dispatch(setInvoiceType(type));
  }, []);

  useEffect(() => {
    dispatch(setAdditionalDetails({ inventory, masking, listSkyBoxRefs }));
    let allAdditionalTags = inventory?.additionalTags;
    const elements = document.querySelectorAll('.rs-picker-toggle');

    if (allAdditionalTags && (allAdditionalTags.length >= 1)) {
      if (elements && elements.length >= 1) {

        elements.forEach(element => {
          element.style.display = 'none';
        });
      }

    }
    else {

      elements.forEach(element => {
        element.style.display = 'block';
      });

    }
  }, [inventory, masking, listSkyBoxRefs]);


  useEffect(() => {
    //here were are taking the allocatedSkyboxamount and setting its value in the react-hook-form fields
    for (let i = 0; i < listSkyBoxRefs?.length; i++) {
      setValue(`allocatedSkyBoxAmount-${listSkyBoxRefs[i]?.id}`, listSkyBoxRefs[i]?.value?.allocatableAmount);
    }
  }, [listSkyBoxRefs])


  // Skybox ref
  const getTotalSkyBoxValue = () => {
    let value = 0;
    listSkyBoxRefs.forEach((x) => {
      const skyBoxValue =
        parseFloat(x?.value?.total) || 0;
      value += skyBoxValue;
    });
    return value;
  };



  const onAddClickButton = () => {
    let id = 1;

    if (listSkyBoxRefs?.length > 0) {
      id = listSkyBoxRefs[listSkyBoxRefs?.length - 1].id + 1;
    }

    setCheckSkyBox(checkskybox ? false : true);
    setListSkyBoxRefs([...listSkyBoxRefs, { id: id }]);
  };

  const getMax = (refs) => {
    return listSkyBoxRefs.find((x) => x.id === refs.id)?.value
      ?.staticAllocatableAmount;
  };

  const isInValid = (refs) => {
    if (
      refs?.value?.allocatableAmount >
      getMax(refs)
    ) {
      return true;
    }
    return false;
  };

  const callList = async (id) => {
    try {
      const input = inputValues.find((inp) => inp.id === id);
      setLoadingData((loadingData) => ({ ...loadingData, [id]: true }));
      await dispatch(
        getListSkyboxForMultipeRefs({ input: input.value, id: id })
      );
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoadingData((loadingData) => ({ ...loadingData, [id]: false }));
    }
  };


  // customefilter for skybox select
  const customFilter = (option, searchText) => {
    if (
      String(option?.data?.id)
        ?.toLowerCase()
        .startsWith(searchText?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resetSkyRefRows = () => {
    if (InvoicePoId) {
      setListSkyBoxRefs(
        skyBoxRefDataList.map((ref) => {
          latestId = latestId + 1;
          setValue(
            `allocatedSkyBoxAmount-${latestId - 1}`,
            ref.allocatedSkyBoxAmount,
            {
              shouldValidate: true,
              shouldDirty: true,
            }
          );
          return {
            id: latestId - 1,
            value: {
              id: ref.skyBoxPurchaseId,
              allocatableAmount:
                ref.remainingSkyboxValue + ref.allocatedSkyBoxAmount,
              total: ref.totalSkyboxValue,
            },
          };
        })
      );
    } else {
      setListSkyBoxRefs([]);
    }
  };



  const shouldShowPrompt = () => {
    return listSkyBoxRefs.some((x) => x.value);
  };

  const getAllSkyBoxRefIds = () => {
    const refs = [];
    listSkyBoxRefs.forEach((x) => {
      const id = x?.value?.id;
      refs.push(id);
    });
    return refs;
  };

  const shouldDisableConfirmatButton = () => {
    return listSkyBoxRefs.some((refs) => {
      return isInValid(refs);
    });
  };

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = useForm();

  const internalNotesOptions =
    purchaseOrderData?.additionalDetails?.inventory?.internalNotes?.map(
      (value) => ({
        label: value,
        value,
      })
    ) || [];


  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none'
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: '#F8F8F8',  // Set background color for selected option
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: 'none',  // Hide the dropdown indicator
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',  // Hide the separator next to the dropdown indicator
    }),
    valueContainer: (base) => ({
      ...base,
      justifyContent: 'center',   // Center the content inside the container
    }),
    placeholder: (base) => ({
      ...base,
      textAlign: 'center',        // Center the text itself
      width: '100%',              // Make the placeholder take the full width of the container
    }),
    menu: (base) => ({
      ...base,
      padding: 0,
      height: 0          // Remove padding from the menu
    }),
    menuList: (base) => ({
      ...base,
      height: 0,             // Set height to 0 to remove any remaining space
      padding: 0,
    }),
    noOptionsMessage: (base) => ({
      ...base,
      height: 0,             // Set height to 0 to remove any remaining space
      padding: 0,            // Remove padding to hide the element completely
    }),
  }

  const CreatableSelectComponent = {
    NoOptionsMessage: () => null,

  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "32px",
      maxHeight: "62px",
      overflowY: "scroll",
      boxShadow: 'none'
    }),
    container: (provided, state) => ({
      ...provided,
      border: "none"
    })
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  return (
    <div className="d-flex flex-column gap-4 p-4">
      {type != 5 && (
        <>
          {/* Inventory Tag */}
          <div className="form-group  w-100 d-flex flex-column gap-2">
            <span className="small  text-muted" style={{ fontSize: "14px" }}>
              Inventory Tag
            </span>
            <Table bordered>
              <thead style={{ backgroundColor: "#f5f5f5" }}>
                <tr
                  style={{ ...custom_styles.details, fontSize: "0.9rem" }}
                  className="text-center"
                >
                  <th>Sport</th>
                  <th>Season</th>
                  <th>Type</th>
                  <th>Additional Tags</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{ ...custom_styles.font_label }}
                  className="text-center"
                >
                  <td
                    className="w-25"
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    {inventory?.sport}
                  </td>
                  <td
                    className="w-25"
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    {
                      globalInvoiceType != 4 ?
                        inventory?.season
                        :
                        "-"
                    }
                  </td>
                  <td
                    className="w-25"
                    style={{
                      verticalAlign: "middle",
                    }}
                  >
                    {inventory?.type}
                  </td>
                  <td
                    className="w-25"
                    style={{
                      verticalAlign: "middle",
                      textAlign: 'center'
                    }}
                  >
                    <div className="w-100 d-flex justify-content-center">
                      <CreatableSelect
                        className="w-75 py-2"
                        styles={{ ...customStyles2 }}
                        isClearable
                        isMulti
                        components={CreatableSelectComponent}
                        value={inventory?.additionalTags ?? []}
                        placeholder="Enter Additional Tags"
                        onChange={handleAdditionalTagsChange}
                      />

                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div style={{ ...custom_styles.border2 }}></div>

          {/* Internal notes and Attendance Breakdown */}
          <div className="d-flex justify-content-between align-items-center ">
            <div className="form-group align-items-center d-flex w-50">
              <label
                className="form-label col-form-label"
                style={{
                  color: "#000000b2",
                  fontSize: "14px",
                  width: "120px",
                }}
              >
                Internal Notes
              </label>
              <div className="form-group d-flex w-75">
                <CreatableSelect
                  className="w-75 py-2"
                  styles={{ ...reactSelectStyle, ...customStyles }}
                  isClearable
                  isMulti
                  value={internalNotesOptions}
                  placeholder="Select or Enter new value"
                  onChange={handleInternalNotesChange}
                />

              </div>
            </div>
            <div className="form-group align-items-center d-flex w-50">
              <label
                className="form-label col-form-label w-50"
                style={{
                  color: "#000000b2",
                  fontSize: "14px",
                  width: "120px",
                }}
              >
                Attendance Breakdown
              </label>
              <div className="w-50">
                <input
                  name="attendanceBreakdown"
                  className="form-control"
                  value={
                    purchaseOrderData?.additionalDetails?.inventory
                      ?.attendanceBreakdown
                  }
                  placeholder="Enter"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div style={{ ...custom_styles.border2 }}></div>

          {/* Adding masking instructions */}
          {
            purchaseOrderData.type != 5 && purchaseOrderData.type != 6 ?
              <div className="d-flex flex-column gap-2">
                <span className="" style={{ color: "#A7A7A7", fontSize: "14px" }}>
                  Masking Instructions (Optional)
                </span>
                {/* Masking Table */}
                {(masking?.length > 0) && (
                  <TableComponent
                    isbutton={true}
                    data={masking}
                    setData={setMasking}
                    handleMaskingCheckboxChange={handleMaskingCheckboxChange}
                    handleNewRow={handleNewRow}
                    generatemasking={generatemasking}
                    setGenerateMasking={setGenerateMasking}
                  />
                )}
              </div>
              :
              null
          }
        </>
      )}

      <div style={{ ...custom_styles.border2 }}></div>

      <div>
        <div className="d-flex flex-column gap-2">
          <span style={{ fontSize: "14px" }} className="text-muted">
            SkyBox Ref (Optional)
          </span>

          {listSkyBoxRefs?.length > 0 && (
            <div className="d-flex flex-row gap-2">
              <span style={{ fontSize: "14px" }} className="text-muted">
                Total SkyBox Value
              </span>
              <span style={{ fontSize: "14px", fontWeight: "600px" }}>
                {formatMoney(getTotalSkyBoxValue())}
              </span>
            </div>
          )}

          {!listSkyBoxRefs?.length && (
            <div className="d-flex align-items-center">
              <Button
                variant="primary"
                onClick={onAddClickButton}
                style={{ ...custom_styles.form_input, fontSize: "14px" }}
              >
                + Add SkyBox Ref
              </Button>
            </div>
          )}
        </div>

        {listSkyBoxRefs?.length > 0 && (
          <>

            <Table bordered className="my-3" style={{ fontSize: "0.9rem" }}>
              <thead style={{ backgroundColor: "#f5f5f5" }}>
                <tr className="text-center">
                  <th style={{ width: "40%" }}>SkyBox Ref</th>
                  <th style={{ width: "25%" }}>SkyBox value</th>
                  <th style={{ width: "25%" }}>Allotted Value (Allottable)</th>
                  <th style={{ width: "10%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {listSkyBoxRefs.map((refs) => (
                  <tr
                    key={refs?.id}
                    style={{ ...custom_styles.font_label }}
                    className="text-center"
                  >

                    <td
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        alignItems: "center",
                      }}
                    >
                      <Select
                        options={skyboxMultipleRefData[refs.id]}
                        className="w-100"
                        styles={{
                          ...reactSelectStyle,
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0", // Adjust padding inside the select input
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            border: "none",
                            width: "100%",
                            outline: "none",
                            boxShadow: "none",
                          }),
                          indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: "none",
                          }),
                        }}
                        value={
                          listSkyBoxRefs.find((x) => x.id == refs.id)?.value ||
                          ""
                        }
                        onChange={(e) => {

                          if (!e) {
                            setListSkyBoxRefs([
                              ...listSkyBoxRefs.map((skRefs) => {
                                if (skRefs.id === refs.id) {
                                  return {
                                    ...skRefs,
                                    value: e,
                                  };
                                } else {
                                  return skRefs;
                                }
                              }),
                            ]);
                            setValue(`allocatedSkyBoxAmount-${refs.id}`, 0);
                            setShowPrompt(true);
                          } else {
                            // field.onChange(e?.id);
                            const isItAlreadySelected = listSkyBoxRefs.some(
                              (x) => x?.value?.id === e?.id
                            );
                            if (
                              isItAlreadySelected &&
                              refs?.value?.id !== e?.id
                            ) {
                              dispatch(
                                errorMessage("SkyRef is already selected")
                              );
                            } else {
                              setListSkyBoxRefs([
                                ...listSkyBoxRefs.map((skRefs) => {
                                  if (skRefs.id === refs.id) {
                                    return {
                                      ...skRefs,
                                      value: {
                                        ...e,
                                        staticAllocatableAmount: e?.allocatableAmount,
                                      },
                                    };
                                  } else {
                                    return skRefs;
                                  }
                                }),
                              ]);
                              setValue(
                                `allocatedSkyBoxAmount-${refs.id}`,
                                e.allocatableAmount
                              );
                              setShowPrompt(true);
                            }
                          }
                        }}
                        onInputChange={(e) => {
                          if (e != "") {
                            const data = inputValues.find(
                              (x) => x.id === refs.id
                            );
                            if (data) {
                              setInputValues([
                                ...inputValues.filter((x) => x.id !== refs.id),
                                { id: refs.id, value: e },
                              ]);
                            } else {
                              setInputValues([
                                ...inputValues,
                                { id: refs.id, value: e },
                              ]);
                            }
                          }
                        }}
                        placeholder={
                          skyboxMultipleRefData[refs.id]?.length
                            ? `Select from ${skyboxMultipleRefData[refs.id].length
                            } results`
                            : "Type & Select"
                        }
                        isClearable
                        getOptionLabel={(option) => `${option.id}`}
                        filterOption={customFilter}
                      />
                      {loadingData[refs.id] ? (
                        <Loader />
                      ) : (
                        <Button
                          onClick={() => {
                            callList(refs.id);
                          }}
                          style={{ height: "32px" }}
                          className="d-flex justify-content-center text-center"
                        >
                          <img src={Search} width={13} height={17} />
                        </Button>
                      )}
                    </td>


                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      {formatMoney(refs?.value?.total || 0)}
                    </td>


                    <td
                      style={{
                        verticalAlign: "middle",
                        padding: '0'
                      }}
                    >
                      {!refs?.value?.id || (refs?.value?.allocatableAmount === 0 && refs?.value?.staticAllocatableAmount === 0) ? (
                        formatMoney(refs?.value?.allocatableAmount)
                      ) : (
                        <Form.Group className="d-flex align-items-center justify-content-between">
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                style={{ height: "32px" }}
                                className="rounded-0 rounded-start"
                              >
                                $
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              // type="float"
                              style={{
                                border: "1px solid #ced4da",
                                boxShadow: "none",
                                height: "32px",
                              }}
                              {...register(`allocatedSkyBoxAmount-${refs.id}`, {
                                onChange: (e) => {
                                  setShowPrompt(true);

                                  // get the previous state of the listSkyBoxRefs 
                                  let oldSkyBoxRefsState = cloneDeep(listSkyBoxRefs);
                                  for (let i = 0; i < oldSkyBoxRefsState?.length; i++) {
                                    if (oldSkyBoxRefsState[i]?.id == refs.id) {
                                      oldSkyBoxRefsState[i] =
                                      {
                                        id: oldSkyBoxRefsState[i]?.id,
                                        value: {
                                          ...oldSkyBoxRefsState[i]?.value,
                                          allocatableAmount: isNaN(parseInt(e?.target?.value)) ? '' : parseInt(e?.target?.value)
                                        }
                                      }
                                    }
                                  }
                                  setListSkyBoxRefs(oldSkyBoxRefsState);

                                },
                              })}
                              isInvalid={
                                !!errors[`allocatedSkyBoxAmount-${refs.id}`]
                              }
                              placeholder="0.00"
                              onKeyPress={(e) =>
                                !/[0-9]|\./.test(e.key) && e.preventDefault()
                              }
                              disabled={
                                !refs?.value?.id ||
                                (refs?.value?.allocatableAmount === 0 && refs?.value?.staticAllocatableAmount === 0)
                              }
                              className="rounded-end"
                            />
                            <span className="d-flex align-items-center mx-1"> ( $ {getMax(refs)})</span>

                            <Form.Control.Feedback type="invalid">
                              {!!errors[`allocatedSkyBoxAmount-${refs.id}`] &&
                                errors[`allocatedSkyBoxAmount-${refs.id}`]
                                  .type === "required" &&
                                "Alloting Value is required"}
                            </Form.Control.Feedback>
                            <div>
                              {isInValid(refs) && (
                                <Form.Text
                                  style={{ display: "block" }}
                                  className="invalid-feedback"
                                >
                                  Alloting value should be less than or
                                  equal to {getMax(refs)}
                                </Form.Text>
                              )}
                            </div>
                          </InputGroup>
                        </Form.Group>
                      )}

                    </td>

                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <Button
                        variant="link"
                        className="table-action-button"
                        onClick={() => {
                          setListSkyBoxRefs(
                            listSkyBoxRefs.filter((x) => x.id !== refs.id)
                          );

                          setShowPrompt(true);
                        }}
                      >
                        <img
                          style={{ width: "15px", height: "100%" }}
                          src={DeleteIcon}
                          alt="Delete Purchase Orders"
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>


            {shouldShowPrompt() && showPrompt ? (
              <Row className="mt-4">
                <Col sm={8}>
                  <p style={{ color: "#EB5757", fontSize: 14 }}>
                    Purchase Order has a total value of{" "}
                    {formatMoney(purchaseOrderData.purchasePaymentDetails.totalScheduled)} and sum of
                    Skybox Ref {getAllSkyBoxRefIds().join(", ")} has a total
                    value of {formatMoney(getTotalSkyBoxValue())} There is
                    a difference of{" "}
                    {formatMoney(
                      Math.abs(
                        (parseFloat(purchaseOrderData.purchasePaymentDetails.totalScheduled) || 0) -
                        getTotalSkyBoxValue()
                      )
                    )}
                    . Are you sure you want to complete this match?
                  </p>
                </Col>
                <Col sm={3}>
                  <Button
                    variant="primary"
                    className="ss-modal-primary-btn mr-2"
                    onClick={() => {
                      setShowPrompt(false);
                    }}
                    disabled={shouldDisableConfirmatButton()}
                  >
                    Confirm
                  </Button>
                </Col>
                <Col xs={1}>
                  <Button
                    className=""
                    onClick={() => {
                      setInputValues([]);
                      setValue("skyBoxRef", "");
                      setShowPrompt(false);
                    }}
                  >
                    <span>X</span>
                  </Button>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </>
        )}

        {listSkyBoxRefs.length > 0 && (
          <div className="d-flex align-items-center">
            <Button
              variant="primary"
              onClick={onAddClickButton}
              style={{ ...custom_styles.form_input, fontSize: "14px" }}
            >
              + Add Another SkyBox Ref
            </Button>
          </div>
        )}
      </div>

      <div style={{ ...custom_styles.border2 }}></div>

      {/* Add po details section */}
      <div className="d-flex flex-column gap-2">
        <span className=" text-muted " style={{ fontSize: "14px" }}>
          Add these PO details to Google Sheets and/or Monday CRM
        </span>
        <div className="d-flex">
          <Form.Group>
            <Form.Check
              className="remember-forgotpass me-4"
              type="checkbox"
              name="isGoogleSheet"
              defaultChecked={true}
              checked={inventory?.isGoogleSheet}
              onChange={handleCheckboxChange}
              label={
                <label style={{ ...custom_styles.font_label }}>
                  Google Sheet
                </label>
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              className="remember-forgotpass"
              type="checkbox"
              name="isMondayCRM"
              checked={inventory?.isMondayCRM}
              onChange={handleCheckboxChange}
              label={
                <label style={{ ...custom_styles.font_label }}>
                  Monday CRM
                </label>
              }
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default InvoiceAdditionalDetails;
