import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ModalBreadcrumb = ({ breadcrumbLinks ,bg,padding ,fontSize}) => (
	<Breadcrumb className={'ss-modal-breadcrumb '} style={bg?{backgroundColor:bg,paddingLeft:`${padding}px`,fontSize:fontSize}:null}>
		{breadcrumbLinks?.map((link, index) => (
			<Breadcrumb.Item
				key={index}
				className='breadcrumb'
				linkAs={Link}
				linkProps={{ to: link.to }}
				active={link.active}>
				{link.name}
			</Breadcrumb.Item>
		))}
	</Breadcrumb>
);

export default ModalBreadcrumb;
