import UploadInvoice from "./UploadInvoice";
import InvoiceAccountDetails from "./content/tabs/2-accountDetails/InvoiceAccountDetails";
import InvoiceTicketDetails from "./content/tabs/3-ticketDetails/InvoiceTicketDetails";
import InvoicePurchaseDetails from "./content/tabs/4-purchaseDetails/InvoicePurchaseDetails";
import InvoiceAttachments from "./content/tabs/5-attachments/InvoiceAttachments";
import InvoiceAdditionalDetails from "./content/tabs/6-additionalDetails/InvoiceAdditionalDetails";
import InvoicePreview from "./content/tabs/7-preview/InvoicePreview";

export const StepComponent = (type, handleUpdateInvoicePO) => {
  switch (type) {
    case "Full Season":
    case "Half Season":
    case "Full Strip":
    case "Partial Season":
    case "Half Strip":
    case "Single Round":
    case "Flex Purchase":
    case "Regular Season-Full":
      return (props, selectedinvoice, allData) => {
        return {
          0: <UploadInvoice allData={allData} setSteps={props} isType={true} handleUpdateInvoicePO={handleUpdateInvoicePO} />,
          1: <InvoiceAccountDetails />,
          2: <InvoiceTicketDetails type={2} />,
          3: (
            <InvoicePurchaseDetails
              allData={allData}
              selectedInvoice={selectedinvoice}
            />
          ),
          // 4: <InvoiceAttachments allData={allData} />,
          4: <InvoiceAdditionalDetails type={2} typeName={type} />,
          5: <InvoicePreview setSteps={props} type={2} typeName={type} />,
        };
      };
    case 3:
      return (props, selectedInvoice, allData) => {
        return {
          0: <UploadInvoice allData={allData} setSteps={props} isType={true} handleUpdateInvoicePO={handleUpdateInvoicePO} />,
          1: <InvoiceAccountDetails />,
          2: <InvoiceTicketDetails type={3} />,
          3: (
            <InvoicePurchaseDetails
              allData={allData}
              selectedInvoice={selectedInvoice}
            />
          ),
          4: <InvoicePreview setSteps={props} type={3} typeName={type} />,
        };
      };
    case 4:
      return (props, selectedInvoice, allData) => {
        return {
          0: <UploadInvoice allData={allData} setSteps={props} isType={true} handleUpdateInvoicePO={handleUpdateInvoicePO} />,
          1: <InvoiceAccountDetails />,
          2: <InvoiceTicketDetails type={4} />,
          3: (
            <InvoicePurchaseDetails
              allData={allData}
              selectedInvoice={selectedInvoice}
            />
          ),
          4: <InvoiceAdditionalDetails type={4} typeName={type} />,
          5: <InvoicePreview setSteps={props} type={4} typeName={type} />,
        };
      };
    case 5:
      return (props, selectedInvoice, allData) => {
        return {
          0: <UploadInvoice allData={allData} setSteps={props} isType={true} handleUpdateInvoicePO={handleUpdateInvoicePO} />,
          1: <InvoiceAccountDetails />,
          2: <InvoiceTicketDetails type={5} />,
          3: (
            <InvoicePurchaseDetails
              allData={allData}
              selectedInvoice={selectedInvoice}
            />
          ),

          4: <InvoiceAdditionalDetails type={5} typeName={type} />,
          5: <InvoicePreview setSteps={props} type={5} />,
        };
      };
    case 6:
      return (props, selectedInvoice, allData) => {
        return {
          0: <UploadInvoice allData={allData} setSteps={props} isType={true} handleUpdateInvoicePO={handleUpdateInvoicePO} />,
          1: <InvoiceAccountDetails />,
          2: <InvoiceTicketDetails type={6} />,
          3: (
            <InvoicePurchaseDetails
              allData={allData}
              selectedInvoice={selectedInvoice}
            />
          ),
          // 4: <InvoiceAttachments allData={allData} />,
          4: <InvoiceAdditionalDetails type={6} typeName={type} />,
          5: <InvoicePreview setSteps={props} type={6} typeName={type} />,
        };
      };
    default:
      return (props, selectedinvoice, allData, setallData) => {
        return {
          0: (
            <UploadInvoice allData={allData} setSteps={props} isType={false} handleUpdateInvoicePO={handleUpdateInvoicePO} />
          ),
          1: <InvoiceAccountDetails />,
          2: <InvoiceTicketDetails allData={allData} setallData={setallData} />,
          3: (
            <InvoicePurchaseDetails
              allData={allData}
              selectedInvoice={selectedinvoice}
            />
          ),
          // 4: <InvoiceAttachments allData={allData} />,
          4: <InvoiceAdditionalDetails typeName={type} />,
          5: <InvoicePreview setSteps={props} typeName={type} />,
        };
      };
  }
};

export const Steps = (type) => {
  switch (type) {
    case "Post Season" ||
      "Full Strip" ||
      "Half Strip" ||
      "Single Round" ||
      "Partial Season" ||
      "Flex Purchase" ||
      "Regular Season-Full":
      return [
        "Upload Invoice",
        "Account Details",
        "Ticket Details",
        "Purchase Details",
        // "Attachments (Optional)",
        "Additional Details",
        "Preview",
      ];
    case 3:
      return ["Upload Invoice", "Account Details", "Ticket Details", "Purchase Details", "Preview"];
    case 4:
      return [
        "Upload Invoice",
        "Account Details",
        "Ticket Details",
        "Purchase Details",
        "Additional Details",
        "Preview",
      ];
    case 5:
      return [
        "Upload Invoice",
        "Account Details",
        "Ticket Details",
        "Purchase Details",
        "Additional Details",
        "Preview",
      ];

    default:
      return [
        "Upload Invoice",
        "Account Details",
        "Ticket Details",
        "Purchase Details",
        // "Attachments (Optional)",
        "Additional Details",
        "Preview",
      ];
  }
};

