import { MdOutlineModeEdit } from "react-icons/md";
import { Form, Table } from "react-bootstrap";
import Header from "../../../../components/Header";
import KeyValueLabel from "../../../../components/KeyValueLabel";
import { formatMoney } from "../../../../../../../utils/formatter/currencyFormatter";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PurchaseDetailsPreview = ({
  custom_styles,
  setSteps,
  purchaseOrderData,
}) => {
  function convertPriceToInteger(priceString) {
    let integerPrice = priceString;
    if (typeof priceString === "string") {
      let cleanedString = priceString.replace(/^USD\s*/, "");
      cleanedString = cleanedString.replace("$", "");
      cleanedString = cleanedString.replace(/,/g, "");
      integerPrice = parseFloat(cleanedString);
    }

    return integerPrice;
  }
  const { globalInvoiceType } = useSelector((state) => state.purchaseOrder);

  return (
    <>
      <div className="p-4 d-flex flex-column gap-4">
        {/* Header */}

        <Header
          title="Purchase Details"
          subTitle="Please enter today's payment and/or create a payment plan."
          onEdit={() => setSteps(3)}
        />

        {/*  */}

        <div className="d-flex flex-column gap-3">
          {/* Amount and Start Date */}
          <div className="d-flex justify-content-between">
            <KeyValueLabel
              label={"Description"}
              value={purchaseOrderData?.purchasePaymentDetails?.description}
            />
            <KeyValueLabel
              label={"Purchase Order Date"}
              value={
                purchaseOrderData?.purchasePaymentDetails?.purchaseOrderDate
              }
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          {/* Amount and Start Date */}
          <div className="d-flex justify-content-between">
            <KeyValueLabel
              label={"Total Invoice Amount"}
              value={`$ ${purchaseOrderData?.purchasePaymentDetails?.totalScheduled}`}
            />
          </div>
        </div>

        {globalInvoiceType != 4 &&
          globalInvoiceType != 3 &&
          globalInvoiceType != 5 && (
            <>
              <div className="d-flex justify-content-between ">
                <div className="form-group d-flex">
                  <Form.Group>
                    <Form.Check
                      readOnly
                      className="remember-forgotpass"
                      type="checkbox"
                      id="payment_check"
                      checked={
                        purchaseOrderData?.purchasePaymentDetails?.remember
                          ? true
                          : false
                      }
                      label={
                        <label
                          htmlFor="payment_check"
                          style={{ fontSize: "14px", cursor: "pointer" }}
                        >
                          Payment made today.
                        </label>
                      }
                    />
                  </Form.Group>
                </div>
                {purchaseOrderData?.purchasePaymentDetails?.remember == true ? (
                  <KeyValueLabel
                    label={"Amount Paid"}
                    value={formatMoney(
                      purchaseOrderData?.purchasePaymentDetails?.amountPaid
                    )}
                  />
                ) : null}
              </div>

              <div style={{ ...custom_styles.border2, margin: "0" }}></div>
            </>
          )}

        {/* Payment plan*/}
        {globalInvoiceType != 4 &&
          globalInvoiceType != 3 &&
          globalInvoiceType != 5 && (
            <div className="d-flex flex-column gap-2">
              <span className="small   text-muted" style={{ fontSize: "16px" }}>
                Payment Plan
              </span>
              {purchaseOrderData?.PaymentPlan?.length > 0 ? (
                <div style={{ minWidth: "200px", maxWidth: "400px" }}>
                  <Table bordered hover>
                    <thead style={{ backgroundColor: "#f5f5f5" }}>
                      <tr style={{ ...custom_styles.details }}>
                        <th className="text-center">Date</th>
                        <th className="text-end">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrderData?.PaymentPlan?.length
                        ? purchaseOrderData?.PaymentPlan?.map((val, i) => {
                            return (
                              <tr
                                key={i}
                                style={{ ...custom_styles.font_label }}
                              >
                                <td className="text-center">{val.date}</td>
                                <td className="text-end">
                                  {formatMoney(
                                    convertPriceToInteger(val.amount)
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="small fw-bold">Payment plan not created</div>
              )}
            </div>
          )}
      </div>
    </>
  );
};

export default PurchaseDetailsPreview;
